export const getActionitem = /* GraphQL */ `
  query GetActionitem($id: ID!) {
    getActionitem(id: $id) {
      id
      actionitem
      duedate
      assigneeID
      actionitemType
      finding
      rootcause
      correctiveAction
      statusID
      creatorID
      lastModifierID
      projectID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      deliverableID
      inspectionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      assignee {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          creator {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          lastModifier {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      project {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      attachment {
        items {
          id
          actionitemID
          attachmentID
          attachment {
            id
            projectID
            name
            description
            datatype
            location
            filename
            statusID
            creatorID
            lastModifierID
            lastversionID
            nextversionID
            adminUser
            editUser
            viewUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            creator {
              id
              username
              email
              firstname
              lastname
              superUser
              companyAdmin
              companyID
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
              keyvalues {
                nextToken
                startedAt
              }
              company {
                id
                name
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
            }
            lastModifier {
              id
              username
              email
              firstname
              lastname
              superUser
              companyAdmin
              companyID
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
              keyvalues {
                nextToken
                startedAt
              }
              company {
                id
                name
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
            }
            status {
              id
              status
              statusState
              dueDate
              notes
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              creator {
                id
                username
                email
                firstname
                lastname
                superUser
                companyAdmin
                companyID
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
              lastModifier {
                id
                username
                email
                firstname
                lastname
                superUser
                companyAdmin
                companyID
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
              owner
            }
            comments {
              items {
                id
                projectID
                comment
                creatorID
                creator {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                lastModifierID
                parentID
                childID
                userID
                companyID
                partnumberID
                actionitemID
                historyID
                requirementID
                attachmentID
                deliverableID
                inspectionID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
              nextToken
              startedAt
            }
            project {
              items {
                id
                customID
                name
                partnumberID
                description
                dueDate
                supplierID
                statusID
                creatorID
                lastModifierID
                parentID
                childID
                adminUser
                editUser
                viewUser
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
              nextToken
              startedAt
            }
            owner
            actionitems {
              items {
                id
                attachmentID
                actionitemID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;