import React, { useContext, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";

import { Switch, Route } from "react-router-dom";

import Home from "./Home";

import FaiMain from "../Module_FAI/FaiMain";
import FaiView from "../Module_FAI/FaiView";

import MtrMain from "../Module_MTR/MtrMain";

import UserProfile from "../Settings/UserProfile";
import ManageCompany from "../Settings/ManageCompany";
import ManageSuppliers from "../Settings/ManageSuppliers";
import MtrView from "../Module_MTR/MtrView";
import Settings from "../Settings/Settings";
import UserContext from "../../contexts/UserContext";
import MissingUserInfo from "../Utilities/MissingUserInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Main = () => {
  const { user } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (user.firstname) {
      setOpen(false);
    } else if (user.loggedIn) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user]);

  return (
    <div className={classes.root}>
      {open ? (
        <MissingUserInfo user={user} open={open} setOpen={setOpen} />
      ) : (
        <Switch>
          <Route path="/fai/details" component={FaiMain} />
          <Route path="/fai/home" component={FaiView} />
          <Route path="/mtr/details" component={MtrMain} />
          <Route path="/mtr/home" component={MtrView} />
          <Route path="/settings/userprofile" component={UserProfile} />
          <Route path="/settings/suppliers" component={ManageSuppliers} />
          <Route
            path="/settings/companyprofile/:companyid"
            component={ManageCompany}
          />
          <Route path="/settings/home" component={Settings} />
          <Route path="/" component={Home} />
        </Switch>
      )}
    </div>
  );
};

export default Main;
