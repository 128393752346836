import React, { useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachmentList from "../Utilities/Attachment/Attachment";
import FileUploadBox from "../Utilities/FileUpload/FileUploadBox";
import { red, green } from "@mui/material/colors";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  CloudDownload,
} from "@mui/icons-material";
import CorrectiveAction from "./CorrectiveAction";
import FileAvatar from "../Utilities/Attachment/FileAvatar";
import { Storage } from "aws-amplify";
import LoadingContext from "../../contexts/LoadingContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flex: 1,
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flex: 1,
  },
}));

const defaultCorrectiveAction = {
  name: "",
  finding: "",
  rootCause: "",
  correctiveAction: "",
  attachments: [],
  status: "open",
};

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

const CaAccordian = (props) => {
  const {
    panel,
    expanded,
    handleAccordianChange,
    correctiveAction = defaultCorrectiveAction,
    getSetProject,
  } = props;
  const { loading, setLoading } = useContext(LoadingContext);
  const classes = useStyles();
  console.log(correctiveAction);
  const checkboxDetermine = (status) => {
    if (status === "open") {
      return (
        <CheckBoxOutlineBlank
          fontSize="medium"
          style={{ marginRight: "1.5em", color: red[500] }}
        />
      );
    } else if (status === "closed") {
      return (
        <CheckBox
          fontSize="medium"
          style={{ marginRight: "1.5em", color: green[500] }}
        />
      );
    }
  };

  const handleDownload = async (file) => {
    setLoading({ ...loading, long: true });
    const result = await Storage.get(file.id, { download: true });
    downloadBlob(result.Body, file.name);
    setLoading({ ...loading, long: false });
  };

  return (
    <Accordion
      expanded={expanded === panel}
      onChange={handleAccordianChange(panel)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={panel + "bh-content"}
        id={panel + "bh-header"}
      >
        {checkboxDetermine(correctiveAction.status)}

        <Typography className={classes.heading}>
          {correctiveAction.actionitem}
        </Typography>
        <Typography className={classes.secondaryHeading}>
          {correctiveAction.finding}
        </Typography>
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <ul>
              {correctiveAction.attachment ? (
                correctiveAction.attachment.items.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div>
                        <List dense={true}>
                          {correctiveAction.attachment.items.map((file) => (
                            <ListItem key={file.attachment.id}>
                              <ListItemAvatar>
                                <FileAvatar
                                  filetype={
                                    file.attachment.datatype
                                      ? file.attachment.datatype
                                      : file.attachment.type
                                  }
                                  size="small"
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={file.attachment.filename}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() =>
                                    handleDownload(file.attachment)
                                  }
                                  edge="end"
                                  aria-label="download"
                                  size="large">
                                  <CloudDownload />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}
            </ul>
          }
        ></FormControlLabel>
      </AccordionSummary>
      <AccordionDetails>
        <CorrectiveAction
          correctiveActionID={correctiveAction.id}
          getSetProject={getSetProject}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CaAccordian;
