import { API } from "aws-amplify";
import { updateProject as updateProjectMutation } from "../graphql/mutations";

export const UpdateProject = async (project, updateKeys) => {
  var updateProject = {
    id: project.id,
    _version: project._version,
  };

  for (let i = 0; i < updateKeys.length; i++) {
    updateProject = {
      ...updateProject,
      [updateKeys[i]]: project[updateKeys[i]],
    };
  }

  await API.graphql({
    query: updateProjectMutation,
    variables: { input: updateProject },
  });
};
