import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ArrowForward } from "@mui/icons-material";
import { API } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import UserContext from "../../../contexts/UserContext";
import UserAvatar from "../TeamMembers/UserAvatar";
import { DatePicker   } from "@mui/x-date-pickers";
import graphQuery from "../../../services/AWSGraphWrapper";

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: '100%',
    marginTop: '0.5em'
  },
  selectfield: {
    width: '100%',
    marginTop: '0.5em'
  },
  form: {
    width: '100%',
    marginTop: '0.5em'
  },
  datefield: {
    marginTop: '0.5em',
    float: 'right'
  },
}));

const CreateActionItem = (props) => {
  const { visible, setVisible, corrective = false,
    projectID,
    partnumberID,
    actionitemID,
    historyID,
    requirementID,
    deliverableID,
    inspectionID,
    accessIDs,
    getSetProject} = props;
  const { user } = useContext(UserContext);
  const [availUsers, setAvailUsers] = useState([])
  const [assignee, setAssignee] = useState({ ...user })
  const [newAI, setNewAI] = useState('')
  const [finding, setFinding] = useState('')
  const [dueDate, setDueDate] = useState(new Date());
  const classes = useStyles();

  useEffect(() => {
    if (accessIDs['editUser']) {
      console.log([...accessIDs['editUser'], ...accessIDs['viewUser'], ...accessIDs['adminUser']])
      graphQuery({ query: queries.listUsers })
        .then(response => {
          var tmpusers = response
          setAvailUsers(tmpusers.filter(item => [...accessIDs['editUser'], ...accessIDs['viewUser'], ...accessIDs['adminUser']].includes(item.id)))
        })
      setAssignee({ ...user })
    }
  },[visible, accessIDs, user])

  const handleClose = () => {
    setVisible(false);
  }

  const handleAssigneeChange = (event) => {
    console.log(event.target)
    setAssignee(event.target.value)
  }

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleAIChange = (event) => {
    setNewAI(event.target.value)
  }

  const handleFindingChange = (event) => {
    setFinding(event.target.value)
  }

  const handleSubmit = async () => {
    const newStatus = {
      status: 'Open',
      statusState: 'open',
      creatorID: user.id,
      lastModifierID: user.id
    }
    if (newAI !== "" && assignee !== null) {
      const statusID = await API.graphql({ query: mutations.createStatus, variables: { input: newStatus } })
        .then(response => response.data.createStatus.id)
      
      const newActionItem = {
        actionitem: newAI,
        finding: finding,
        actionitemType: corrective ? 'corrective' : 'basic',
        projectID: projectID, 
        assigneeID: assignee.id,
        duedate: dueDate,
        creatorID: user.id,
        lastModifierID: user.id,
        statusID: statusID,
        partnumberID: partnumberID,
        actionitemID: actionitemID,
        historyID: historyID,
        requirementID: requirementID,
        deliverableID: deliverableID,
        inspectionID: inspectionID,
        adminUser: accessIDs.adminUser,
        editUser: accessIDs.editUser,
        viewUser: accessIDs.viewUser
      }
    
      console.log("Creating new AI")
      await API.graphql({ query: mutations.createActionitem, variables: { input: newActionItem } })
      getSetProject();
      handleClose();
    }
  }

  return (
    <Dialog open={visible} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle>{corrective ? "New Non-Conformance" : "New Action Item"}</DialogTitle>
      <DialogContent >
        <Grid spacing={2} container>
          <Grid item sm={12}>
            <TextField
              className={classes.textfield}
              multiline
              required
              variant="outlined"
              label={corrective ? "Non-Confomance Name / Summary" : "Action Item"}
              value={newAI}
              onChange={handleAIChange}
            />
          </Grid>
          {corrective ?
            <Grid item sm={12}>
              <TextField
                className={classes.textfield}
                multiline
                required
                variant="outlined"
                label={"Finding"}
                value={finding}
                onChange={handleFindingChange}
              />
            </Grid>
            : null}
          <Grid item sm={12}>
            <FormControl variant="standard" className={classes.form}>
              <InputLabel id="assignee-select-label">Assign To</InputLabel>
              <Select
                variant="standard"
                labelId="assignee-select-label"
                id="assignee-select"
                value={assignee}
                required
                onChange={handleAssigneeChange}
                label="Assign To">
                {availUsers.map(useri => (
                  <MenuItem key={useri.id} value={useri}>
                    <ListItemAvatar>
                      <UserAvatar email={useri.username} />
                    </ListItemAvatar>
                    <ListItemText primary={useri.firstname + ' ' + useri.lastname + "    |    " + useri.username} secondary={useri.company ? useri.company.name : null} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <DatePicker 
              className={classes.formControl}
              variant="standard"
              inputFormat="MM/dd/yyyy"
              margin="normal"
              id="duedate-picker-inline"
              label="Due Date"
              value={dueDate}
              onChange={handleDueDateChange}
              renderInput={(params) => <TextField variant='standard' {...params} />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<ArrowForward />} color="primary" variant="contained" onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateActionItem;