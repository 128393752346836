export const Steps = {
  open: {
    label: 'Open',
    phase: null,
    complete: false,
    nextStep: 'review_pending',
    lastStep: null,
    approvalRoles: [
      'admin',
      'engineering'
    ]
  },
  review_pending: {
    label: 'Awaiting Review',
    phase: null,
    complete: false,
    nextStep: {
      approve: 'closed',
      disapprove: 'open'
    },
    lastStep: 'open',
    approvalRoles: [
      'admin',
      'engineering'
    ]
  },
  closed: {
    label: 'Closed',
    phase: null,
    complete: false,
    nextStep: null,
    lastStep: 'review_pending',
    approvalRoles: [
      'admin',
      'engineering'
    ]
  }
}

export default Steps