import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';

const ConfirmAction = (props) => {
  const {
    open,
    handleOnClose,
    handleConfirm,
    dialogTitle = 'Dialog Title',
    dialogContent = 'Dialog Content',
    dialogCancelText = 'Cancel',
    dialogConfirmText = 'Confirm',
    dialogCancelIcon = <CancelIcon />,
    dialogConfirmIcon = <CheckIcon />
  } = props
  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth={true}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: "space-evenly" }}>
        <Button startIcon={dialogCancelIcon} variant='contained' color='secondary' onClick={handleOnClose}>
          {dialogCancelText}
        </Button>
        <Button startIcon={dialogConfirmIcon} variant='contained' color='primary' onClick={handleConfirm}>
          {dialogConfirmText}
        </Button></DialogActions>
    </Dialog>
  )

}

export default ConfirmAction;