import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";

const DeleteRequirement = async (requirementID, version) => {
  const company = {
    id: requirementID,
    _version: version,
  };

  return await API.graphql({
    query: mutations.deleteRequirement,
    variables: { input: company },
  }).then((response) => response.data.deleteRequirement.id);
};

export default DeleteRequirement;
