export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      customID
      name
      partnumberID
      description
      dueDate
      supplierID
      statusID
      creatorID
      lastModifierID
      parentID
      childID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      usergroups {
        items {
          id
          projectID
          name
          description
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          creator {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          lastModifier {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          owner
          users {
            items {
              id
              useraccessgroupID
              userID
              user {
                id
                username
                email
                firstname
                lastname
                superUser
                companyAdmin
                companyID
                company {
                  id
                  name
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;