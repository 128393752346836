import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Cancel, Forward, PriorityHigh, Send, ThumbDown, ThumbUp } from '@mui/icons-material';
import { API } from 'aws-amplify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import * as mutations from '../../graphql/mutations'
import * as getProjectquery from '../../graphql/getProject';
import config from '../../config.json'
import Emailer from '../../services/RequestEmail';
import Logger from '../../services/Logger';


function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fullWidth: {
    width: '100%',
    marginTop: '0.5em',
    marginBottom: '0.5em'
  }
}));

const CompleteStep = (props) => {
  const { getSetProject, project, user, loading, setLoading, Steps } = props
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState({ workflow: { actions: {} } })
  const [, setActionModals] = useState([])
  const [activeAction, setActiveAction] = useState({})
  const [activeActionModal, setActiveActionModal] = useState({ title: '', description: '', responseType: 'textField'})
  const [activeActionModali, setActiveActionModali] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalReponse, setModalResponse] = useState([])
  const [additionalInfoResponses, setAdditionalInfoResponses] = useState({})
  const logger = new Logger(user.id)

  const getDisabled = () => {
    var disabled = true;

    var roles = {
      admin: false,
      engineering: false,
      purchasing: false,
      quality: false,
      production: false,
      supplier: false
    }

    for (let i = 0; i < Object.keys(roles).length; i++){
      if (project.usergroups.items.find(item => item.name === Object.keys(roles)[i]).users.items.find(item => item.userID === user.id)) {
        roles[Object.keys(roles)[i]] = true
      } 
    }
    
    var stepApprovalRoles = project.steps[project.status.statusState].approvalRoles
    stepApprovalRoles = stepApprovalRoles.concat(project.steps[project.status.statusState].optionalApprovalRoles)

    for (let i = 0; i < stepApprovalRoles.length; i++){
      if (roles[stepApprovalRoles[i]]) {
        disabled = false
      }
    }
    
    return disabled
  }

  useEffect(() => {
    
  }, [project])

  useEffect(() => {
    var tmpstep = project.steps[project.status.statusState]
    setCurrentStep(tmpstep)
  }, [project])

  const UpdateStatus = async (project, newState, steps, user) => {
    const updateStatus = {
      id: project.statusID,
      statusState: newState,
      status: steps[newState].label,
      lastModifierID: user.id,
      _version: project.status._version
    }

    const statusData = await API.graphql({ query: mutations.updateStatus, variables: { input: updateStatus } })
    return statusData
  }

  const AddRoles = async (project, addRoles, user) => {
    if (addRoles) {
      for (let i = 0; i < Object.keys(addRoles).length; i++){
        var addGroups = addRoles[Object.keys(addRoles)[i]]
        if (addGroups.length > 0) {
          var tmpUsers = project[Object.keys(addRoles)[i]]
          for (let j = 0; j < addGroups.length; j++) {
            tmpUsers.push(...project.usergroups.items.filter(item => item.name === addGroups[j])[0].users.items.map(item => item.userID))
          }
          tmpUsers = [...project[Object.keys(addRoles)[i]], ...tmpUsers].filter(onlyUnique)
          const updateProject = {
            id: project.id,
            _version: project._version,
            [Object.keys(addRoles)[i]]: tmpUsers,
            lastModifierID: user.id
          }
          await API.graphql({ query: mutations.updateProject, variables: { input: updateProject } })
        }
      }
    }

    return 1
  }

  const CreateUpdateKV = async (project, newState, user) => {
    var kvData = {}
    if (project[newState]) {
      var kv = project.keyvalues.items.find(item => item.key === newState)
      const updateKV = {
        id: kv.id,
        _version: kv._version,
        value: new Date(),
        lastModifierID: user.id
      }
      kvData = await API.graphql({ query: mutations.updateKeyvalues, variables: { input: updateKV } })
        .then(response => response.data.updateKeyvalues)
    } else {
      const createKV = {
        key: newState,
        value: new Date(),
        creatorID: user.id,
        lastModifierID: user.id,
        projectID: project.id
      }
      kvData = await API.graphql({ query: mutations.createKeyvalues, variables: { input: createKV } })
        .then(response => response.data.createKeyvalues)
    }
    return kvData
  }

  const StepWorkflow = async (direction = 'forward', nSteps = 1, nextStepOption = null) => {
    try {
      setLoading({ ...loading, long: true })
    } catch (err) {
      console.log(err)
    }
    var stepDirection = 'nextStep'
    if (direction === 'backward') {
      stepDirection = 'lastStep'
    }

    var tmpState = project.status.statusState;

    for (let i = 0; i < nSteps; i++) {
      if (nextStepOption) {
        tmpState = project.steps[tmpState][stepDirection][nextStepOption]
      } else {
        tmpState = project.steps[tmpState][stepDirection]
      }
    }

    var addRoles = Steps[tmpState].addRoles
    var adminGroup = project.usergroups.items.find(item => item.name === 'admin')
    var adminUser = adminGroup.users.items[0].user

    var emailer = new Emailer(user.id, user)
    emailer.setAdmin(adminUser)

    try {

      var roles = Steps[tmpState].approvalRoles.concat(Steps[tmpState].notifyRoles)

      roles.map(role => {
        var users2email = project.usergroups.items.find(item => item.name === role).users.items
        users2email = users2email.map(item => item.user)
        users2email.map(recuser => {
          var currentRecipient = recuser
          emailer.setRecipient(currentRecipient)
          var actionRequired = (role !== 'admin') && (Steps[tmpState].approvalRoles.findIndex(item => item === role) > -1)
          if (config.emailNotificationsEnabled) {
            emailer.defineAndSend(actionRequired, Steps[tmpState].label, project.customID, `${project.customID} Moved to ${Steps[tmpState].label}`, additionalInfoResponses[role])
          }
          return 1
        })
        return 1
      })

    } catch (err) {
      logger.log(err)
    }

    await AddRoles(project, addRoles, user)

    await UpdateStatus(project, tmpState, Steps, user)

    await CreateUpdateKV(project, tmpState, user)

    if (Steps[tmpState].finalStep) {
      const updateProject = {
        id: project.id,
        _version: project._version,
        finalStatus: tmpState,
        lastModifierID: user.id
      }
      await API.graphql({ query: mutations.updateProject, variables: { input: updateProject } })
        .then(response => getSetProject(project.id))
      
    } else {
      getSetProject(project.id)
    }
    try {
      setLoading({ ...loading, long: false })
    } catch (err) {
      logger.log(err)
    }
  }

  

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'ThumbUp':
        return (<ThumbUp />)
      case 'ThumbDown':
        return (<ThumbDown />)
      case 'Send':
        return (<Send />)
      case 'PriorityHigh':
        return (<PriorityHigh />)
      case 'Forward':
        return (<Forward />)
      case 'Cancel':
        return (<Cancel />)
      default:
        return (null)
    }
  }

  const handleContentChange = (value) => {
    console.log(value)
    setModalResponse(value);
  };

  const setdialogContent = (modal) => {
    switch (modal.responseType) {
      case 'textField':
        return (
          <TextField
            id="modal-textField"
            label={modal.title}
            variant="outlined"
            value={modalReponse}
            onChange={event  => handleContentChange(event.target.value)}
            className={classes.fullWidth}
          />
        )
      case 'radioButtons':
        return (
          <FormControl variant="standard" component="fieldset" className={classes.fullWidth}>
            <FormLabel component="legend">{modal.title}</FormLabel>
            <RadioGroup
              aria-label="modal-options"
              name="modalOptions"
              value={modalReponse}
              onChange={event => handleContentChange(event.target.value)}
            >
              {modal.radioOptions.map((item, index) => (
                <FormControlLabel key={index} value={item} control={<Radio />} label={item} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case 'select':
        return (
          <Autocomplete
            className={classes.fullWidth}
            id={modal.title}
            value={modalReponse}
            onChange={(event, newValue) => handleContentChange(newValue)}
            renderInput={(params) => <TextField {...params} label={modal.title} />}
            options={modal.options}
          />
        );
      case 'multiSelect':
        return (
          <Autocomplete
            className={classes.fullWidth}
            id={modal.title}
            value={modalReponse}
            onChange={(event, newValue) => handleContentChange(newValue)}
            renderInput={(params) => <TextField {...params} label={modal.title} />}
            options={modal.options}
            multiple
          />
        );
      default:
        return (null)
    }
  }

  const stepModalAction = (action) => {
    setActiveAction(action)
    if (action.modals) {
      setActionModals(action.modals)
      if (activeActionModali < action.modals.length) {
        setActiveActionModal(action.modals[activeActionModali])
        setActiveActionModali(activeActionModali + 1)
      } else {
        StepWorkflow(action.stepDirection, action.nSteps, action.nextStepOption)
      }
      setModalOpen(true)
    } else {
      StepWorkflow(action.stepDirection, action.nSteps, action.nextStepOption)
    }
    
  }

  const handleClose = () => {
    setModalOpen(false)
    setActiveActionModali(0)
    setModalResponse('')
  }

  const handleContinue = async () => {
    var tmpProject = await API.graphql({ query: getProjectquery.getProject, variables: { id: project.id } })
      .then(response => response.data.getProject)
    var currentKV = tmpProject.keyvalues.items.find(item => item.key === `${activeActionModal.key}`)
    if (currentKV) {
      const updateKV = {
        id: currentKV.id,
        value: modalReponse,
        lastModifierID: user.id,
        _version: currentKV._version
      }
      await API.graphql({ query: mutations.updateKeyvalues, variables: { input: updateKV}})
    } else {
      const createKV = {
        key: `${activeActionModal.key}`,
        value: modalReponse,
        creatorID: user.id,
        lastModifierID: user.id,
        projectID: project.id
      }
      await API.graphql({ query: mutations.createKeyvalues, variables: { input: createKV}})

    }
    var responses = additionalInfoResponses
    for (var i = 0; i < activeActionModal.notifyRoles.length; i++) {
      var roleResponse = responses[activeActionModal.notifyRoles[i]]
      if (roleResponse) {
        roleResponse.push({
          fieldType: activeActionModal.responseType,
          title: activeActionModal.title,
          details: modalReponse,
          notifyRoles: activeActionModal.notifyRoles
        })
      } else {
        roleResponse = [{
          fieldType: activeActionModal.responseType,
          title: activeActionModal.title,
          details: modalReponse,
          notifyRoles: activeActionModal.notifyRoles
        }]
      }
      responses[activeActionModal.notifyRoles[i]] = roleResponse
    }
    setAdditionalInfoResponses(responses)
    stepModalAction(activeAction)
  }


  if (currentStep.workflow) {
    return (
      <Card style={{ marginTop: "1em" }}>
        <CardContent>
          <Typography  variant="h5">
            {currentStep.workflow.title}
          </Typography>
            <Typography variant="body2" component="p">
            {currentStep.workflow.body}
          </Typography>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: "space-evenly" }}>
          {Object.keys(currentStep.workflow.actions).map((item, index) => {
            var action = currentStep.workflow.actions[item]
            return (
              <Button
                key={index}
                startIcon={getIcon(action.icon)}
                color={action.color}
                variant="contained"
                disabled={getDisabled()}
                onClick={() => stepModalAction(action)}
              >
                {action.label}
              </Button>
            )
          })}
        </CardActions>
        <Dialog open={modalOpen} onClose={handleClose} aria-labelledby="additional-actions-form-title">
          <DialogTitle id="additional-actions-form-title">{activeActionModal.title}</DialogTitle>
          <DialogContent className={classes.root}>{activeActionModal.description}
            {setdialogContent(activeActionModal)}
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: "space-evenly" }}>
            <Button
              startIcon={getIcon(activeActionModal.cancelIcon)}
              color={activeActionModal.cancelColor}
              variant="contained"
              onClick={() => handleClose()}
            >
              {activeActionModal.cancelLabel}
            </Button>
            <Button
              startIcon={getIcon(activeActionModal.continueIcon)}
              color={activeActionModal.continueColor}
              variant="contained"
              onClick={handleContinue}
            >
              {activeActionModal.continueLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    )  
  } else {
    return (
      <>
      </>
    )
  }
}

export default CompleteStep;