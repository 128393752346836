import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CreateCompany from '../../services/CreateCompany';

const CreateCompanyView = (props) => {
  const { open, closeHandle, companyType = 'Supplier', user} = props;
  
  const [newCompany, setNewCompany] = useState({ name: '' })

  const handleCreateCompany = async() => {
    await CreateCompany(newCompany, user)
    closeHandle()
  }

  return (
    <Dialog open={open} onClose={closeHandle} fullWidth={true}>
      <DialogTitle>Create a New {companyType} </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          label={`New ${companyType} Name`}
          fullWidth={true}
          value={newCompany.name}
          onChange={(event) => setNewCompany({ ...newCompany, name: event.target.value })} />
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: "space-evenly" }}>
        <Button startIcon={<CancelIcon />} variant='contained' color='secondary' onClick={closeHandle}>
          Cancel
        </Button>
        <Button startIcon={<CheckIcon />} variant='contained' color='primary' onClick={() => handleCreateCompany()}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCompanyView;