/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      firstname
      lastname
      superUser
      companyAdmin
      companyID
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      company {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModeluserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getKeyvalues = /* GraphQL */ `
  query GetKeyvalues($id: ID!) {
    getKeyvalues(id: $id) {
      id
      key
      value
      creatorID
      lastModifierID
      userID
      companyID
      partnumberID
      projectID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const listKeyvaluess = /* GraphQL */ `
  query ListKeyvaluess(
    $filter: ModelkeyvaluesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyvaluess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        value
        creatorID
        lastModifierID
        userID
        companyID
        partnumberID
        projectID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKeyvalues = /* GraphQL */ `
  query SyncKeyvalues(
    $filter: ModelkeyvaluesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKeyvalues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        key
        value
        creatorID
        lastModifierID
        userID
        companyID
        partnumberID
        projectID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      users {
        items {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      groups {
        items {
          id
          name
          creatorID
          companyID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelcompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCompanies = /* GraphQL */ `
  query SyncCompanies(
    $filter: ModelcompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      creatorID
      companyID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          userID
          groupID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelgroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        creatorID
        companyID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGroups = /* GraphQL */ `
  query SyncGroups(
    $filter: ModelgroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        creatorID
        companyID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsergroups = /* GraphQL */ `
  query SyncUsergroups(
    $filter: ModelusergroupsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsergroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        groupID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        group {
          id
          name
          creatorID
          companyID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartnumber = /* GraphQL */ `
  query GetPartnumber($id: ID!) {
    getPartnumber(id: $id) {
      id
      objectnumber
      numberType
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      projects {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listPartnumbers = /* GraphQL */ `
  query ListPartnumbers(
    $filter: ModelpartnumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartnumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        objectnumber
        numberType
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        projects {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPartnumbers = /* GraphQL */ `
  query SyncPartnumbers(
    $filter: ModelpartnumberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartnumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        objectnumber
        numberType
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        projects {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStatus = /* GraphQL */ `
  query GetStatus($id: ID!) {
    getStatus(id: $id) {
      id
      status
      statusState
      dueDate
      notes
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const listStatuss = /* GraphQL */ `
  query ListStatuss(
    $filter: ModelstatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStatuses = /* GraphQL */ `
  query SyncStatuses(
    $filter: ModelstatusFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      projectID
      comment
      creatorID
      lastModifierID
      parentID
      childID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      attachmentID
      deliverableID
      inspectionID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelcommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        comment
        creatorID
        lastModifierID
        parentID
        childID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        attachmentID
        deliverableID
        inspectionID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelcommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        comment
        creatorID
        lastModifierID
        parentID
        childID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        attachmentID
        deliverableID
        inspectionID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getActionitem = /* GraphQL */ `
  query GetActionitem($id: ID!) {
    getActionitem(id: $id) {
      id
      actionitem
      duedate
      assigneeID
      actionitemType
      finding
      rootcause
      correctiveAction
      statusID
      creatorID
      lastModifierID
      projectID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      deliverableID
      inspectionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      assignee {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      project {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        supplier {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        partnumber {
          id
          objectnumber
          numberType
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        history {
          nextToken
          startedAt
        }
        owner
        usergroups {
          nextToken
          startedAt
        }
        requirements {
          nextToken
          startedAt
        }
        deliverable {
          nextToken
          startedAt
        }
        inspection {
          nextToken
          startedAt
        }
      }
      attachment {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listActionitems = /* GraphQL */ `
  query ListActionitems(
    $filter: ModelactionitemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionitems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncActionitems = /* GraphQL */ `
  query SyncActionitems(
    $filter: ModelactionitemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActionitems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      projectID
      modifiedID
      modifiedType
      modifiedAction
      modification
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const listHistorys = /* GraphQL */ `
  query ListHistorys(
    $filter: ModelhistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        modifiedID
        modifiedType
        modifiedAction
        modification
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHistories = /* GraphQL */ `
  query SyncHistories(
    $filter: ModelhistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        modifiedID
        modifiedType
        modifiedAction
        modification
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      customID
      name
      partnumberID
      mpartnumber
      description
      projectType
      finalStatus
      dueDate
      supplierID
      statusID
      creatorID
      lastModifierID
      parentID
      childID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      supplier {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      partnumber {
        id
        objectnumber
        numberType
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        projects {
          nextToken
          startedAt
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      history {
        items {
          id
          projectID
          modifiedID
          modifiedType
          modifiedAction
          modification
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      usergroups {
        items {
          id
          projectID
          name
          description
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      requirements {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      deliverable {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      inspection {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          inspectorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelprojectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        supplier {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        partnumber {
          id
          objectnumber
          numberType
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        history {
          nextToken
          startedAt
        }
        owner
        usergroups {
          nextToken
          startedAt
        }
        requirements {
          nextToken
          startedAt
        }
        deliverable {
          nextToken
          startedAt
        }
        inspection {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelprojectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        supplier {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        partnumber {
          id
          objectnumber
          numberType
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        history {
          nextToken
          startedAt
        }
        owner
        usergroups {
          nextToken
          startedAt
        }
        requirements {
          nextToken
          startedAt
        }
        deliverable {
          nextToken
          startedAt
        }
        inspection {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUseraccessgroup = /* GraphQL */ `
  query GetUseraccessgroup($id: ID!) {
    getUseraccessgroup(id: $id) {
      id
      projectID
      name
      description
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          useraccessgroupID
          userID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listUseraccessgroups = /* GraphQL */ `
  query ListUseraccessgroups(
    $filter: ModeluseraccessgroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUseraccessgroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectID
        name
        description
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUseraccessgroups = /* GraphQL */ `
  query SyncUseraccessgroups(
    $filter: ModeluseraccessgroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUseraccessgroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        name
        description
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUseraccessgroupusers = /* GraphQL */ `
  query SyncUseraccessgroupusers(
    $filter: ModeluseraccessgroupusersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUseraccessgroupusers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        useraccessgroupID
        userID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        useraccessgroup {
          id
          projectID
          name
          description
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getRequirement = /* GraphQL */ `
  query GetRequirement($id: ID!) {
    getRequirement(id: $id) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      attachments {
        items {
          id
          requirementID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listRequirements = /* GraphQL */ `
  query ListRequirements(
    $filter: ModelrequirementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequirements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        attachments {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRequirements = /* GraphQL */ `
  query SyncRequirements(
    $filter: ModelrequirementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequirements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        attachments {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRequirementattachments = /* GraphQL */ `
  query SyncRequirementattachments(
    $filter: ModelrequirementattachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequirementattachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        requirementID
        attachmentID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        requirement {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          id
          projectID
          name
          description
          datatype
          location
          filename
          statusID
          creatorID
          lastModifierID
          lastversionID
          nextversionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      projectID
      name
      description
      datatype
      location
      filename
      statusID
      creatorID
      lastModifierID
      lastversionID
      nextversionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelattachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttachments = /* GraphQL */ `
  query SyncAttachments(
    $filter: ModelattachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttachmentcomments = /* GraphQL */ `
  query SyncAttachmentcomments(
    $filter: ModelattachmentcommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachmentcomments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        attachmentID
        commentID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        comment {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          id
          projectID
          name
          description
          datatype
          location
          filename
          statusID
          creatorID
          lastModifierID
          lastversionID
          nextversionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttachmentactionitems = /* GraphQL */ `
  query SyncAttachmentactionitems(
    $filter: ModelattachmentactionitemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachmentactionitems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        attachmentID
        actionitemID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        actionitem {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          id
          projectID
          name
          description
          datatype
          location
          filename
          statusID
          creatorID
          lastModifierID
          lastversionID
          nextversionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getDeliverable = /* GraphQL */ `
  query GetDeliverable($id: ID!) {
    getDeliverable(id: $id) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          deliverableID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          deliverableID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listDeliverables = /* GraphQL */ `
  query ListDeliverables(
    $filter: ModeldeliverableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeliverables = /* GraphQL */ `
  query SyncDeliverables(
    $filter: ModeldeliverableFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliverables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeliverableactionitems = /* GraphQL */ `
  query SyncDeliverableactionitems(
    $filter: ModeldeliverableactionitemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliverableactionitems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deliverableID
        actionitemID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        actionitem {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        deliverable {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDeliverableattachments = /* GraphQL */ `
  query SyncDeliverableattachments(
    $filter: ModeldeliverableattachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliverableattachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deliverableID
        attachmentID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        attachment {
          id
          projectID
          name
          description
          datatype
          location
          filename
          statusID
          creatorID
          lastModifierID
          lastversionID
          nextversionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        deliverable {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspection = /* GraphQL */ `
  query GetInspection($id: ID!) {
    getInspection(id: $id) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      inspectorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      inspector {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          inspectionID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          inspectionID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listInspections = /* GraphQL */ `
  query ListInspections(
    $filter: ModelinspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspections = /* GraphQL */ `
  query SyncInspections(
    $filter: ModelinspectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionactionitems = /* GraphQL */ `
  query SyncInspectionactionitems(
    $filter: ModelinspectionactionitemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionactionitems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inspectionID
        actionitemID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        actionitem {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspection {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          inspectorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionattachments = /* GraphQL */ `
  query SyncInspectionattachments(
    $filter: ModelinspectionattachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionattachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inspectionID
        attachmentID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        attachment {
          id
          projectID
          name
          description
          datatype
          location
          filename
          statusID
          creatorID
          lastModifierID
          lastversionID
          nextversionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspection {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          inspectorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
      startedAt
    }
  }
`;
