import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import IconAvatar from './IconAvatar';
import HelpIcon from '@mui/icons-material/Help';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { format, parse } from 'date-fns';

const CommentAvatar = (item) => {
  if (item.responseType === 'textField'){
    return (
      <IconAvatar icon={<EmailIcon/>}  size='small'/>
    )
  } else if (item.responseType === 'radioButtons') {
    return (
      <IconAvatar icon={<RadioButtonCheckedIcon/>}  size='small'/>
    )

  }
  return (
    <IconAvatar icon={<HelpIcon/>}  size='small'/>
  )
}

const ModalActionsList = (props) => {
  const { modalObjects = [], style } = props
  console.log(modalObjects)
  return (
    <Card style={style}>
      <CardHeader
        title="Special Instructions"
      />
      <CardContent>
        <Grid container spacing={1} direction="column">
          {
            modalObjects.map(item => (
              <Grid item xs={12}>
                <Card >
                  <CardHeader
                    avatar={CommentAvatar(item)}
                    title={`${item.title} for ${item.stepTitle}`}
                    subheader={format(parse(item.kv.createdAt, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()), 'Pp')}
                  />
                  <CardContent>
                    {item.comment}
                  </CardContent>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ModalActionsList;