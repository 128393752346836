import { Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import React from "react";
import DeleteCompanyView from "./DeleteCompanyView";
import {
  Link as RouterLink
} from "react-router-dom";

const CompanyCard = (props) => {
  const { company, handleUpdateCompanies } = props
  
  return (
    <Card>
      <CardHeader
        title={company.name}
        action={
          <>
            <Tooltip title='Edit Company'>
              <IconButton component={RouterLink} to={`companyprofile/${company.id}`} size="large">
                <Edit />
              </IconButton>
            </Tooltip>
            <DeleteCompanyView
              company={company}
              handleUpdateCompanies={() => handleUpdateCompanies()}
            />
          </>
      }
      />
      <CardContent>
        <Typography>
          {`${company.users.items.length} users linked`}
        </Typography>

      </CardContent>
    </Card>
  );
}

export default CompanyCard;