export default function DisabledCheck(userID, project, allowedStates) {
  var approvedUsers = project.stepApprovalRoles.map(
    (role) => project.usergroupsIds[role]
  );
  approvedUsers = [...new Set(approvedUsers.flat(Infinity))];
  var permittedUser = approvedUsers.findIndex((id) => id === userID) >= 0;

  var permittedState =
    allowedStates.findIndex((state) => state === project.activeState) >= 0;

  return !(permittedUser && permittedState);
}
