import React from 'react';
import { Grid } from '@mui/material';
import Comment from './Comment';

const Comments = (props) => {
  const { comments=[], replyHandle } = props
    
  return (
    <Grid container spacing={1} direction="column">
      {comments.sort((first, second) => 0 - (first.createdAt > second.createdAt ? -1 : 1)).map(thiscomment => {
        var lastComment = false
        if (thiscomment.id === comments[comments.length - 1].id) {
          lastComment = true
        }
        return (<Comment commentdetails={thiscomment} replyHandle={replyHandle} lastComment={lastComment}  {...props}/>)
      }
      )}
    </Grid>
  )
}

export default Comments;