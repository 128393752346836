import React, { useContext } from "react";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReqAccordian from "../../Module_FAI/Requirements/ReqAccordian";
import UserContext from "../../../contexts/UserContext";
import CurrentProjectContext from "../../../contexts/CurrentProjectContext";
import CompleteStep from "../../Base/CompleteStep";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Container maxWidth="lg">{children}</Container>}
    </div>
  );
}

const Requirements = (props) => {
  const { value, index, getSetProject, ...other } = props;
  const { user } = useContext(UserContext);
  const { project } = useContext(CurrentProjectContext);

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const [, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(project.sampleApprovalReq);
  }, [project]);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <TabPanel
      value={value}
      index={index}
      padding={"1em"}
      className={classes.root}
    >
      {project.requirements
        ? project.requirements.items.map((req) => (
            <ReqAccordian
              key={req.id}
              panel={req.id}
              expanded={expanded}
              handleAccordianChange={handleAccordianChange}
              requirement={req}
              project={project}
              getSetProject={() => getSetProject(project.id)}
            />
          ))
        : null}
      {project.activePhase === "requirements" ? (
        <CompleteStep
          getSetProject={() => getSetProject(project.id)}
          project={project}
          user={user}
          {...other}
        />
      ) : null}
    </TabPanel>
  );
};

export default Requirements;
