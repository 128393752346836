import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AttachmentList from "../../Utilities/Attachment/Attachment";
import FileUploadBox from "../../Utilities/FileUpload/FileUploadBox";
import UserContext from "../../../contexts/UserContext";
import CurrentProjectContext from "../../../contexts/CurrentProjectContext";
import { API } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import CompleteStep from "../../Base/CompleteStep";
import projectConfigs from "../../../constants/projectConfigs";
import { createUpdateKV } from "../../../services/CreateUpdateKV";
var Steps = { ...projectConfigs.find((item) => item.name === "mtr").steps };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
    marginTop: "1em",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Container maxWidth="lg">{children}</Container>}
    </div>
  );
}

const Samples = (props) => {
  const { value, index, getSetProject, ...other } = props;
  const { user } = useContext(UserContext);
  const { project } = useContext(CurrentProjectContext);
  const classes = useStyles();
  const [attachments, setAttachments] = useState([]);
  const [, setShippingHidden] = useState(false);
  const [uploads, setUploads] = useState({});
  const [descDisabled, setDescDisabled] = useState(true);
  const [descValue, setDescValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (project.deliverable) {
        if (
          project.deliverable.items.findIndex(
            (item) => item.name === "uploads"
          ) === -1
        ) {
          const newDeliverable = {
            projectID: project.id,
            name: "uploads",
            description: "Placeholder for sample uploads",
            creatorID: user.id,
            lastModifierID: user.id,
          };
          await API.graphql({
            query: mutations.createDeliverable,
            variables: { input: newDeliverable },
          })
            .then((response) => getSetProject(project.id))
            .catch((err) => console.log(err));
        } else {
          const tmpuploads = project.deliverable.items.filter(
            (item) => item.name === "uploads"
          )[0];
          setAttachments(tmpuploads.attachments.items);
          setUploads(tmpuploads);
        }
      }
      setDescValue(project.uploadDescription ? project.uploadDescription : "");
    };
    try {
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getIsValidRole = () => {
    if (project.permissions.edit.indexOf(user.id) > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (project.status.statusState === "documents_submit" && getIsValidRole()) {
      setDescDisabled(false);
    } else if (project.status.statusState === "sample_dataaccept") {
      setShippingHidden(true);
    } else if (project.status.statusState === "sample_receive") {
      setShippingHidden(true);
    } else {
      setShippingHidden(true);
    }
  }, [project.sampleApprovalReq, project]);

  const handleDescriptionSubmit = async (event) => {
    await createUpdateKV(
      "uploadDescription",
      event.target.value,
      project,
      user.id
    );
    getSetProject(project.id);
  };

  const handleDescriptionChange = (e) => {
    setDescValue(e.value);
  };

  return (
    <TabPanel
      value={value}
      index={index}
      padding={"1em"}
      className={classes.root}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">Upload Material Test Report</Typography>
          <Grid container spacing={1} className={classes.card}>
            {attachments.map((file) => (
              <AttachmentList
                key={file.id}
                file={file.attachment}
                getSetProject={() => getSetProject(project.id)}
                deleteAllowed={!descDisabled}
              />
            ))}
            {(project.permissions.edit.indexOf(user.id) >= 0) &
            (project.activeState === "documents_submit") ? (
              <FileUploadBox
                attachmentUse="deliverable"
                parentID={uploads.id}
                Steps={Steps}
              />
            ) : null}
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">Description</Typography>

          <TextField
            multiline
            disabled={descDisabled}
            value={descValue}
            onChange={handleDescriptionChange}
            style={{ minWidth: "50%", marginTop: "1em" }}
            id="description"
            label="Description"
            variant="outlined"
            onBlur={handleDescriptionSubmit}
          />
        </CardContent>
      </Card>

      {project.activePhase === "data_upload" ? (
        <CompleteStep
          getSetProject={() => getSetProject(project.id)}
          project={project}
          user={user}
          {...other}
        />
      ) : null}
    </TabPanel>
  );
};

export default Samples;
