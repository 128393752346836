import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadBox from "../../Utilities/FileUpload/FileUploadBox";
import { Storage } from "aws-amplify";
import { CloudDownload } from "@mui/icons-material";
import FileAvatar from "../../Utilities/Attachment/FileAvatar";

import CurrentProjectContext from "../../../contexts/CurrentProjectContext";
import UserContext from "../../../contexts/UserContext";
import LoadingContext from "../../../contexts/LoadingContext";
import ConfirmAction from "../../Base/ConfirmAction";
import DeleteRequirement from "../../../services/DeleteRequirement";
import DisabledCheck from "../../../services/Disabled";
import projectConfigs from "../../../constants/projectConfigs";
import Attachment from "../../Utilities/Attachment/Attachment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flex: 1,
  },
}));

const defaultRequirement = {
  name: "",
  shortDescription: "",
  longDescription: "",
};

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

const ReqAccordian = (props) => {
  const {
    panel,
    expanded,
    handleAccordianChange,
    requirement = defaultRequirement,
    getSetProject,
  } = props;
  const { user } = useContext(UserContext);
  const { project } = useContext(CurrentProjectContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const classes = useStyles();

  const handleDownload = async (file) => {
    setLoading({ ...loading, long: true });
    const result = await Storage.get(file.id, { download: true });
    downloadBlob(result.Body, file.name);
    setLoading({ ...loading, long: false });
  };

  const handleDelete = async () => {
    setConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    console.log("Deleting");
    setConfirmOpen(false);
    DeleteRequirement(requirement.id, requirement._version);
    getSetProject();
  };

  useEffect(() => {
    console.log("Disabled Check");
    console.log(project);
    if (project && user.id) {
      setDeleteDisabled(
        DisabledCheck(user.id, project, ["req_define", "req_eng_approve"])
      );
    }
  }, [user, project]);

  return (
    <Accordion
      expanded={expanded === panel}
      onChange={handleAccordianChange(panel)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={panel + "bh-content"}
        id={panel + "bh-header"}
      >
        <Typography className={classes.heading}>{requirement.name}</Typography>
        <Typography className={classes.secondaryHeading}>
          {requirement.description}
        </Typography>
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <ul>
              {requirement.attachments ? (
                requirement.attachments.items.length > 0 ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div>
                        <List dense={true}>
                          {requirement.attachments.items.map((file, index) => (
                            <ListItem key={index}>
                              <ListItemAvatar>
                                <FileAvatar
                                  filetype={
                                    file.attachment.datatype
                                      ? file.attachment.datatype
                                      : file.attachment.type
                                  }
                                  size="small"
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={file.attachment.filename}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() =>
                                    handleDownload(file.attachment)
                                  }
                                  edge="end"
                                  aria-label="download"
                                  size="large"
                                >
                                  <CloudDownload />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}
            </ul>
          }
        ></FormControlLabel>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>{requirement.longDescription}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {requirement.attachments
                    ? requirement.attachments.items
                        .filter((file) => file.attachment._deleted !== true)
                        .map((file, index) => (
                          <Attachment
                            key={index}
                            file={file.attachment}
                            getSetProject={() => getSetProject()}
                            deleteAllowed={!deleteDisabled}
                          />
                        ))
                    : null}
                  {(project.permissions.edit.indexOf(user.id) >= 0) &
                  (project.activeState === "req_define") ? (
                    <FileUploadBox
                      attachments={requirement.attachments}
                      attachmentUse="requirement"
                      parentID={requirement.id}
                      Steps={
                        projectConfigs.find(
                          (item) => item.name === project.projectType
                        ).steps
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleDelete}
                  disabled={deleteDisabled}
                >
                  Delete
                </Button>
                <ConfirmAction
                  open={confirmOpen}
                  dialogTitle="Are you sure?"
                  dialogContent={`You are about to delete requirement ${requirement.name}. Are you sure you wish to do this?`}
                  handleOnClose={() => setConfirmOpen(false)}
                  handleConfirm={() => handleDeleteConfirm()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReqAccordian;
