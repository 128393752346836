import React, { useEffect, useState, useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker  } from '@mui/x-date-pickers';
import TeamMemberSection from '../Utilities/TeamMembers/TeamMemberSection';
import * as queries from '../../graphql/queries';
import { listProjects } from '../../graphql/listProjects';
import * as mutations from '../../graphql/mutations';
import { getCompany } from '../../graphql/getCompany';
import { API } from 'aws-amplify';
import ProjectsContext from '../../contexts/ProjectsContext';
import { useHistory, useLocation  } from 'react-router-dom';
import * as getProjectquery from '../../graphql/getProject';
import { formatProject } from '../Utilities/FormatProject';
import CurrentProjectContext from '../../contexts/CurrentProjectContext';
import Steps from '../../constants/steps';
import LoadingContext from '../../contexts/LoadingContext';
import graphQuery from '../../services/AWSGraphWrapper';
import ProjectAppBar from './ProjectAppBar';
import GetLocationParams from '../../services/GetLocationParams';
import projectConfigs from '../../constants/projectConfigs';
import GetCompany from '../../services/GetCompany';
import Logger from '../../services/Logger';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
  },
  card: {
    padding: theme.spacing(1),
    minWidth: "100%"
  },
  row: {
    width: '100%',
    padding: theme.spacing(2)
  },
  formItem: {
    width: '100%'
  },
}))

const partOrDrawingOptions = {
  'part': 'Part Number',
  'drawing': 'Quote Drawing Number'
}

const teamGroups = {
  
  supplier: {
    name: 'Supplier',
    description: 'Responsible for completing sample data collection and acknowledging requirements.'
  },
  admin: {
    name: 'Admin',
    description: 'Has full access to the project and can step the project through all steps regardless of responsible party.'
  },
  purchasing: {
    name: 'Purchasing',
    description: 'Can update the project details and team members. Responsible for sending steps to the supplier.'
  },
  engineering: {
    name: 'Engineering',
    description: 'Can create and modify requirements. Responsible for signing-off on inspection.'
  },
  quality: {
    name: 'Quality',
    description: 'Can create and update an inspection. Responsible for completing inspection for Engineering sign-off.'
  },
  production: {
    name: 'Production',
    description: 'Has read only access to the project.'
  },
}

const CreateProject = () => {
  
  let history = useHistory();

  const { user } = useContext(UserContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const { projectlist, setProjectlist } = useContext(ProjectsContext);
  const { project, setProject } = useContext(CurrentProjectContext);

  const classes = useStyles();
  const [partOrDrawing, setPartOrDrawing] = useState('part');
  const [dueDate, setDueDate] = useState(new Date());
  const [availPartNumbers, setAvailPartNumbers] = useState([])
  const [availProjNumbers, setAvailProjNumbers] = useState([])
  const [availCompanys, setAvailCompanys] = useState([])
  const [newProject, setNewProject] = useState(
    {
      customID: {
        customID: ''
      },
      numberType: 'part',
      dueDate: new Date(),
      supplier: {
        name: ''
      },
      quantityUnit: '',
      groups: {}
    })
  const [supplierConfirmOpen, setSupplierConfirmOpen] = useState(false);
  const [supplier, setSupplier] = useState({ name: '' })
  const [groups, setGroups] = useState([{ name: '' }]);

  const [createDisabled, setCreateDisabled] = useState(true)
  const [superCreateDisabled, setSuperCreateDisabled ] = useState(false)
  
  const [availUsers, setAvailUsers] = useState([]);

  const [projType, setProjType ] = useState('mtr')
  const location = useLocation();
  const [projectConfig, setProjectConfig] = useState(projectConfigs.find(item => item.name === 'mtr'))

  const [autocompletes, setAutocompletes] = useState({})

  const logger = new Logger(user.id)

  useEffect(() => {
    setProjectConfig(projectConfigs.find(item => item.name === projType))
    setCreateDisabled(!isFormValid())
  },[projType, newProject, setCreateDisabled])

  useEffect(() => {
    const getAutoCompleteKeys = async(key) => {
      graphQuery({query: queries.listKeyvaluess, variables: { filter: {key: {eq: key}, and: {value: {ne: ""}}}}})
        .then(response => {
          var autocompleteArray = [...new Set(response.map(item => item.value))]
          if (autocompleteArray.length === 0) {
            autocompleteArray = []
          }
          setAutocompletes(autocompletes => ({...autocompletes, [key]: autocompleteArray}))
        })
    }
    
    
    projectConfigs.find(item => item.name === projType).steps.create.keyValuePairs.filter(item => item.type === 'autocomplete').map(item =>getAutoCompleteKeys(item.key))
    
  },[projType])
  
  useEffect(() => {

    const getUnique = (array, key) => {
      const sortedArray = array.sort((a, b) => a[key] > b[key]).sort((a, b) => a._lastChangedAt > b._lastChangedAt)
      return (array
        .map(item => item[key])
        .filter((value, index, self) => self.indexOf(value) === index)
        .map(item => sortedArray.find(sortItem => sortItem[key] === item))
        .sort((a,b) => a[key] > b[key])
      )
      
    }
    const matchPathReturn = GetLocationParams(location)
    setProjType(matchPathReturn.params.main)

    var newProjectId = ''
    
    graphQuery({ query: queries.listUsers })
      .then(response => {
        var tmpusers = response
        setAvailUsers(tmpusers.filter(item => (!item.companyID) | (item.companyID === user.companyID)))
      })
    graphQuery({ query: queries.listPartnumbers })
      .then(response => setAvailPartNumbers(getUnique(response, 'objectnumber')))
    graphQuery({ query: listProjects })
      .then(response => setAvailProjNumbers(response.map(item => item.customID)))
    graphQuery({ query: queries.listCompanys })
      .then(response => {
        console.log(getUnique(response, 'name'))
        setAvailCompanys(getUnique(response, 'name'))
      })    
    GetId(matchPathReturn.params.main)
      .then(response => {
        newProjectId = response.nextId
        API.graphql({ query: getCompany, variables: { id: user.companyID } })
        .then(response => {
          var tmpGroups = response.data.getCompany.groups.items;
          setGroups(response.data.getCompany.groups.items)
          var groupName = Object.keys(projectConfig.roles)
          var projectGroups = {}
          for (let i = 0; i < groupName.length; i++) {
            var grpUsers = []
            if (groupName[i] === 'company') {
              grpUsers = response.data.getCompany.users.items
            } else {
              var tmpGroupi = tmpGroups.filter(grp => grp.name === groupName[i])[0]
              if (tmpGroupi) {
                grpUsers = tmpGroupi.users.items.map(item => item.user)
              }
            }
            projectGroups = {...projectGroups, [groupName[i]]: grpUsers}
          }
          setNewProject(newProject => ({...newProject, groups: projectGroups, customID: {customID :  newProjectId}}))
        })
          .catch(error => {
            logger.error(error)
          })
        })
      .catch(error => {
        logger.error(error)
      })
    
    
    
  }, [user, location, projectConfig])

  
  async function GetId(type) { 
    const apiName = 'guild';
    const path = `/guild/v1/getnextprojectid/${type}`;
    const myInit = { // OPTIONAL
      body: {}, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };
    return API.post(apiName, path, myInit)
  }

  
  const findExisting = (values, idValue, idKey) => {
    return values.find(item => item[idKey] === idValue)
  }

  const handleProjectNumberChange = (event) => {
    if (event.target.dataset.optionIndex) {
      setNewProject({ ...newProject, customID: availProjNumbers[event.target.dataset.optionIndex], newCustomID: false })
    } else {
      setNewProject({ ...newProject, customID: {customID: event.target.value}, newCustomID: true })
    }
  }

  const handlePartOrDrawingChange = (event) => {
    setPartOrDrawing(event.target.value)
    setNewProject({ ...newProject, numberType: event.target.value })
  }

  const handleObjectNumberChange = (event) => {
    var existingObject
    if (event.target.dataset.optionIndex) {
      setNewProject({ ...newProject, objectnumber: availPartNumbers[event.target.dataset.optionIndex], newPartNumber: false })
    } else {
      existingObject = findExisting(availPartNumbers, event.target.value, 'objectnumber')
      if (existingObject) {
        setNewProject({ ...newProject, objectnumber: existingObject, newPartNumber: false })
      } else {
        setNewProject({ ...newProject, objectnumber: {name: event.target.value}, newPartNumber: true })
      }
    }
  }

  
  const handleAutocompleteChange = (event, key) => {
    if (event.target.dataset.optionIndex) {
      setNewProject({ ...newProject, [key]: autocompletes[key][event.target.dataset.optionIndex]})
    } else {
      setNewProject({ ...newProject, [key]: event.target.value})
    }
  }

  const handleDueDateChange = (date) => {
    setDueDate(date);
    setNewProject({ ...newProject, dueDate: date })
  };

  const handleSupplierChange = (event) => {
    var existingObject
    if (event.target.dataset.optionIndex) {
      setSupplier({ ...availCompanys[event.target.dataset.optionIndex] })
      setNewProject({ ...newProject, supplier: availCompanys[event.target.dataset.optionIndex], newSupplier: false })
    } else {
      existingObject = findExisting(availCompanys, event.target.value, 'name')
      if (existingObject) {
        setSupplier({ ...existingObject })
        setNewProject({ ...newProject, supplier: existingObject, newSupplier: false })
      } else {
        setSupplier({ name: event.target.value })
        setNewProject({ ...newProject, supplier: {name: event.target.value}, newSupplier: true })
      }
    }
  }

  const handleSupplierConfirmOpen = (event) => {
    setSupplierConfirmOpen(true);
  }
  
  const handleSupplierConfirmNoClose = () => {
    setNewProject({ ...newProject, supplier: '' })
    setSupplier({ name: '' })
    setSupplierConfirmOpen(false);
  }

  const handleSupplierConfirmYesClose = () => {
    setSupplierConfirmOpen(false);
    if (newProject.newSupplier) {
      const newCompany = {
        name: newProject.supplier.name,
        creatorID: user.id,
        lastModifierID: user.id
      }
      API.graphql({ query: mutations.createCompany, variables: { input: newCompany } })
        .then(response => {
          setNewProject({ ...newProject, supplier: response.data.createCompany, newSupplier: false })
        graphQuery({ query: queries.listCompanys })
          .then(response => setAvailCompanys(response))
      })
    } else {
      GetCompany(newProject.supplier.id)
        .then(response => {
          var tmpGroupi = response.groups.items.find(item => item.name === 'contacts')
          var grpUsers = []
          if (tmpGroupi) {
            grpUsers = tmpGroupi.users.items.map(item => item.user)
          }
          var newProjectGroups = { ...newProject.groups, supplier: grpUsers }
          setNewProject({...newProject, groups: newProjectGroups})
        })
    }

    setCreateDisabled(!isFormValid())
  }

  const handleAddUser = (userNew, group) => {
    var newGroup;
    if (newProject.groups[group]) {
      newGroup = newProject.groups[group]
    } else {
      newProject.groups[group] = []
      newGroup = newProject.groups[group]
    }
    newGroup.push(userNew)
    var newProjectGroups = { ...newProject.groups, [group]: newGroup }
    setNewProject({...newProject, groups: newProjectGroups})
  }

  const handleRemoveUser = (user, group) => {
    var newGroup = newProject.groups[group]
    newGroup = newGroup.filter(oldUser => oldUser.id !== user.id)
    var newProjectGroups = { ...newProject.groups, [group]: newGroup }
    setNewProject({...newProject, groups: newProjectGroups})
  }

  const getSetProject = async (projectid, setTab=false) => {
    
    var tmpProject = await API.graphql({ query: getProjectquery.getProject, variables: { id: projectid } })
    
    var tmpProjects = projectlist.fai
    if (tmpProjects.find(item => item.id ===  'create')) {
      tmpProjects[tmpProjects.findIndex(item => item.id ===  'create')] = tmpProject.data.getProject
    } else {      
      tmpProjects.push(tmpProject.data.getProject)
    }

    var tmpProjectList = {...projectlist}

    tmpProjectList[projType] = tmpProjects
    setProjectlist(tmpProjectList)
    var tmpproject = formatProject(tmpProject.data.getProject, Steps)
    setProject(tmpproject)

  }

  const removeSetProject = async () => {
    var tmpProject;
    var tmpProjects = projectlist[projType]
    
    if(projectlist[projType].length > 1) {
      tmpProjects = tmpProjects.filter(item => item.id !== 'create')
      tmpProject = await API.graphql({query: getProjectquery.getProject , variables: {id: projectlist.fai[0].id }})
    } else {
      tmpProjects = null;
      tmpProject = null;
    }

    var tmpProjectList = {...projectlist}


    if (tmpProject != null) {
      tmpProjectList[projType] = tmpProjects
      setProjectlist(tmpProjectList)
      tmpProject = formatProject(tmpProject.data.getProject, Steps)
      setProject(tmpProject)
      history.push(`/${projType}/${tmpProjects[0].id}`)
    } else {
      history.push('/main')
    }
  }

  const getAbleCreate = () => {
    if (user.createAuth && user.modules) {
      return user.createAuth[projType] && user.modules[projType]
    } else {
      return false
    }
  }

  const isFormValid = () => {
    let valid = true;
    if ((newProject.numberType == null) ||
      (newProject.supplier == null || newProject.supplier.id == null) ||
      (newProject.objectnumber == null) ||
      (newProject.customID.customID === null || newProject.customID.customID === "")
    ) {
      valid = false;
    }
    return valid;
  }


  const AddUserGroup = (groupName) => {
    return newProject.groups[groupName].map(item => item.id)
  }
  const AddGroups = (groupList) => { 
    return [...new Set([].concat(...groupList.map(group => AddUserGroup(group))))]
  }

  const CreatePartNumber = async () => {
    var objectnumberID = ''
    try {
      const newPartNumber = {
        objectnumber: newProject.objectnumber.name,
        numberType: newProject.numberType,
        creatorID: user.id,
        lastModifierID: user.id
  
      }
      objectnumberID = await API.graphql({ query: mutations.createPartnumber, variables: { input: newPartNumber } })
        .then(response => response.data.createPartnumber.id)      
      logger.info(`Created object number for ${newPartNumber.objectnumber}`, newPartNumber)
    } catch (error) {
      logger.error('Error creating part number', error)
    }
    return objectnumberID
  }

  const CreateKeyValuePair = async (projectID, key) => {
    try {
      const newKV = {
        projectID: projectID,
        key: key,
        value: newProject[key] ? newProject[key] : '',
        creatorID: user.id,
        lastModifierID: user.id
      }
      await API.graphql({ query: mutations.createKeyvalues, variables: { input: newKV } })
        .then(response => response.data.createKeyvalues.id)
    } catch (error) {
      logger.error('Error creating Key-Value Pair', error)
    }
  }

  const CreateLinkedObject = async (projectID, requirement, newCreateProject) => {
    try {
      const newStatus = {
        status: 'Open',
        statusState: 'open',
        creatorID: user.id,
        lastModifierID: user.id
      }
  
      console.log(`Creating status for requirement: ${requirement.name}`)
      const statusID = await API.graphql({ query: mutations.createStatus, variables: { input: newStatus } })
  
      const newReq = {
        name: requirement.name,
        description: requirement.description,
        projectID: projectID,
        statusID: statusID.data.createStatus.id,
        creatorID: user.id,
        lastModifierID: user.id,
        adminUser: newCreateProject.adminUser,
        editUser: newCreateProject.editUser,
        viewUser: newCreateProject.viewUser
      }
      await API.graphql({ query: mutations.createRequirement, variables: { input: newReq } })
    } catch (error) {
      logger.error('Error creating linked object', error)
    }
  }

  const handleCreateFai = async () => {

        
    if (isFormValid()) {
      setSuperCreateDisabled(true)
      setCreateDisabled(true)
      setLoading({ ...loading, long: true })
      
      var newCreateProject = {
        customID: newProject.customID.customID,
        name: newProject.customID,
        partnumberID: '',
        projectType: projType,
        dueDate: newProject.dueDate,
        supplierID: newProject.supplier.id,
        statusID: '',
        creatorID: user.id,
        lastModifierID: user.id
      }
      console.log('Create Button Pushed')

      logger.info(`Attempting to create project for ${newProject.customID.customID}`, newProject)

      // Add the groups based on the create step
      var step = projectConfig.steps.create
      Object.keys(step.addRoles).map(key => newCreateProject[key] = AddGroups(step.addRoles[key]))
      console.log(newCreateProject)
      
      // Create PN if necessary
      var objectnumberID = ''
      if (newProject.newPartNumber) {
        objectnumberID = await CreatePartNumber()
      } else {
        objectnumberID = newProject.objectnumber.id
      }
      newCreateProject = { ...newCreateProject, partnumberID: objectnumberID }

      // Create status
      var nextStep = projectConfig.steps[projectConfig.steps.create.nextStep]
      const newStatus = {
        status: nextStep.label,
        statusState: projectConfig.steps.create.nextStep,
        creatorID: user.id,
        lastModifierID: user.id
      }

      const statusID = await API.graphql({ query: mutations.createStatus, variables: { input: newStatus } })
        .then(response => response.data.createStatus.id)

      // Add status ID to the new project - Now we can create it with everything attached
      newCreateProject = { ...newCreateProject, statusID: statusID }

      // Create project
      const projectID = await API.graphql({ query: mutations.createProject, variables: { input: newCreateProject } })
        .then(response => response.data.createProject.id)

      console.log(projectID)

      for (let i = 0; i < Object.keys(teamGroups).length; i++) {
        const newGroup = {
          projectID: projectID,
          name: Object.keys(teamGroups)[i],
          creatorID: user.id,
          lastModifierID: user.id,
          adminUser: newCreateProject.adminUser,
          editUser: newCreateProject.editUser,
          viewUser: newCreateProject.viewUser
        }
        console.log(newGroup)

        // Create access user group
        console.log(`Creating access group ${Object.keys(teamGroups)[i]}`)
        const groupID = await API.graphql({ query: mutations.createUseraccessgroup, variables: { input: newGroup } })
          .then(response => response.data.createUseraccessgroup.id)
        for (let j = 0; j < newProject.groups[Object.keys(teamGroups)[i]].length; j++) {
          const newGroupUser = {
            useraccessgroupID: groupID,
            userID: newProject.groups[Object.keys(teamGroups)[i]][j].id
          }
          console.log(newGroupUser)
          console.log(`Assigning user ${newProject.groups[Object.keys(teamGroups)[i]][j].id}`)

          await API.graphql({ query: mutations.createUseraccessgroupusers, variables: { input: newGroupUser } })
            .then(response => response.data.createUseraccessgroupusers.id)
        }
      }

      console.log('Creating Key-Value pairs')

      // Create KV pairs for number of samples
      step.keyValuePairs.map(item => CreateKeyValuePair(projectID, item.key))

      console.log('Creating Requirements')
      // Create basic requirements
      step.initialLinkedObjects.map(requirement => CreateLinkedObject(projectID, requirement, newCreateProject))

      getSetProject(projectID, true)
      setLoading({ ...loading, long: false })      
      logger.info(`Created project ${projectID}`, newProject)
      history.push(`/${projType}/details/${projectID}`)
    } else {
      
      setCreateDisabled(!isFormValid())
    }
  }

  const handleClose = () => {
    removeSetProject()
  }

  const getField = (item) => {
    if (['text','number'].indexOf(item.type) > -1) {
      return (
        <Tooltip title={item.description ? item.description : item.label} key={item.key}>
          <TextField
            variant="standard"
            className={classes.formControl}
            type={item.type}
            label={item.label}
            onChange={(event) => { setNewProject({ ...newProject, [item.key]: event.target.value }) }} />
        </Tooltip>
      );
    } else if (item.type === 'autocomplete') {
      return (
        <Tooltip title={item.description ? item.description : item.label} key={item.key}>
          <Autocomplete
            id={`autocomplete-item-${item.key}`}
            options={autocompletes[item.key]}
            freeSolo
            onChange={(event) => handleAutocompleteChange(event, item.key)}
            className={classes.formControl}
            renderInput={(params) => <TextField
              variant="standard"
              {...params}
              label={item.label}
              onChange={(event) => handleAutocompleteChange(event, item.key)} />}
          />
        </Tooltip>
      );
    } else if (item.type === 'select') {
      return (
        <Tooltip title={item.description ? item.description : item.label} key={item.key}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel id={`select-item-${item.key}-label`}>{item.label}</InputLabel>
            <Select
              variant="standard"
              labelId={`select-item-${item.key}-label`}
              id={`select-item-${item.key}`}
              onChange={(event) => { setNewProject({ ...newProject, [item.key]: event.target.value }) }}
              value={newProject[item.key] ?? ''}>
              {newProject[item.key] ? null : <MenuItem value={''} key={'def'}>{''}</MenuItem>}
              {item.options.map(itemopt => (<MenuItem value={itemopt} key={itemopt}>{itemopt}</MenuItem>))}
            </Select>
          </FormControl>
        </Tooltip>
      );
    }

  }

  const supplierModal = (supplierName) => {
    if (supplierName && supplierName !== '') {
      return (
        <>
          <DialogTitle id="simple-dialog-title">{supplierName} Selected</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You have chosen <b>{supplierName}</b> as the supplier. This will allow users from <b>{supplierName}</b> to access this project. Do you wish to proceed with <b>{supplierName}</b>?
            </DialogContentText>
            <DialogActions>
              <Button onClick={handleSupplierConfirmNoClose} variant='contained' color="secondary">
                No
              </Button>
              <Button onClick={handleSupplierConfirmYesClose} variant='contained' color="primary">
                Yes
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      )
    }
    return (
      <>
        <DialogTitle id="simple-dialog-title">No Supplier Selected!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You have not chosen a valid supplier. Please re-select.
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setSupplierConfirmOpen(false)} variant="contained" color="primary">
              Okay
            </Button>
          </DialogActions>
        </DialogContent>
      </>
    )
  }
  return (
    <>
      <ProjectAppBar project={newProject.customID} handleClose={handleClose} ableCreate={getAbleCreate()} projectType={projType}/>
      <Container style={{ marginTop: '1em'}}>
        <Card className={classes.card}>
          <Grid container spacing={2} direction="row" className={classes.row}>
            <Grid item xs={12} md={3}>
              <Typography variant="h4">Project Details</Typography>
              <Typography variant="subtitle1">
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} direction="row" className={classes.formItem}>
                <Tooltip title='Identifying number for the project. You can override the default.'>
                  <TextField
                    variant="standard"
                    error={(newProject.customID.customID === '') || (!newProject.customID.customID)}
                    className={classes.formControl}
                    label={`${projType.toUpperCase()} Number`}
                    onChange={handleProjectNumberChange}
                    value={newProject.customID.customID} />
                </Tooltip>
                <Tooltip title='Select if this project refers to a part or quote drawing number. If not applicable, leave as part number'>
                  <FormControl variant="standard" className={classes.formControl}>
                    <InputLabel id="part-or-drawing-number-select-label">Part or Quote Drawing Number</InputLabel>
                    <Select
                      variant="standard"
                      labelId="part-or-drawing-number-select-label"
                      id="part-or-drawing-number-select"
                      value={partOrDrawing}
                      onChange={handlePartOrDrawingChange}>
                      {Object.keys(partOrDrawingOptions).map(key => (<MenuItem value={key}>{partOrDrawingOptions[key]}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Tooltip>
                <Tooltip title={`Select the ${partOrDrawingOptions[partOrDrawing]}`}>
                  <Autocomplete
                    id="combo-box-partOrDrawingNumber"
                    options={availPartNumbers}
                    freeSolo
                    getOptionLabel={(option) => option.objectnumber}
                    onChange={handleObjectNumberChange}
                    className={classes.formControl}
                    renderInput={(params) => <TextField
                      variant="standard"
                      error={(!newProject.objectnumber) || (newProject.objectnumber ? newProject.objectnumber.name === '' : false)}
                      {...params}
                      label={partOrDrawingOptions[partOrDrawing]}
                      onChange={handleObjectNumberChange}
                      required />}
                  />
                </Tooltip>
                <Tooltip title={`Enter the desired due date for completion of the ${projType}`}>
                  <DatePicker 
                    className={classes.formControl}
                    variant="standard"
                    inputFormat="MM/dd/yyyy"
                    margin="normal"
                    id="duedate-picker-inline"
                    label="Due Date"
                    value={dueDate}
                    onChange={handleDueDateChange}
                    renderInput={(params) => <TextField variant='standard' {...params} />}
                  />
                </Tooltip>
                <Tooltip title='Select the supplier for the project. Entering a free-form supplier will create a new supplier.'>
                  <Autocomplete
                    id="combo-box-supplier"
                    options={availCompanys}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    className={classes.formControl}
                    value={supplier}
                    onBlur={handleSupplierConfirmOpen}
                    onClose={handleSupplierChange}
                    renderInput={(params) => <TextField
                      variant="standard"
                      error ={!supplier.id}
                      {...params}
                      onChange={handleSupplierChange}
                      label="Supplier"
                      required />}
                  />
                </Tooltip>
                <Dialog 
                  aria-labelledby="simple-dialog-title"
                  open={supplierConfirmOpen}
                  keepMounted
                >
                  {supplierModal(newProject.supplier.name)}
                </Dialog>
                {projectConfig.steps.create.keyValuePairs.map(item => getField(item))}
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: '3em' }} />
          <Grid container spacing={2} direction="row" className={classes.row}>
            <Grid item xs={12} md={3}>
              <Typography variant="h4">Team Information</Typography>
              <Typography variant="subtitle1">Identify the responsible users for each of the sections</Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={12} md={8}>
              
            </Grid>
          </Grid>
          {Object.keys(projectConfig.roles).map(group => (
            projectConfig.roles[group].show ? 
            <TeamMemberSection
              key={groups[group]}
              group={groups.filter(item => item.name === group)[0] ? groups.filter(item => item.name === group)[0] : { name: group }}
              groupDescription={projectConfig.roles[group]['description']}
              users={newProject.groups[group]}
              handleAddUser={(newUser) => handleAddUser(newUser, group)}
              handleRemoveUser={(user) => handleRemoveUser(user, group)}
              availUsers={availUsers}
            /> : null
          ))}
        </Card>
        <Grid container spacing={2} direction="row" className={classes.row} justifyContent="center">
          <Button
            size="large"
            color="primary"
            variant="contained"
            disabled={createDisabled | superCreateDisabled}
            onClick={handleCreateFai}>{`Create ${newProject.customID ? newProject.customID.customID : projType.toUpperCase()}`}</Button>
        </Grid>
      </Container>
    </>
  );
}

export default CreateProject;