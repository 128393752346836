import { Card, CardContent, CardHeader, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import FileAvatar from '../Attachment/FileAvatar';
import Comments from '../Comment/Comments';
import IconAvatar from '../IconAvatar';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddIcon from '@mui/icons-material/Add';
import NewComment from '../Comment/NewComment';


const CardAvatar = (item) => {
  if (item.parentType === 'attachment') {
    return (
      <FileAvatar filetype={item.datatype ? item.datatype : item.type} size='small'></FileAvatar> 
    )
  }
  else if (item.parentType === 'actionItem') {
    return (
      <IconAvatar icon={<AssignmentTurnedInIcon/>}  size='small'/>
    )
  }
  else if (item.parentType === 'project') {
    return (
      <IconAvatar icon={<AssignmentIcon/>}  size='small'/>
    )
  }
}

const CardTitle = (item) => {
  if (item.parentType === 'attachment') {
    return (
       `Comments on Attachment: ${item.filename}`
    )
  }
  else if (item.parentType === 'actionItem') {
    return (
      `Comments on Action Item: ${item.actionitem}`
    )
  }
  else if (item.parentType === 'project') {
    return (
      `Comments on Project: ${item.name}`
    )
  }

}
const CommentList = (props) => {
  const { commentObjects = [], style } = props
  const [createCommentOpen, setCreateCommentOpen] = useState(false)
  return (
    <Card style={style}>
      <CardHeader
        action={
          <IconButton
            aria-label="create new comment on project"
            onClick={() => setCreateCommentOpen(true)}
            size="large">
            <AddIcon />
          </IconButton>
        }
        title="Comments"
      />
      <CardContent>
        <Grid container spacing={1} direction="column">
          {
            createCommentOpen && (
              <NewComment {...props} />
            )
          }
          {
            commentObjects.map(item => (
              <Grid item xs={12}>
                <Card >
                  <CardHeader
                    avatar={CardAvatar(item)}
                    title={CardTitle(item)}
                  />
                  <CardContent>
                    
                    <Comments
                      comments={item.comments.items}
                      dense={true}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))
          }
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CommentList;