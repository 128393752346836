import React, { useContext, useState, useEffect } from "react";
import { Typography, Grid, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ProjectsContext from "../../contexts/ProjectsContext";
import UserContext from "../../contexts/UserContext";
import { listProjects } from "../../graphql/listProjects";
import { format, parseJSON } from "date-fns";
import ProjectTable from "../Base/ProjectTable";
import { ReactComponent as NewMtrIcon } from "../Utilities/Icons/new_mtr_icon.svg";

import { ReactComponent as MtrIcon } from "../Utilities/Icons/mtr_icon.svg";

import { formatProject } from "../Utilities/FormatProject";
import IconActionButton from "../Utilities/IconActionButton";
import IconRouter from "../Utilities/IconRouter";
import Feature from "../Utilities/Feature";
import graphQuery from "../../services/AWSGraphWrapper";
import projectConfigs from "../../constants/projectConfigs";
var Steps = { ...projectConfigs.find((item) => item.name === "mtr").steps };

const openColumns = [
  { field: "customID", headerName: "MTR Number", width: 180 },
  { field: "objectNumber", headerName: "Part Number", width: 180 },
  { field: "description", headerName: "Part Description", width: 180 },
  { field: "supplierName", headerName: "Supplier", width: 140 },
  { field: "poNumber", headerName: "PO", width: 100 },
  { field: "quantity", headerName: "Quantity", width: 140 },
  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "activeStatus", headerName: "Current Status", width: 200 },
  {
    field: "stepOwner",
    headerName: "Current Step Owner",
    width: 230,
    valueFormatter: (params) =>
      params.value
        ? params.value[0].toUpperCase() + params.value.substring(1)
        : "NA",
  },
  { field: "nOpenActionItems", headerName: "# Open Actions", width: 140 },
  {
    field: "nOpenCActionItems",
    headerName: "# Open Corrective Actions",
    width: 140,
  },
  { field: "nClosedActionItems", headerName: "# Closed Actions", width: 140 },
  {
    field: "nClosedCActionItems",
    headerName: "# Closed Corrective Actions",
    width: 140,
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    width: 150,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
];

const closedColumns = [
  { field: "customID", headerName: "MTR Number", width: 180 },
  { field: "objectNumber", headerName: "Part Number", width: 180 },
  { field: "description", headerName: "Part Description", width: 180 },
  { field: "supplierName", headerName: "Supplier", width: 140 },
  { field: "poNumber", headerName: "PO", width: 100 },
  { field: "quantity", headerName: "Quantity", width: 140 },
  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  {
    field: "completionDate",
    headerName: "Completed Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "nOpenActionItems", headerName: "# Open Actions", width: 140 },
  {
    field: "nOpenCActionItems",
    headerName: "# Open Corrective Actions",
    width: 140,
  },
  { field: "nClosedActionItems", headerName: "# Closed Actions", width: 140 },
  {
    field: "nClosedCActionItems",
    headerName: "# Closed Corrective Actions",
    width: 140,
  },
  { field: "nonconformances", headerName: "# of Nonconformances", width: 150 },
];

const allColumns = [
  { field: "customID", headerName: "MTR Number", width: 180 },
  { field: "objectNumber", headerName: "Part Number", width: 180 },
  { field: "description", headerName: "Part Description", width: 180 },
  { field: "supplierName", headerName: "Supplier", width: 140 },
  { field: "poNumber", headerName: "PO", width: 100 },
  { field: "quantity", headerName: "Quantity", width: 140 },
  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "activeStatus", headerName: "Current Status", width: 200 },
  {
    field: "stepOwner",
    headerName: "Current Step Owner",
    width: 230,
    valueFormatter: (params) =>
      params.value[0].toUpperCase() + params.value.substring(1),
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    width: 150,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },

  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  {
    field: "completionDate",
    headerName: "Last Action Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "nonconformances", headerName: "# of Nonconformances", width: 150 },
  { field: "nOpenActionItems", headerName: "# Open Actions", width: 140 },
  {
    field: "nOpenCActionItems",
    headerName: "# Open Corrective Actions",
    width: 140,
  },
  { field: "nClosedActionItems", headerName: "# Closed Actions", width: 140 },
  {
    field: "nClosedCActionItems",
    headerName: "# Closed Corrective Actions",
    width: 140,
  },
  {
    field: "additionalSamplesRequired",
    headerName: "Additional Samples Required",
    width: 200,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const MtrView = () => {
  const classes = useStyles();
  const [isClosedFilter, setIsClosedFilter] = useState(false);
  const [stateFilter, setStateFilter] = useState();
  const { user } = useContext(UserContext);
  const { projectlist, setProjectlist } = useContext(ProjectsContext);
  const [columns, setColumns] = useState(openColumns);
  const [rows, setRows] = useState([]);
  const [tableName, setTableName] = useState("Open MTRs");

  // Retrieve the project list
  useEffect(() => {
    let newByDate = 604800000;

    var filter = {
      projectType: { eq: "mtr" },
    };
    if (stateFilter === "all") {
      filter = {
        projectType: { eq: "mtr" },
      };
    } else {
      filter = {
        ...filter,
        and: { finalStatus: { attributeExists: isClosedFilter } },
      };
    }
    graphQuery({
      query: listProjects,
      variables: {
        filter: filter,
      },
    })
      .then((response) => {
        var tmpProjects = response;
        if (stateFilter) {
          if (stateFilter === "new") {
            tmpProjects = tmpProjects.filter(
              (proj) => Date.now() - Date.parse(proj.createdAt) < newByDate
            );
          }
        }
        tmpProjects = tmpProjects.map((project) =>
          formatProject(project, Steps, false)
        );
        tmpProjects = handleSort(tmpProjects);
        setRows(tmpProjects);
      })
      .catch((err) => console.log(err));
  }, [user, isClosedFilter, stateFilter]);

  const handleOpenMtrs = () => {
    setStateFilter("open");
    setColumns(openColumns);
    setTableName("Open MTRs");
    setIsClosedFilter(false);
  };

  const handleClosedMtrs = () => {
    setStateFilter("closed");
    setColumns(closedColumns);
    setTableName("Closed MTRs");
    setIsClosedFilter(true);
  };
  const handleAllMtrs = () => {
    setStateFilter("all");
    setTableName("All MTRs");
    setColumns(allColumns);
  };

  //defaults to creation date descending
  const handleSort = (rows, sortType = "default") => {
    switch (sortType) {
      case "":
        break;
      default:
        rows.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
        break;
    }
    return rows;
  };

  const viewAllowed = () => {
    var allowedRoles = [
      "admin",
      "engineering",
      "production",
      "quality",
      "purchasing",
      "allcompany",
    ];
    var allowedRolesInt = allowedRoles.map((role) => (user[role] ? 1 : 0));
    return Math.max(...allowedRolesInt) === 1;
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3">{"MTRs"}</Typography>
      <Container>
        <Grid container spacing={1}>
          <IconActionButton
            onClick={handleOpenMtrs}
            icon={MtrIcon}
            text="Open MTR"
            iconX={3}
          />
          {viewAllowed() ? (
            <IconActionButton
              onClick={handleClosedMtrs}
              icon={MtrIcon}
              text="Closed MTR"
              iconX={3}
            />
          ) : null}
          {viewAllowed() ? (
            <IconActionButton
              onClick={handleAllMtrs}
              icon={MtrIcon}
              text="All MTR"
              iconX={3}
            />
          ) : null}
          <Feature name="mtrCreateEnabled">
            <IconRouter
              link={`/mtr/details/create`}
              icon={NewMtrIcon}
              text="Create New MTR"
              iconX={3}
            />
          </Feature>
        </Grid>
      </Container>

      <ProjectTable
        columns={columns}
        rows={rows}
        projectlist={projectlist}
        setProjectlist={setProjectlist}
        projectType={"mtr"}
        tableName={tableName}
      />
    </div>
  );
};

export default MtrView;
