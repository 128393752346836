import React from "react";
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import MuiDrawer from '@mui/material/Drawer';
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add'

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerIcons: {
    color: "#FFFFFF"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.darkbackground.main,
    color: "#FFFFFF"
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const Drawer = withStyles(theme => ({
  root: props =>
    props.color === "darkbackground"
      ? {
          color: theme.palette.darkbackground.contrastText,
          backgroundColor: theme.palette.darkbackground.main,
        }
      : {}
}))(MuiDrawer);

function NavDrawer(props) {
  const { open, setOpen } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <div className={classes.drawerHeader}>
        <div>
          <img src="/assets/main_logo_reversed.png" alt="Leverage4Data Logo" style={{height: '3em'}}></img>
        </div>
        <IconButton onClick={() => setOpen(!open)} size="large">
          {theme.direction === 'ltr' ? <ChevronLeftIcon className={classes.drawerIcons}/> : <ChevronRightIcon className={classes.drawerIcons}/>}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button >
          <ListItemIcon><AddIcon className={classes.drawerIcons}/></ListItemIcon>
          <ListItemText primary='Create New FAI' />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default NavDrawer;
