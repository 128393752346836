import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import UserProfile from '../Settings/UserProfile';

const MissingUserInfo = (props) => {
  const { open, setOpen } = props
  
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="user-info-dialog"
      open={open}
      fullWidth={true}
      maxWidth="lg"
    >
      <UserProfile />
    </Dialog>
  )
}

MissingUserInfo.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}

MissingUserInfo.defaultProps = {
  open: false,
  setOpen: () => { }
}

export default MissingUserInfo;