import { AppBar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import ActionsButtonGroup from '../Utilities/ActionsButtonGroup';
import TemplateButtonGroup from '../Utilities/TemplateButtonGroup';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderTop: '1px solid #e8e8e8',
    backgroundColor: theme.palette.darkbackground.secondary,
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const FaiAppBar = (props) => {
  const { ...other } = props
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.toolbar}>
      <div className={classes.toolbar}>
        <ActionsButtonGroup
          style={{ flex: 1, borderRight: '1em solid white', padding: '0.5em' }}
          {...other}
        />
        <TemplateButtonGroup 
          style={{ flex: 1 }} 
          {...other}
        />
      </div>
    </AppBar>
  )
}

export default FaiAppBar;