import React, { useContext } from "react";
import { Container, FormControlLabel, FormGroup, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReqAccordian from "./ReqAccordian";
import UserContext from "../../../contexts/UserContext";
import { API } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import CurrentProjectContext from "../../../contexts/CurrentProjectContext";
import CompleteStep from "../../Base/CompleteStep";
import Feature from "../../Utilities/Feature";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Container maxWidth="lg">{children}</Container>}
    </div>
  );
}

const Requirements = (props) => {
  const { value, index, getSetProject, ...other } = props;
  const { user } = useContext(UserContext);
  const { project } = useContext(CurrentProjectContext);

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(project.sample_dataaccept_required);
  }, [project]);

  const toggleChecked = async () => {
    var tmpKV = project.keyvalues.items.find(
      (item) => item.key === "sample_dataaccept_required"
    );
    if (tmpKV) {
      const newKV = {
        id: tmpKV.id,
        _version: tmpKV._version,
        value: !checked,
        lastModifierID: user.id,
      };
      await API.graphql({
        query: mutations.updateKeyvalues,
        variables: { input: newKV },
      }).then((response) => response.data.updateKeyvalues);
    } else {
      const newKV = {
        projectID: project.id,
        key: "sample_dataaccept_required",
        value: !checked,
        creatorID: user.id,
        lastModifierID: user.id,
      };
      await API.graphql({
        query: mutations.createKeyvalues,
        variables: { input: newKV },
      }).then((response) => response.data.createKeyvalues);
    }

    tmpKV = project.keyvalues.items.find(
      (item) => item.key === "sample_upload_required"
    );
    if (tmpKV) {
      const newKV = {
        id: tmpKV.id,
        _version: tmpKV._version,
        value: !checked,
        lastModifierID: user.id,
      };
      await API.graphql({
        query: mutations.updateKeyvalues,
        variables: { input: newKV },
      }).then((response) => response.data.updateKeyvalues);
    } else {
      const newKV = {
        projectID: project.id,
        key: "sample_upload_required",
        value: !checked,
        creatorID: user.id,
        lastModifierID: user.id,
      };
      await API.graphql({
        query: mutations.createKeyvalues,
        variables: { input: newKV },
      }).then((response) => response.data.createKeyvalues);
    }

    setChecked(!checked);
    getSetProject(project.id);
    setChecked((prev) => !prev);
  };
  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <TabPanel
      value={value}
      index={index}
      padding={"1em"}
      className={classes.root}
    >
      <Feature name="fai_sample_approval">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                disabled={project.status.statusState !== "req_define"}
                checked={checked}
                onChange={toggleChecked}
              />
            }
            label="Approval required on sample data prior to shipping sample parts."
          />
        </FormGroup>
      </Feature>
      {project.requirements
        ? project.requirements.items.map((req) => (
            <ReqAccordian
              key={req.id}
              panel={req.id}
              expanded={expanded}
              handleAccordianChange={handleAccordianChange}
              requirement={req}
              project={project}
              getSetProject={() => getSetProject(project.id)}
            />
          ))
        : null}
      {project.activePhase === "requirements" ? (
        <CompleteStep
          getSetProject={() => getSetProject(project.id)}
          project={project}
          user={user}
          {...other}
        />
      ) : null}
    </TabPanel>
  );
};

export default Requirements;
