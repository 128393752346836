import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { API, Storage } from 'aws-amplify';
import React, { useState, useContext } from 'react';
import UserContext from '../../../contexts/UserContext';
import FileUploadBox from '../../Utilities/FileUpload/FileUploadBox';
import * as mutations from '../../../graphql/mutations';
import { formatProject } from '../../Utilities/FormatProject';
import * as getProjectquery from '../../../graphql/getProject';
import AttachmentList from '../../Utilities/Attachment/Attachment';
import { useEffect } from 'react';
import Steps from '../../../constants/steps';

const CreateReq = (props) => {
  const { project, open, handleClose, setProject } = props
  const { user } = useContext(UserContext);
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [attachments, setAttachments] = useState([])

  useEffect(() => {
    setDescription('')
    setName('')
    setAttachments([])
  }, [open])

  const uploadAttachment = async (file, reqID) => {
    var adminUser = [];
    var editUser = [];
    var viewUser = [];

    adminUser.push(...project.usergroups.items.find(item => item.name === 'admin').users.items.map(item => item.userID));
    adminUser.push(...project.usergroups.items.find(item => item.name === 'engineering').users.items.map(item => item.userID));
    viewUser.push(...project.usergroups.items.find(item => item.name === 'purchasing').users.items.map(item => item.userID));
    viewUser.push(...project.usergroups.items.find(item => item.name === 'quality').users.items.map(item => item.userID));
    viewUser.push(...project.usergroups.items.find(item => item.name === 'production').users.items.map(item => item.userID));
    viewUser.push(...project.usergroups.items.find(item => item.name === 'supplier').users.items.map(item => item.userID));
  
    const newAttach = {
      projectID: project.id,
      name: file.name,
      filename: file.name,
      location: '',
      datatype: file.type,
      creatorID: user.id,
      lastModifierID: user.id,
      adminUser: adminUser,
      editUser: editUser,
      viewUser: viewUser

    }
    const attachmentID = await API.graphql({ query: mutations.createAttachment, variables: { input: newAttach } })
      .then(response => response.data.createAttachment.id)
    Storage.put(attachmentID, file, {
      contentType: file.type
    })
      .catch(error => console.log(error))
    
    
    var newAttachLink = {
      attachmentID: attachmentID,
      requirementID: reqID
    }

    API.graphql({ query: mutations.createRequirementattachment, variables: { input: newAttachLink } })
      .catch(err => console.log(err))
  }

  const handleCreate = async () => {
    
    const newStatus = {
      status: 'Open Requirement',
      statusState: 'open',
      creatorID: user.id,
      lastModifierID: user.id
    }

    const statusID = await API.graphql({ query: mutations.createStatus, variables: { input: newStatus } })
    
    var adminUser = project.usergroups.items.find(item => item.name === 'admin').users.items.map(item => item.userID);
    adminUser.push(...project.usergroups.items.find(item => item.name === 'engineering').users.items.map(item => item.userID));
    var editUser = [];
    var viewUser = project.usergroups.items.find(item => item.name === 'purchasing').users.items.map(item => item.userID);
    viewUser.push(...project.usergroups.items.find(item => item.name === 'quality').users.items.map(item => item.userID));
    viewUser.push(...project.usergroups.items.find(item => item.name === 'production').users.items.map(item => item.userID));
    viewUser.push(...project.usergroups.items.find(item => item.name === 'supplier').users.items.map(item => item.userID));

    const newReq = {
      name: name,
      description: description,
      projectID: project.id,
      statusID: statusID.data.createStatus.id,
      creatorID: user.id,
      lastModifierID: user.id,
      adminUser: adminUser,
      editUser: editUser,
      viewUser: viewUser
    }
    const reqID = await API.graphql({ query: mutations.createRequirement, variables: { input: newReq } })
    attachments.map(file => uploadAttachment(file, reqID.data.createRequirement.id))
    var tmpProject = await API.graphql({query: getProjectquery.getProject , variables: {id: project.id }})
    tmpProject = formatProject(tmpProject.data.getProject, Steps)
    setProject(tmpProject)


  }

  const handleUpload = (files) => {
    setAttachments(files)
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">New Requirement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the information below to create a new requirement for {project.customID}
        </DialogContentText>
        <TextField
          variant="standard"
          autoFocus
          margin="dense"
          id="name"
          label="Requirement Name"
          fullWidth
          onChange={(event) => setName(event.target.value)} /><TextField
        variant="standard"
        autoFocus
        margin="dense"
        id="name"
        label="Requirement Description"
        fullWidth
        onChange={(event) => setDescription(event.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleCreate} color="primary" variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateReq;