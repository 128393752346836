import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material';
import React, {useState } from 'react';
import SelectUser from './SelectUser';
import TeamMember from './TeamMember';

const useStyles = makeStyles((theme) => ({
  row: {
    width: '100%',
    padding: theme.spacing(2)
  },
  userDivider: {
    width: '100%',
  }
}))

const TeamMemberSection = (props) => {
  const {
    group = { name: '' },
    groupDescription,
    users,
    deEmphasizeGroup = false,
    availUsers = [],
    handleAddUser,
    handleRemoveUser
  } = props
  
  const classes = useStyles();
  const [addUserDialog, setAddUserDialog] = useState(false);
  

  const handleAddUserWrapper = (newUser) => {
    setAddUserDialog(false)
    handleAddUser(newUser)
  }

  const handleClose = () => {
    setAddUserDialog(false)
  }

  const handleRemoveUserWrapper = (oldUser) => {
    handleRemoveUser(oldUser)
  }
  
  return (
    <Grid container spacing={2} direction="row" className={classes.row}>
      <Grid item xs={12} md={deEmphasizeGroup ? 2 : 3}>
        <Typography variant={deEmphasizeGroup ? "h6" : "h5"} align="right">{ group.name.charAt(0).toUpperCase() + group.name.slice(1)}</Typography>
        <Typography variant={deEmphasizeGroup ? "caption" : "subtitle1"} align="right">{groupDescription}</Typography>
      </Grid>
      <Grid item xs={12} md={1}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={12} md={deEmphasizeGroup ? 9 : 8}>
        <Grid container spacing={2} direction="row" className={classes.row}>
          <Grid item xs={12}>
            {users ? users.map(user => (
              <TeamMember key={user.id}  teamMember={user} group={group} withRole={false} isCreating={true} handleClear={handleRemoveUserWrapper}/>
            )) : null}

          </Grid>
          {handleAddUser ? (
            <Grid item xs={12}>
              <Tooltip title='Add a user for this role'>
                <IconButton
                  style={{float: "right"}}
                  color="primary"
                  onClick={() => setAddUserDialog(true)}
                  size="large"><Add /></IconButton>
              </Tooltip>
              <SelectUser group={group} users={availUsers} handleAddUser={(oldUser) => handleAddUserWrapper(oldUser) } handleClose={() => handleClose()} open={addUserDialog}/>
            </Grid>
          ) : null}
          <Divider className={classes.userDivider}/>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TeamMemberSection;