import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FileUploadBox from "../../Utilities/FileUpload/FileUploadBox";
import { Add } from "@mui/icons-material";
import CaAccordian from "../../Module_CorrectiveAction/CaAccordian";
import UserContext from "../../../contexts/UserContext";
import CurrentProjectContext from "../../../contexts/CurrentProjectContext";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import CompleteStep from "../../Base/CompleteStep";
import { API } from "aws-amplify";
import UserAvatar from "../../Utilities/TeamMembers/UserAvatar";
import { DatePicker } from "@mui/x-date-pickers";
import CreateActionItem from "../../Utilities/ActionItem/CreateActionItem";
import { parseJSON } from "date-fns";
import graphQuery from "../../../services/AWSGraphWrapper";
import projectConfigs from "../../../constants/projectConfigs";
import Attachment from "../../Utilities/Attachment/Attachment";
var Steps = { ...projectConfigs.find((item) => item.name === "mtr").steps };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
    marginTop: "1em",
  },
  form: {
    width: "100%",
    marginTop: "0.5em",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Container maxWidth="lg">{children}</Container>}
    </div>
  );
}

const Inspection = (props) => {
  const { value, index, getSetProject, ...other } = props;
  const { user } = useContext(UserContext);
  const { project, setProject } = useContext(CurrentProjectContext);
  const classes = useStyles();
  const [attachments, setAttachments] = useState([]);
  const [reviewHidden, setReviewHidden] = useState(true);

  const [expanded, setExpanded] = React.useState(false);

  const [inspection, setInspection] = useState({});
  const [createCAOpen, setCreateCAOpen] = React.useState(false);
  const [availUsers, setAvailUsers] = useState([]);
  const [assignee, setAssignee] = useState({ id: null });
  const [cARs, setCARs] = useState([]);
  const [accessIDs, setAccessIDs] = useState({});
  const [inspectionDate, setInspectionDate] = useState(null);
  const [inspectionEditDisabled, setInspectionEditDisabled] = useState(true);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const stepValidated = () => {
    if (assignee.id && inspectionDate) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (project.inspection) {
        if (
          project.inspection.items.findIndex(
            (item) => item.name === "inspection"
          ) === -1
        ) {
          const newInspection = {
            projectID: project.id,
            name: "inspection",
            description: "",
            creatorID: user.id,
            inspectorID: user.id,
            lastModifierID: user.id,
          };
          await API.graphql({
            query: mutations.createInspection,
            variables: { input: newInspection },
          })
            .then((response) => getSetProject(project.id))
            .catch((err) => console.log(err));
        } else {
          var tmpInsp = project.inspection.items.filter(
            (item) => item.name === "inspection"
          )[0];
          setInspection(tmpInsp);
          graphQuery({ query: queries.listUsers }).then((response) => {
            var tmpusers = response;
            setAvailUsers(
              tmpusers.filter((item) => item.companyID === user.companyID)
            );
          });
          setAttachments(tmpInsp.attachments.items);
          if (tmpInsp.status) {
            setAssignee(tmpInsp.inspector);
            setInspectionDate(tmpInsp.status.dueDate);
          } else {
            setAssignee({ id: null });
            setInspectionDate(null);
          }
          var adminUser = [];
          var editUser = [];
          var viewUser = [];

          try {
            adminUser.push(
              ...project.usergroups.items
                .find((item) => item.name === "admin")
                .users.items.map((item) => item.userID)
            );
            editUser.push(
              ...project.usergroups.items
                .find((item) => item.name === "engineering")
                .users.items.map((item) => item.userID)
            );
            editUser.push(
              ...project.usergroups.items
                .find((item) => item.name === "purchasing")
                .users.items.map((item) => item.userID)
            );
            editUser.push(
              ...project.usergroups.items
                .find((item) => item.name === "quality")
                .users.items.map((item) => item.userID)
            );
            editUser.push(
              ...project.usergroups.items
                .find((item) => item.name === "production")
                .users.items.map((item) => item.userID)
            );
            editUser.push(
              ...project.usergroups.items
                .find((item) => item.name === "supplier")
                .users.items.map((item) => item.userID)
            );
          } catch (err) {
            console.log(err);
          }

          setAccessIDs({
            adminUser: adminUser,
            editUser: editUser,
            viewUser: viewUser,
          });
          var tmpCars = project.actionitems.items.filter(
            (item) => item.actionitemType === "corrective"
          );
          setCARs(
            tmpCars.sort((a, b) => parseJSON(a.duedate) - parseJSON(b.duedate))
          );
        }
      }
    };
    try {
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, [project, setProject, user]);

  useEffect(() => {
    switch (project.status.statusState) {
      case "review_quality":
        setReviewHidden(false);
        break;
      case "review_engineering":
        setReviewHidden(false);
        break;
      case "approved":
        setReviewHidden(false);
        break;
      case "rejected":
        setReviewHidden(false);
        break;
      default:
        setReviewHidden(true);
    }

    try {
      if (project.status.statusState === "review_quality") {
        if (
          project.usergroups.items
            .find((item) => item.name === "admin")
            .users.items.find((item) => item.userID === user.id)
        ) {
          setInspectionEditDisabled(false);
        } else if (
          project.usergroups.items
            .find((item) => item.name === "quality")
            .users.items.find((item) => item.userID === user.id)
        ) {
          setInspectionEditDisabled(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [project]);

  useEffect(() => {
    graphQuery({ query: queries.listUsers }).then((response) => {
      var tmpusers = response;
      setAvailUsers(
        tmpusers.filter((item) => item.companyID === user.companyID)
      );
    });
  }, []);

  const handleAssigneeChange = async (event) => {
    setAssignee(availUsers.filter((item) => item.id === event.target.value)[0]);

    const updateInspection = {
      id: inspection.id,
      _version: inspection._version,
      inspectorID: event.target.value,
      lastModifierID: user.id,
    };
    const updatedInspection = await API.graphql({
      query: mutations.updateInspection,
      variables: { input: updateInspection },
    }).then((response) => response.data.updateInspection);

    setInspection(updatedInspection);
  };

  const handleDueDateChange = async (date) => {
    setInspectionDate(date);
    var status = {};
    if (inspection.status === "inspected") {
      const updateStatus = {
        id: inspection.statusID,
        _version: inspection.status._version,
        dueDate: date,
        lastModifierID: user.id,
      };
      status = await API.graphql({
        query: mutations.updateStatus,
        variables: { input: updateStatus },
      }).then((response) => response.data.updateStatus);
    } else {
      const newStatus = {
        status: "Inspected",
        statusState: "inspected",
        creatorID: user.id,
        lastModifierID: user.id,
        dueDate: date,
      };
      status = await API.graphql({
        query: mutations.createStatus,
        variables: { input: newStatus },
      }).then((response) => response.data.createStatus);

      const updateInspection = {
        id: inspection.id,
        _version: inspection._version,
        statusID: status.id,
        lastModifierID: user.id,
      };
      const updatedInspection = await API.graphql({
        query: mutations.updateInspection,
        variables: { input: updateInspection },
      }).then((response) => response.data.updateInspection);

      setInspection(updatedInspection);
    }
  };

  return (
    <TabPanel
      value={value}
      index={index}
      padding={"1em"}
      className={classes.root}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">Results</Typography>
          <Grid container spacing={1} className={classes.card}>
            <Grid item xs={12} md={6}>
              <FormControl variant="standard" className={classes.form}>
                <InputLabel id="inspectedby-label">Inspected By</InputLabel>
                <Select
                  variant="standard"
                  disabled={inspectionEditDisabled}
                  labelId="inspectedby-label"
                  id="inspectedby-select"
                  value={assignee.id}
                  onChange={handleAssigneeChange}
                  label="Inspected By"
                >
                  {availUsers.map((useri) => (
                    <MenuItem key={useri.id} value={useri.id}>
                      <ListItemAvatar>
                        <UserAvatar email={useri.username} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${useri.firstname} ${useri.lastname}`}
                        secondary={useri.username}
                      />
                      <ListItemText
                        secondary={useri.company ? useri.company.name : null}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                className={classes.form}
                disabled={inspectionEditDisabled}
                disableToolbar
                variant="contained"
                inputFormat="MM/dd/yyyy"
                margin="normal"
                id="duedate-picker-inline"
                label="Inspection Date"
                value={inspectionDate}
                onChange={handleDueDateChange}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.card}>
            {attachments.map((file) => (
              <Attachment
                key={file.id}
                file={file.attachment}
                getSetProject={() => getSetProject(project.id)}
                deleteAllowed={!inspectionEditDisabled}
              />
            ))}
            {(project.permissions.edit.indexOf(user.id) >= 0) &
            (project.activeState === "review_quality") ? (
              <FileUploadBox
                attachmentUse="inspection"
                parentID={inspection.id}
                Steps={Steps}
              />
            ) : null}
          </Grid>
        </CardContent>
      </Card>

      {reviewHidden ? null : (
        <>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5">Review Comments</Typography>
              <Grid container spacing={1} className={classes.card}>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%", marginTop: "1em" }}
                    id="outlined-basic"
                    label="Comments"
                    variant="outlined"
                    disabled={
                      !(
                        (project.permissions.edit.indexOf(user.id) > 0) &
                        (project.activeState === "review_engineering")
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardHeader
              action={
                <IconButton
                  aria-label="create new non-conformance"
                  onClick={() => setCreateCAOpen(true)}
                  size="large"
                >
                  <Add />
                </IconButton>
              }
              title="Non-Conformance"
            />
            <CardContent>
              <Grid container spacing={1} className={classes.card}>
                {cARs.map((car) => (
                  <Grid item xs={12} key={car.id}>
                    <CaAccordian
                      panel="panel1"
                      expanded={expanded}
                      handleAccordianChange={handleAccordianChange}
                      correctiveAction={car}
                    />
                  </Grid>
                ))}

                <CreateActionItem
                  visible={createCAOpen}
                  setVisible={setCreateCAOpen}
                  corrective={true}
                  accessIDs={accessIDs}
                  getSetProject={() => getSetProject(project.id)}
                  projectID={project.id}
                />
              </Grid>
            </CardContent>
          </Card>
        </>
      )}

      {project.activePhase === "review" &&
      !(project.activeState === "rejected") &&
      stepValidated() ? (
        <CompleteStep
          getSetProject={() => getSetProject(project.id)}
          project={project}
          user={user}
          {...other}
        />
      ) : project.activeState === "rejected" &&
        project.nOpenCActionItems === 0 ? (
        <CompleteStep
          getSetProject={() => getSetProject(project.id)}
          project={project}
          user={user}
          {...other}
        />
      ) : null}
    </TabPanel>
  );
};

export default Inspection;
