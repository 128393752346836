import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import config from "./config.json";

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PRIMARY_MAIN
        ? process.env.REACT_APP_PRIMARY_MAIN
        : config.palette.primary.main,
    },
    secondary: {
      main: process.env.REACT_APP_SECONDARY_MAIN
        ? process.env.REACT_APP_SECONDARY_MAIN
        : config.palette.secondary.main,
    },
    third: {
      main: process.env.REACT_APP_THIRD_MAIN
        ? process.env.REACT_APP_THIRD_MAIN
        : config.palette.third.main,
    },
    darkbackground: {
      main: process.env.REACT_APP_DARKBACKGROUND_MAIN
        ? process.env.REACT_APP_DARKBACKGROUND_MAIN
        : config.palette.darkbackground.main,
      contrastText: process.env.REACT_APP_DARKBACKGROUND_CONTRAST_TEXT
        ? process.env.REACT_APP_DARKBACKGROUND_CONTRAST_TEXT
        : config.palette.darkbackground.contrastText,
      secondary: process.env.REACT_APP_DARKBACKGROUND_SECONDARY
        ? process.env.REACT_APP_DARKBACKGROUND_SECONDARY
        : config.palette.darkbackground.secondary,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
