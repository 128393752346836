import { API } from "aws-amplify";
import { getCompany } from '../graphql/getCompany';


const removeDeletedUsers = (groups) => {
  return (
    groups.map(group => (
      { ...group, users: { items: group.users.items.filter(item => item._deleted !== true) } }
    ))
  )
}

const GetCompany = async(companyId) => {
  const originalCompany = await API.graphql({ query: getCompany, variables: { id: companyId } })
  var newCompany = {
    ...originalCompany.data.getCompany,
    groups: { items: removeDeletedUsers(originalCompany.data.getCompany.groups.items) }
  }
  return newCompany
}

export default GetCompany;