import React from "react";
import {
  ButtonGroup,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  AddRounded,
  Assignment,
  ChevronLeft,
  ChevronRight,
  CreateNewFolder,
  Edit,
  FolderOpen,
  Save,
} from "@mui/icons-material";
import CreateReq from "../Module_FAI/Requirements/CreateReq";
import { useHistory } from "react-router-dom";

const ActionsButtonGroup = (props) => {
  const {
    project,
    setProject,
    projectlist,
    setProjectlist,
    setTabValue,
    ableCreate,
    projectType,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  let history = useHistory();

  const handleClickOpen = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClickNewFai = (event) => {
    setAnchorMenu(null);

    var tmpProject = {
      data: {
        getProject: {
          id: "create",
          customID: "New Project",
          keyvalues: {
            items: [],
          },
          partnumber: { objectnumber: "", numberType: "" },
          supplier: { id: "", name: "" },
          usergroups: {
            items: [],
          },
          requirements: {
            items: [],
          },
          status: {
            statusState: "assign_team",
          },
        },
      },
    };

    var tmpFaiProjects = projectlist.fai;

    if (tmpFaiProjects.find((item) => item.id === "create")) {
      tmpFaiProjects[tmpFaiProjects.findIndex((item) => item.id === "create")] =
        tmpProject.data.getProject;
    } else {
      tmpFaiProjects.push(tmpProject.data.getProject);
    }

    setTabValue(tmpFaiProjects.findIndex((item) => item.id === "create"));

    setProjectlist({ ...projectlist, fai: tmpFaiProjects });

    history.push("/fai/details/create");
  };

  const handleClickNewMtr = (event) => {
    setAnchorMenu(null);

    var tmpProject = {
      data: {
        getProject: {
          id: "create",
          customID: "New Project",
          keyvalues: {
            items: [],
          },
          partnumber: { objectnumber: "", numberType: "" },
          supplier: { id: "", name: "" },
          usergroups: {
            items: [],
          },
          requirements: {
            items: [],
          },
          status: {
            statusState: "assign_team",
          },
        },
      },
    };

    var tmpMtrProjects = projectlist.mtr;

    if (tmpMtrProjects.find((item) => item.id === "create")) {
      tmpMtrProjects[tmpMtrProjects.findIndex((item) => item.id === "create")] =
        tmpProject.data.getProject;
    } else {
      tmpMtrProjects.push(tmpProject.data.getProject);
    }

    setTabValue(tmpMtrProjects.findIndex((item) => item.id === "create"));

    setProjectlist({ ...projectlist, mtr: tmpMtrProjects });

    history.push("/mtr/details/create");
  };

  const handleClose = () => {
    setAnchorMenu(null);
    setOpen(false);
  };

  const handleNewRequirementOpen = () => {
    setOpen(true);
    setAnchorMenu(null);
  };
  return (
    <ButtonGroup color="inherit">
      <Tooltip title="Create New" disabled={!ableCreate}>
        {ableCreate ? (
          <IconButton
            disabled={!ableCreate}
            onClick={handleClickOpen}
            size="large"
            color="inherit"
          >
            <AddRounded />
          </IconButton>
        ) : (
          <span>
            <IconButton
              disabled={!ableCreate}
              onClick={handleClickOpen}
              size="large"
              color="inherit"
            >
              <AddRounded />
            </IconButton>
          </span>
        )}
      </Tooltip>
      <Menu
        id="new-menu"
        anchorEl={anchorMenu}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorMenu)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleNewRequirementOpen}>
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          New Requirement
        </MenuItem>
        {projectType === "fai" ? (
          <MenuItem onClick={handleClickNewFai}>
            <ListItemIcon>
              <CreateNewFolder />
            </ListItemIcon>
            New FAI
          </MenuItem>
        ) : null}
        {projectType === "mtr" ? (
          <MenuItem onClick={handleClickNewMtr}>
            <ListItemIcon>
              <CreateNewFolder />
            </ListItemIcon>
            New MTR
          </MenuItem>
        ) : null}
      </Menu>
      <CreateReq
        project={project}
        setProject={setProject}
        open={open}
        handleClose={() => setOpen(false)}
      />

      <Tooltip title={`Open Another ${projectType.toUpperCase()}`}>
        <span>
          <IconButton disabled={true} size="large" color="inherit">
            <FolderOpen />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Save Changes">
        <span>
          <IconButton disabled={true} size="large" color="inherit">
            <Save />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={`Edit ${projectType.toUpperCase()}`}>
        <span>
          <IconButton disabled={true} size="large" color="inherit">
            <Edit />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Last Iteration">
        <span>
          <IconButton disabled={true} size="large" color="inherit">
            <ChevronLeft />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Next Iteration">
        <span>
          <IconButton disabled={true} size="large" color="inherit">
            <ChevronRight />
          </IconButton>
        </span>
      </Tooltip>
    </ButtonGroup>
  );
};

export default ActionsButtonGroup;
