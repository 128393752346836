import { Paper, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";

const Timeline = (props) => {
  const { steps = {}, threeColumn, activeStep = 0 } = props;

  return (
    <Paper style={{ maxWidth: "100%", overflow: "auto", padding: "1em" }}>
      <Stepper
        activeStep={activeStep}
        orientation={threeColumn ? "vertical" : "horizontal"}
      >
        {Object.keys(steps).map((step, index) => (
          <Step key={index}>
            <StepLabel>{steps[step].label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
};

export default Timeline;
