import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  outerCircle: {
    animation: "$spin 2s infinite ease-in-out",
    border: "solid 2vmin transparent",
    borderRadius: "50%",
    borderRightColor: "#71c491",
    borderTopColor:"#f7941d",
    boxSizing: "border-box",   
    height: "20vmin",
    left: "calc(50% - 10vmin)",
    position: "fixed",
    top:"calc(50% - 10vmin)",
    width:"20vmin",
    zIndex:"1"
  },
  midCircle: {
      animation: "$spin 4s infinite linear",
      border:"solid 2vmin transparent",
      borderRadius:"50%",
      borderRightColor:"#21409a",
      borderTopColor:"#92278f",
      boxSizing: "border-box",
      content:"",  
      height:"16vmin",
      left: "0",
      position: "relative",
      top: "0",
      width:"16vmin" 
    },
    innerCircle: { 
      animation:"$spin 6s infinite linear",
      border:"solid 2vmin transparent",
      borderRadius:"50%",
      borderRightColor:"#13b0e6",
      borderTopColor: "#18244c",
      boxSizing:"border-box",
      content:"",
      height:"12vmin",
      left: "0",
      position: "relative",
      top: "0",
      width:"12vmin"  
  },
  "@keyframes spin": {
    "100%": { 
      transform:"rotate(360deg)"
    } 
  },

  
  inline: {
    display: 'inline',
  },
}));

const L4DSpinner = () => {
  
  const classes = useStyles();
  return (
    <div className={classes.outerCircle} >
      <div className={classes.midCircle}>
        <div className={classes.innerCircle} />
      </div>
    </div>
  );
  }


  export default L4DSpinner;