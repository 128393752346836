/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_ADMIN_QUERIES_ENDPOINT,
            "region": process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_ADMIN_QUERIES_REGION
        },
        {
            "name": "notification",
            "endpoint": process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_NOTIFICATION_ENDPOINT,
            "region": process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_NOTIFICATION_REGION
        },
        {
            "name": "guild",
            "endpoint": process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_GUILD_ENDPOINT,
            "region": process.env.REACT_APP_AWS_CLOUD_LOGIC_CUSTOM_GUILD_REGION
        }
    ],
    "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
    "aws_appsync_region": process.env.REACT_APP_AWS_APPSYNC_REGION,
    "aws_appsync_authenticationType": process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
    "aws_user_files_s3_bucket": process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET,
    "aws_user_files_s3_bucket_region": process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_REGION
};


export default awsmobile;
