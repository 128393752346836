import KVs from "../../constants/kvs.json";

const evaluateKv = (kv) => {
  var returnKv = "";
  if (kv) {
    if (kv.value === "true") {
      returnKv = true;
    } else if (kv.value === "false") {
      returnKv = false;
    } else {
      returnKv = kv.value;
    }
  } else {
    returnKv = null;
  }

  return returnKv;
};

const getCurrentStepOwner = (step, Steps) => {
  var steps = { ...Steps };
  var stepKeys = Object.keys(steps);
  var approvalRoles = [];
  for (var i = 0; i < stepKeys.length; i++) {
    if (stepKeys[i] === step) {
      if (steps[stepKeys[i]].approvalRoles) {
        approvalRoles = steps[stepKeys[i]].approvalRoles;
        if (steps[stepKeys[i]].optionalApprovalRoles) {
          approvalRoles = approvalRoles.concat(
            steps[stepKeys[i]].optionalApprovalRoles
          );
        }
        return approvalRoles.toString().replace("admin,", "");
      } else {
        return "admin";
      }
    }
  }
};

const getCurrentStepUsers = (step, Steps) => {
  var steps = { ...Steps };
  var stepKeys = Object.keys(steps);
  var approvalRoles = [];
  for (var i = 0; i < stepKeys.length; i++) {
    if (stepKeys[i] === step) {
      approvalRoles = steps[stepKeys[i]].approvalRoles;
      if (steps[stepKeys[i]].optionalApprovalRoles) {
        approvalRoles = approvalRoles.concat(
          steps[stepKeys[i]].optionalApprovalRoles
        );
      }
      return approvalRoles;
    }
  }
};

const getAttachments = (parentItem) => {
  if (parentItem.attachments.items) {
    parentItem.attachments.items = parentItem.attachments.items.filter(
      (item) => !item.attachment._deleted
    );
  }
  return parentItem;
};

export const formatProject = (tmpproject, Steps, comprehensive = true) => {
  // Project key-value pairs
  var tmpKV = {};
  for (let i = 0; i < KVs.length; i++) {
    tmpKV = tmpproject.keyvalues.items.find((item) => item.key === KVs[i]);
    tmpproject[KVs[i]] = evaluateKv(tmpKV);
  }

  // Extra detected key-value pairs
  var kvs = tmpproject.keyvalues.items.map((item) => item.key);
  for (let i = 0; i < kvs.length; i++) {
    tmpKV = tmpproject.keyvalues.items.find((item) => item.key === kvs[i]);
    tmpproject[kvs[i]] = evaluateKv(tmpKV);
  }

  // Requirements
  if (tmpproject.requirements) {
    if (tmpproject.requirements.items) {
      tmpproject.requirements.items = tmpproject.requirements.items.filter(
        (item) => !item._deleted
      );
      tmpproject.requirements.items = tmpproject.requirements.items.map(
        (item) => getAttachments(item)
      );
    }
  }

  // Deliverables
  if (tmpproject.deliverable) {
    if (tmpproject.deliverable.items) {
      tmpproject.deliverable.items = tmpproject.deliverable.items.filter(
        (item) => !item._deleted
      );
      tmpproject.deliverable.items = tmpproject.deliverable.items.map((item) =>
        getAttachments(item)
      );
    }
  }

  // Inspections
  if (tmpproject.inspection) {
    if (tmpproject.inspection.items) {
      tmpproject.inspection.items = tmpproject.inspection.items.filter(
        (item) => !item._deleted
      );
      tmpproject.inspection.items = tmpproject.inspection.items.map((item) =>
        getAttachments(item)
      );
    }
  }
  // Special items for the fai/mtr view
  tmpproject.objectNumber = tmpproject.partnumber
    ? tmpproject.partnumber.objectnumber
    : "";
  tmpproject.createdBy = tmpproject.createdBy
    ? tmpproject.creator.firstname + " " + tmpproject.creator.lastname
    : "";
  tmpproject.supplierName = tmpproject.supplier ? tmpproject.supplier.name : "";
  tmpproject.completionDate = tmpproject.status
    ? tmpproject.status.updatedAt
    : "";
  let nonconformances = 0;
  if (tmpproject.actionitems) {
    if (tmpproject.actionitems.items) {
      for (let k = 0; k < tmpproject.actionitems.items.length; k++) {
        if (tmpproject.actionitems.items[k].actionitemType === "corrective") {
          nonconformances++;
        }
      }
    }
  }
  tmpproject.nonconformances = nonconformances;

  if (tmpproject.status) {
    tmpproject.stepOwner = getCurrentStepOwner(
      tmpproject.status.statusState,
      Steps
    );
    tmpproject.stepApprovalRoles = getCurrentStepUsers(
      tmpproject.status.statusState,
      Steps
    );
  }

  if (!tmpproject.stepApprovalRoles) {
    tmpproject.stepApprovalRoles = [];
  }
  // Project steps
  var steps = { ...Steps };
  var stepKeys = Object.keys(steps);
  for (let i = 0; i < stepKeys.length; i++) {
    if (
      steps[stepKeys[i]].optionalStep &&
      !tmpproject[stepKeys[i]] &&
      !steps[stepKeys[i]].defaultFinalStep
    ) {
      delete steps[stepKeys[i]];
    }
  }

  if (steps[tmpproject.status.statusState]) {
    if (
      steps[tmpproject.status.statusState].finalStep &&
      !steps[tmpproject.status.statusState].defaultFinalStep
    ) {
      stepKeys = Object.keys(steps);
      for (let i = 0; i < stepKeys.length; i++) {
        if (steps[stepKeys[i]].finalStep && !tmpproject[stepKeys[i]]) {
          delete steps[stepKeys[i]];
        }
      }
    }
  }
  tmpproject.steps = steps;

  // Comments
  if (comprehensive) {
    var allComments = [];
    var commentable = ["deliverable", "inspection", "requirements", "project"];
    for (let i = 0; i < commentable.length; i++) {
      if (tmpproject[commentable[i]]) {
        var items = tmpproject[commentable[i]].items;
        for (var j = 0; j < items.length; j++) {
          var attachments = items[j].attachments.items;
          for (var k = 0; k < attachments.length; k++) {
            var attachment = attachments[k].attachment;
            if (attachment) {
              attachment["parentObject"] = commentable[i];
              attachment["parentType"] = "attachment";
              if (attachment.comments.items.length > 0) {
                allComments = allComments.concat(attachment);
              }
            }
          }
        }
      }
    }

    if (tmpproject.actionitems) {
      for (let i = 0; i < tmpproject.actionitems.items.length; i++) {
        var actionItem = tmpproject.actionitems.items[i];
        actionItem["parentObject"] = commentable[i];
        actionItem["parentType"] = "actionItem";
        if (actionItem.comments.items.length > 0) {
          allComments = allComments.concat(actionItem);
        }
      }
    }

    if (tmpproject.comments) {
      for (let i = 0; i < tmpproject.comments.items.length; i++) {
        let commentObjectTmp = null;
        let comment = tmpproject.comments.items[i];
        if (comment.actionitemID) {
          //console.log('actionItem')
        } else if (comment.attachmentID) {
          //console.log('attachment')
        } else if (comment.deliverableID) {
          //console.log('deliverable')
        } else if (comment.inspectionID) {
          //console.log('inspection')
        } else if (comment.historyID) {
          //console.log('history')
        } else if (comment.partnumberID) {
          //console.log('partnumber')
        } else if (comment.requirementID) {
          //console.log('requirement')
        } else {
          //console.log('project')
          let projectIndex = allComments.findIndex(
            (item) => item.parentType === "project"
          );
          //console.log(projectIndex)
          if (projectIndex > -1) {
            commentObjectTmp = allComments[projectIndex];
            let comments = commentObjectTmp.comments.items;
            comments.push(comment);
            commentObjectTmp.comments.items = comments;

            allComments[projectIndex] = commentObjectTmp;
          } else {
            commentObjectTmp = {
              name: tmpproject.customID,
              comments: { items: [comment] },
              parentObject: "project",
              parentType: "project",
              creator: tmpproject.creator,
            };
            allComments.push(commentObjectTmp);
          }
        }
      }
    }

    tmpproject.allCommentObjects = allComments;
  }

  // Possible email keyvalues
  tmpproject.modalActions = [];
  const addActionComment = (modal, step) => {
    var actionComment = modal;
    actionComment["parentObject"] = "actionModal";
    actionComment["parentType"] = "actionModal";
    actionComment["stepTitle"] = step.label;
    actionComment["comment"] = tmpproject[modal.key];
    actionComment["kv"] = tmpproject.keyvalues.items.find(
      (item) => item.key === modal.key
    );
    if (actionComment["comment"] !== "" && actionComment["comment"]) {
      //console.log(actionComment)
      tmpproject.modalActions = tmpproject.modalActions.concat(actionComment);
    }
  };

  const actionModals = (action, step) => {
    if (action.modals) {
      action.modals.map((modal) => addActionComment(modal, step));
    }
  };

  const stepWFmodals = (step) => {
    if (step.workflow) {
      if (step.workflow.actions) {
        Object.keys(step.workflow.actions).map((action) =>
          actionModals(step.workflow.actions[action], step)
        );
      }
    }
  };

  Object.keys(tmpproject.steps).map((step) =>
    stepWFmodals(tmpproject.steps[step])
  );

  // Project Status
  tmpproject.activeStep = Object.keys(steps).findIndex(
    (item) => item === tmpproject.status.statusState
  );
  tmpproject.activeStatus = tmpproject.status.status;
  tmpproject.activeState = tmpproject.status.statusState;
  if (tmpproject.activeStep === Object.keys(steps).length - 1) {
    tmpproject.activeStep = tmpproject.activeStep + 1;
  }
  if (steps[tmpproject.status.statusState]) {
    tmpproject.activePhase = steps[tmpproject.status.statusState].phase;
  } else {
    tmpproject.activePhase = "create";
  }

  // Usergroups
  tmpproject.permissions = { edit: [], read: [] };
  tmpproject.usergroupsIds = {};
  for (let i = 0; i < tmpproject.usergroups.items.length; i++) {
    var currentGroup = tmpproject.usergroups.items[i];
    var currentGroupName = currentGroup.name;
    var currentGroupUsers = currentGroup.users.items.map((item) => item.userID);
    tmpproject.usergroupsIds[currentGroupName] = currentGroupUsers;
    tmpproject.permissions.read.push(...currentGroupUsers);
  }

  // Permissions
  for (let i = 0; i < tmpproject.stepApprovalRoles.length; i++) {
    let currentGroupName = tmpproject.stepApprovalRoles[i];
    try {
      tmpproject.permissions.edit.push(
        ...tmpproject.usergroupsIds[currentGroupName]
      );
    } catch (err) {}
  }
  tmpproject.permissions.edit = Array.from(
    new Set(tmpproject.permissions.edit)
  );
  tmpproject.permissions.read = Array.from(
    new Set(tmpproject.permissions.read)
  );

  // Number of action items
  if (!tmpproject.actionitems) {
    tmpproject.actionitems = { items: [] };
  }
  //console.log(tmpproject.actionitems.items)
  if (tmpproject.actionitems.items.length > 0) {
    var openActionItems = tmpproject.actionitems.items.filter(
      (item) =>
        (item.status ? item.status.statusState !== "closed" : true) &&
        item.actionitemType === "basic"
    );
    var closedActionItems = tmpproject.actionitems.items.filter(
      (item) =>
        (item.status ? item.status.statusState === "closed" : false) &&
        item.actionitemType === "basic"
    );
    var openCActionItems = tmpproject.actionitems.items.filter(
      (item) =>
        (item.status ? item.status.statusState !== "closed" : false) &&
        item.actionitemType === "corrective"
    );
    var closedCActionItems = tmpproject.actionitems.items.filter(
      (item) =>
        (item.status ? item.status.statusState === "closed" : false) &&
        item.actionitemType === "corrective"
    );
    tmpproject.nOpenActionItems = openActionItems.length
      ? openActionItems.length
      : 0;
    tmpproject.nClosedActionItems = closedActionItems.length
      ? closedActionItems.length
      : 0;
    tmpproject.nOpenCActionItems = openCActionItems.length
      ? openCActionItems.length
      : 0;
    tmpproject.nClosedCActionItems = closedCActionItems.length
      ? closedCActionItems.length
      : 0;
  } else {
    tmpproject.nOpenActionItems = 0;
    tmpproject.nClosedActionItems = 0;
    tmpproject.nOpenCActionItems = 0;
    tmpproject.nClosedCActionItems = 0;
  }

  //console.log(tmpproject)
  return tmpproject;
};
