import { API } from "aws-amplify"
import * as mutations from '../graphql/mutations';

const companyGroups = [
  'purchasing',
  'engineering',
  'quality',
  'production',
  'admin',
  'contacts'
]

const selfCompanyGroups = [
  'purchasing',
  'engineering',
  'quality',
  'production',
  'admin',
]

const supplierGroups = [
  'contacts'
]

const CreateCompanyGroup = async (groupName, userID, companyID) => {
  const newGroup = {
    name: groupName,
    creatorID: userID,
    lastModifierID: userID,
    companyID: companyID
  }

  return await API.graphql({ query: mutations.createGroup, variables: { input: newGroup } })
    .then(response => response.data.createGroup.id)
}

const CreateCompany = async (company, creator, isSupplier = true) => {
  var groups = companyGroups
  if (isSupplier) {
    groups = supplierGroups
  } else {
    groups = selfCompanyGroups
  }

  const newCompany = {
    name: company.name,
    creatorID: creator.id,
    lastModifierID: creator.id
  }

  console.log(`Creating company ${newCompany.name}`)
  const companyID = await API.graphql({ query: mutations.createCompany, variables: { input: newCompany } })
    .then(response => response.data.createCompany.id)
  
  groups.map(group => CreateCompanyGroup(group, creator.id, companyID))
  
  return companyID
}

export default CreateCompany;