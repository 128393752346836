import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { listActionitems } from '../../../graphql/queries';
import { useState } from 'react';
import { List } from '@mui/material';
import ActionItem from './ActionItem';
import { parseJSON } from 'date-fns';
import graphQuery from '../../../services/AWSGraphWrapper';

const OwnActionItems = (props) => {
  const { user, actionitemType='basic' } = props;
  const [actionItems, setActionItems] = useState([])

  useEffect(() => {

    const getActionItems = async () => {
      try {
        const tmpActionItems = await graphQuery({ query: listActionitems, variables: { filter: { assigneeID: { eq: user.id ? user.id : '' } } } })
          .then(response => response.filter(item => ((item.status.statusState !== 'closed') & (item.actionitemType === actionitemType))))
        setActionItems(tmpActionItems.sort((a, b) => parseJSON(a.duedate)-parseJSON(b.duedate)))
      } catch (err) {
        console.log(err)
      }
    }
    
    if (user.id) {
      getActionItems()
    }
  }, [user, actionitemType])
  

  return (  
    <List >
      {actionItems.map(ai => (<ActionItem key={ai.id} actionItemID={ai.id} user={user} secondary={'project'} />))}
    </List>
  )
}

OwnActionItems.propTypes = {
  user: PropTypes.object
}

OwnActionItems.defaultProps = {
  user: {
    id: ''
  }
}

export default OwnActionItems;