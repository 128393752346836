import React, { useContext } from "react";
import {
  Grid,
  Typography,
  Divider,
  ButtonGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Clear, Edit } from "@mui/icons-material";
import UserAvatar from "./UserAvatar";
import UserContext from "../../../contexts/UserContext";

const TeamMember = (props) => {
  const {
    teamMember,
    role,
    withRole = true,
    handleEdit,
    handleClear,
    isCreating = false,
  } = props;
  const { user } = useContext(UserContext);

  const handleClearUserWrapper = (oldUser) => {
    handleClear(oldUser);
  };

  const isUserAdmin = () => {
    let isAdmin = false;

    console.log(user);
    let userGroups = user.company.groups;
    if (userGroups) {
      let adminGroup = userGroups.items.find((item) => item.name === "admin");
      if (adminGroup) {
        if (
          adminGroup.users.items.find((item) => item.userID === user.id) !==
          null
        ) {
          isAdmin = true;
        }
      }
    }

    return isAdmin;
  };

  return (
    <Grid container style={{ marginBottom: "1em", width: "100%" }}>
      <Grid item xs={1} style={{ marginRight: "0.5em" }}>
        <UserAvatar email={teamMember.username} />
      </Grid>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginRight: "0.5em" }}
      />
      <Grid
        item
        xs={
          withRole
            ? handleEdit || handleClear
              ? 6
              : 7
            : handleEdit || handleClear
            ? 9
            : 10
        }
      >
        <Typography variant="body1">
          {teamMember.firstname + " " + teamMember.lastname}
        </Typography>
      </Grid>
      {withRole ? (
        <>
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: "0.5em" }}
          />
          <Grid item xs={3}>
            <Typography variant="overline">Role</Typography>
            <Typography variant="body1">{role}</Typography>
          </Grid>
        </>
      ) : null}
      {handleEdit || (handleClear && isUserAdmin()) || isCreating ? (
        <>
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: "0.5em" }}
          />
          <Grid item xs={1}>
            <ButtonGroup
              style={{ float: "right" }}
              color="primary"
              aria-label="outlined primary button group"
            >
              {handleEdit ? (
                <Tooltip title="Edit user">
                  <IconButton size="large">
                    <Edit />
                  </IconButton>
                </Tooltip>
              ) : null}
              {handleClear ? (
                <Tooltip title="Remove user from role">
                  <IconButton
                    onClick={() => handleClearUserWrapper(teamMember)}
                    size="large"
                  >
                    <Clear />
                  </IconButton>
                </Tooltip>
              ) : null}
            </ButtonGroup>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default TeamMember;
