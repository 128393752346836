import config from '../config.json'

const sendEmail = (email_config, userID) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    ...email_config,
    'messageTime': new Date(),
    'app': 'guild-beta',
    'host': window.location.host,
    'url': window.location.href,
    'userId': userID
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  console.log(raw)

  fetch(process.env.REACT_APP_EMAILAPI_ENDPOINT, requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

}


class Emailer {
  constructor(userId, user, recipient, admin) {
    this.userId = userId
    this.email_message = {
      email_header_bgcolor: process.env.REACT_APP_DARKBACKGROUND_MAIN ? process.env.REACT_APP_DARKBACKGROUND_MAIN : config.palette.darkbackground.main,
      email_header_color: process.env.REACT_APP_DARKBACKGROUND_CONTRAST_TEXT ? process.env.REACT_APP_DARKBACKGROUND_CONTRAST_TEXT : config.palette.darkbackground.contrastText,
      email_header_logo: process.env.REACT_APP_HEADER_LOGO_W_NAME ? process.env.REACT_APP_HEADER_LOGO_W_NAME : config.headerLogowName,            
    }
    
    if (user) {
      this.setUser(user)
    }

    if (recipient) {
      this.setRecipient(recipient)
    }

    if (admin) {
      this.setAdmin(admin)
    }
  }

  setUser(user) {
    this.user = user
    this.email_message = {
      ...this.email_message,
      sender_firstname: this.user.firstname,
      sender_email: this.user.email
    }
  }

  setRecipient(recipient) {
    this.recipient = recipient
    this.email_message = {
      ...this.email_message,
      recipient_firstname: this.recipient.firstname,
      recipient_email: this.recipient.email
    }

  }

  setAdmin(admin) {
    this.admin = admin
    this.email_message = {
      ...this.email_message,
      admin_firstname: this.admin.firstname,
      admin_lastname: this.admin.lastname,
      admin_email: this.admin.email
    }
  }

  defineMessage(actionRequired = true, stepLabel, projectCustomId, emailSubject, additionalInfoResponses = []) {
    this.email_message = {
      ...this.email_message,
      email_type: actionRequired ? 'action_required' : 'action_norequired',
      email_subject: emailSubject,
      step_label: stepLabel,
      project_customID: projectCustomId,
      project_url: window.location.href,
      additional_information_enable: additionalInfoResponses.length > 0,
      additional_information_responses: additionalInfoResponses
    }
  }

  send() {
    sendEmail(this.email_message, this.userId)
  }

  defineAndSend(actionRequired = true, stepLabel, projectCustomId, emailSubject, additionalInfoResponses = []) {
    this.defineMessage(actionRequired, stepLabel, projectCustomId, emailSubject, additionalInfoResponses)
    this.send()
  }

}

export default Emailer;