import React, { useContext } from "react";
import clsx from "clsx";
import {
  Avatar,
  Badge,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { red } from "@mui/material/colors";
import { useState } from "react";
import {
  CloudDownload,
  Delete,
  ExpandMore,
  MoreVert,
} from "@mui/icons-material";
import CommentIcon from "@mui/icons-material/Comment";
import Comments from "../Comment/Comments";
import { format } from "date-fns";
import NewComment from "../Comment/NewComment";
import { API, Storage } from "aws-amplify";
import * as mutations from "../../../graphql/mutations";
import LoadingContext from "../../../contexts/LoadingContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const default_file = {
  id: "",
  comments: [],
};

const FileAvatar = (filetype) => {
  if (filetype === "image/png") {
    return (
      <Avatar aria-label="recipe" variant="square" src={"/assets/png.png"} />
    );
  } else if (filetype === "image/jpeg") {
    return (
      <Avatar aria-label="recipe" variant="square" src={"/assets/jpg.png"} />
    );
  } else if (
    filetype ===
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ) {
    return (
      <Avatar aria-label="recipe" variant="square" src={"/assets/pptx.png"} />
    );
  } else if (
    filetype ===
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return (
      <Avatar aria-label="recipe" variant="square" src={"/assets/docx.png"} />
    );
  } else if (filetype === "application/pdf") {
    return (
      <Avatar aria-label="recipe" variant="square" src={"/assets/pdf.png"} />
    );
  } else {
    return (
      <Avatar aria-label="recipe" variant="square" src={"/assets/file.png"} />
    );
  }
};

const Attachment = (props) => {
  const {
    file = default_file,
    condensed = false,
    getSetProject,
    deleteAllowed = false,
  } = props;

  console.log(deleteAllowed);

  const { loading, setLoading } = useContext(LoadingContext);
  const [expanded, setExpanded] = useState(false);
  const [newcomment, setNewcomment] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleMoreClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleMoreClose = (event) => {
    setAnchorMenu(null);
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  const handleDownload = async () => {
    setLoading({ ...loading, long: true });
    const result = await Storage.get(file.id, { download: true });
    downloadBlob(result.Body, file.name);

    setLoading({ ...loading, long: false });
    setAnchorMenu(null);
  };

  const handleDelete = () => {
    const deletefile = {
      id: file.id,
      _version: file._version,
    };
    API.graphql({
      query: mutations.deleteAttachment,
      variables: { input: deletefile },
    });

    setAnchorMenu(null);
    getSetProject();
  };

  const handleExpandClick = () => {
    if (expanded) {
      setNewcomment(false);
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
  };

  const handleNewCommentClick = () => {
    if (newcomment) {
      setNewcomment(!newcomment);
    } else {
      setNewcomment(!newcomment);
      setExpanded(true);
    }
  };

  const classes = useStyles();
  return (
    <Grid
      key={file.id}
      item
      xs={12}
      sm={condensed ? 12 : 6}
      md={condensed ? 12 : 6}
    >
      <Card className={classes.root}>
        <CardHeader
          avatar={FileAvatar(file.datatype ? file.datatype : file.type)}
          action={
            <>
              <Tooltip title="More">
                <IconButton aria-label="settings" onClick={handleMoreClick} size="large">
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                id="simple-menu"
                anchorEl={anchorMenu}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorMenu)}
                onClose={handleMoreClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => handleDownload()}>
                  <ListItemIcon>
                    <CloudDownload />
                  </ListItemIcon>
                  Download
                </MenuItem>
                <MenuItem
                  disabled={!deleteAllowed}
                  onClick={() => handleDelete()}
                >
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  Delete
                </MenuItem>
              </Menu>
            </>
          }
          title={file.name}
          subheader={
            <>
              <Typography variant="caption">
                {file._lastChangedAt
                  ? `${format(new Date(file._lastChangedAt), "Pp")}`
                  : `${format(new Date(file.lastModified), "Pp")}`}
              </Typography>
              <br></br>
              <Typography variant="caption">{`Uploaded by ${file.creator.firstname} ${file.creator.lastname}`}</Typography>
            </>
          }
        />
        {file.description && (
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {file.description}
            </Typography>
          </CardContent>
        )}
        {!condensed ? (
          <CardActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginRight: 7,
            }}
          >
            <Tooltip title="Add a comment">
              <IconButton aria-label="add comment" onClick={handleNewCommentClick} size="large">
                <CommentIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View comments">
              <Badge
                badgeContent={file.comments ? file.comments.items.length : 0}
                color="primary"
              >
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show comments"
                  size="large">
                  <ExpandMore />
                </IconButton>
              </Badge>
            </Tooltip>
          </CardActions>
        ) : null}
      </Card>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ flex: 1 }}>
          {file.comments ? (
            file.comments.items.length > 0 ? null : (
              <Typography variant="body2">
                No comments available for this item.
              </Typography>
            )
          ) : (
            <Typography variant="body2">No Comments.</Typography>
          )}
          <Comments
            comments={file.comments ? file.comments.items : []}
            replyHandle={handleNewCommentClick}
          />
        </CardContent>
      </Collapse>
      <Collapse in={newcomment} timeout="auto" unmountOnExit>
        <CardContent style={{ flex: 1 }}>
          <NewComment
            attachmentID={file.id}
            getSetProject={() => getSetProject()}
          />
        </CardContent>
      </Collapse>
    </Grid>
  );
};

export default Attachment;
