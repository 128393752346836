import React from "react";
import { ButtonGroup, IconButton, Tooltip } from "@mui/material";
import { Close, GetApp, Publish } from "@mui/icons-material";

const TemplateButtonGroup = (props) => {
  const { handleClose, projectType = "" } = props;

  return (
    <ButtonGroup color="inherit">
      <Tooltip title="Load from Template">
        <span>
          <IconButton disabled={true} size="large" color="inherit">
            <GetApp />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Save as Template">
        <span>
          <IconButton disabled={true} size="large" color="inherit">
            <Publish />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={`Close Current ${projectType.toUpperCase()}`}>
        <IconButton
          disabled={false}
          onClick={handleClose}
          size="large"
          color="inherit"
        >
          <Close />
        </IconButton>
      </Tooltip>
    </ButtonGroup>
  );
};

export default TemplateButtonGroup;
