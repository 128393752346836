import { API, Auth } from "aws-amplify";
import * as getUserquery from "../graphql/getUser";
import * as mutations from "../graphql/mutations";
import Logger from "./Logger";

const removeDeletedUsers = (groups) => {
  return groups.map((group) => ({
    ...group,
    users: {
      items: group.users.items.filter((item) => item._deleted !== true),
    },
  }));
};

const setRoles = (userData) => {
  var userIds = [];
  var company = {
    ...userData.company,
    groups: { items: removeDeletedUsers(userData.company.groups.items) },
  };
  company.groups.items.map((item) => {
    if (item.users) {
      userIds = item.users.items.map((user) => user.userID);
    }
    if (userIds.includes(userData.id)) {
      userData[item.name] = true;
    } else {
      userData[item.name] = false;
    }

    return userData[item.name];
  });
  return userData;
};

const isAuthorized = (userData, authGroups) => {
  var authIds = [];
  var isAuth = false;
  if (userData.company) {
    var oldcompany = {
      ...userData.company,
      groups: { items: removeDeletedUsers(userData.company.groups.items) },
    };
    oldcompany.groups.items.map((item) => {
      if (item.users && authGroups.includes(item.name)) {
        authIds = item.users.items.map((user) => user.userID);
      }

      if (authIds.includes(userData.id)) {
        isAuth = true;
      }

      return isAuth;
    });
  }

  return isAuth;
};

const featureActive = (
  featureName,
  shortName,
  featureDescription,
  userData,
  AuthGroups
) => {
  var features = JSON.parse(localStorage.getItem("flags"));
  var elementsIndex = features.findIndex(
    (element) => element.name === featureName
  );
  var authorized = isAuthorized(userData, AuthGroups);
  if (elementsIndex >= 0) {
    features[elementsIndex] = {
      name: featureName,
      description: featureDescription,
      active: authorized,
    };
  } else {
    features = [
      ...features,
      {
        name: featureName,
        description: featureDescription,
        active: authorized,
      },
    ];
  }
  localStorage.setItem("flags", JSON.stringify(features));
  userData = {
    ...userData,
    createAuth: { ...userData.createAuth, [shortName]: authorized },
    [featureName]: authorized,
  };
  return userData;
};

const createUser = async (userData) => {
  const newUser = {
    id: userData.username,
    email: userData.attributes.email,
    username: userData.attributes.email,
    creatorID: userData.username,
    lastModifierID: userData.username,
  };
  return API.graphql({
    query: mutations.createUser,
    variables: { input: newUser },
  }).then((userData) => userData.data.createUser);
};

const getUser = (user, setUser) => {
  var tmpuser = {};
  var features = JSON.parse(localStorage.getItem("flags"));
  const logger = new Logger(null, "INFO");

  return Auth.currentAuthenticatedUser()
    .then((userData) => {
      tmpuser = userData;
      return API.graphql({
        query: getUserquery.getUser,
        variables: { id: userData.username },
      });
    })
    .then((userData) => {
      if (userData.data.getUser === null) {
        return createUser(tmpuser);
      } else {
        return userData.data.getUser;
      }
    })
    .then((userData) => {
      userData = featureActive(
        "faiCreateEnabled",
        "fai",
        "User can create FAI",
        userData,
        ["engineering", "admin"]
      );
      userData = featureActive(
        "mtrCreateEnabled",
        "mtr",
        "User can create MTRs",
        userData,
        ["engineering", "admin", "purchasing"]
      );
      if (userData.company) {
        userData = setRoles(userData);
      }

      var tmpuser = {
        ...user,
        ...userData,
        loggedIn: true,
        modules: {
          fai: features.find((feature) => feature.name === "module_fai_enable")
            .active,
          mtr: features.find((feature) => feature.name === "module_fai_enable")
            .active,
        },
      };

      logger.setUserId(user.id);
      logger.info(`Retrieved user ${tmpuser.username}`);
      setUser(tmpuser);
    })
    .catch((err) => console.log(err));
};

export default getUser;
