export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      users {
        items {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      groups {
        items {
          id
          name
          creatorID
          companyID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
          users {
            items {
              id
              userID
              user {
                id
                username
                firstname
                lastname
                companyID
                company {
                  name
                }
              }
              groupID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;