import React  from 'react';

import { Card, CardHeader, CardContent, CardActions, Grid, IconButton, Typography } from '@mui/material';

import { Reply } from '@mui/icons-material';
import UserAvatar from '../TeamMembers/UserAvatar';
import { format, parse } from 'date-fns';


const default_commentdetails = {
  id: '',
  username: '',
  creationDate: '',
  comment: ''
}

const Comment = (props) => {
  const { commentdetails=default_commentdetails, replyHandle, lastComment=true, dense } = props
 
  return (
    <Grid item key={commentdetails.id} xs={12 } direction="row" alignItems="flex-start">
      <Card elevation={1} style={{ width: '100%'}}>
        {dense ? null :
          <CardHeader
            avatar={
              <UserAvatar email={commentdetails.creator.username} size={dense ? "small" : "mediium"} />
            }
            title={commentdetails.creator.firstname + " " + commentdetails.creator.lastname}
            subheader={format(parse(commentdetails.createdAt, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()), 'Pp')}
          />}
        <CardContent style={{padding: '0.75em'}}>
          <Typography>{commentdetails.comment}</Typography>
          {dense ?
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography style={{ fontSize: '0.75em' }}>
                  {commentdetails.creator.firstname + " " + commentdetails.creator.lastname}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} style={{textAlign: 'right'}}>
                <Typography style={{ fontSize: '0.75em'}}>
                  {format(parse(commentdetails.createdAt, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()), 'Pp')}
                </Typography>
              </Grid>

            </Grid> : null}
        </CardContent>
          {lastComment && replyHandle ?
        <CardActions>
            <IconButton aria-label="reply to comment" onClick={replyHandle} size="large">
              <Reply />
            </IconButton> 
        </CardActions>:
            null}
      </Card>
    </Grid>
  );
}

export default Comment;