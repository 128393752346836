import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BallotIcon from '@mui/icons-material/Ballot';
import HistoryIcon from '@mui/icons-material/History';
import { NavContext } from '../../contexts/NavContext';
import Feature from '../Utilities/Feature';

const drawerWidth = 100
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.darkbackground.main,
    color: theme.palette.darkbackground.contrastText,
    
    overflowX: 'hidden',
    width: theme.spacing(7),
    
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const ProjectDrawerRight = () => {
  const classes = useStyles();
  const theme = useTheme();
  
  const [nav, setNav] = useContext(NavContext)

  return (
    <Drawer
      variant="permanent"
      anchor="right"
        className={classes.drawer}
        classes={{
          paper: classes.drawer,
        }}
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem button onClick={() => setNav({...nav, project_right_object:'actionItems'})}>
            <ListItemIcon 
              style={{color: theme.palette.darkbackground.contrastText}}
              >
                <AssignmentTurnedInIcon />
              </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => setNav({...nav, project_right_object:'commentList'})}>
            <ListItemIcon 
              style={{color: theme.palette.darkbackground.contrastText}}
              >
                <QuestionAnswerIcon />
              </ListItemIcon>
          </ListItem>
          <ListItem button onClick={() => setNav({...nav, project_right_object:'modalActions'})}>
            <ListItemIcon 
              style={{color: theme.palette.darkbackground.contrastText}}
              >
                <BallotIcon />
              </ListItemIcon>
          </ListItem>
          <Feature name={'feature_displayhistory'}>
            <ListItem button onClick={() => setNav({...nav, project_right_object:'history'})}>
              <ListItemIcon 
                style={{color: theme.palette.darkbackground.contrastText}}
                >
                  <HistoryIcon />
                </ListItemIcon>
            </ListItem>
          </Feature>
        </List>
      </Drawer>
  )
}

ProjectDrawerRight.propTypes = {
}

ProjectDrawerRight.defaultProps = {
}

export default ProjectDrawerRight;