import React, { useContext, useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router";
import ProjectsContext from "../../contexts/ProjectsContext";
import UserContext from "../../contexts/UserContext";
import { listProjects } from "../../graphql/listProjects";
import { format, parseJSON } from "date-fns";
import { API } from "aws-amplify";
import ProjectTable from "../Base/ProjectTable";
import { Steps } from "../../constants/steps";
import { ReactComponent as NewFaiIcon } from "../Utilities/Icons/new_fai_icon.svg";

import { ReactComponent as FaiIcon } from "../Utilities/Icons/fai_icon.svg";

import { formatProject } from "../Utilities/FormatProject";
import IconActionButton from "../Utilities/IconActionButton";
import IconRouter from "../Utilities/IconRouter";
import Feature from "../Utilities/Feature";
import graphQuery from "../../services/AWSGraphWrapper";

const openColumns = [
  { field: "customID", headerName: "FAI Number", width: 180 },
  { field: "objectNumber", headerName: "Part Number", width: 180 },
  { field: "description", headerName: "Part Description", width: 180 },
  { field: "supplierName", headerName: "Supplier", width: 140 },
  { field: "poNumber", headerName: "PO", width: 100 },
  { field: "quantity", headerName: "Quantity", width: 140 },
  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "activeStatus", headerName: "Current Status", width: 200 },
  {
    field: "stepOwner",
    headerName: "Current Step Owner",
    width: 230,
    valueFormatter: (params) =>
      params.value[0].toUpperCase() + params.value.substring(1),
  },
  { field: "nOpenActionItems", headerName: "# Open Actions", width: 140 },
  {
    field: "nOpenCActionItems",
    headerName: "# Open Corrective Actions",
    width: 140,
  },
  { field: "nClosedActionItems", headerName: "# Closed Actions", width: 140 },
  {
    field: "nClosedCActionItems",
    headerName: "# Closed Corrective Actions",
    width: 140,
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    width: 150,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
];

var closedColumns = [
  { field: "customID", headerName: "FAI Number", width: 180 },
  { field: "objectNumber", headerName: "Part Number", width: 180 },
  { field: "description", headerName: "Part Description", width: 180 },
  { field: "supplierName", headerName: "Supplier", width: 140 },
  { field: "poNumber", headerName: "PO", width: 100 },
  { field: "quantity", headerName: "Quantity", width: 140 },
  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  {
    field: "completionDate",
    headerName: "Completed Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "nonconformances", headerName: "# of Nonconformances", width: 150 },
  { field: "nOpenActionItems", headerName: "# Open Actions", width: 140 },
  {
    field: "nOpenCActionItems",
    headerName: "# Open Corrective Actions",
    width: 140,
  },
  { field: "nClosedActionItems", headerName: "# Closed Actions", width: 140 },
  {
    field: "nClosedCActionItems",
    headerName: "# Closed Corrective Actions",
    width: 140,
  },
  { field: "nOpenActionItems", headerName: "Open Action Items", width: 140 },
  {
    field: "additionalSamplesRequired",
    headerName: "Additional Samples Required",
    width: 200,
  },
];

const allColumns = [
  { field: "customID", headerName: "FAI Number", width: 180 },
  { field: "objectNumber", headerName: "Part Number", width: 180 },
  { field: "description", headerName: "Part Description", width: 180 },
  { field: "supplierName", headerName: "Supplier", width: 140 },
  { field: "poNumber", headerName: "PO", width: 100 },
  { field: "quantity", headerName: "Quantity", width: 140 },
  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "activeStatus", headerName: "Current Status", width: 200 },
  {
    field: "stepOwner",
    headerName: "Current Step Owner",
    width: 230,
    valueFormatter: (params) =>
      params.value[0].toUpperCase() + params.value.substring(1),
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    width: 150,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },

  {
    field: "createdAt",
    headerName: "Created Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  {
    field: "completionDate",
    headerName: "Last Action Date",
    width: 200,
    valueFormatter: (params) =>
      format(parseJSON(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  { field: "nonconformances", headerName: "# of Nonconformances", width: 150 },
  { field: "nOpenActionItems", headerName: "# Open Actions", width: 140 },
  {
    field: "nOpenCActionItems",
    headerName: "# Open Corrective Actions",
    width: 140,
  },
  { field: "nClosedActionItems", headerName: "# Closed Actions", width: 140 },
  {
    field: "nClosedCActionItems",
    headerName: "# Closed Corrective Actions",
    width: 140,
  },
  {
    field: "additionalSamplesRequired",
    headerName: "Additional Samples Required",
    width: 200,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const FaiView = () => {
  const classes = useStyles();
  const [isClosedFilter, setIsClosedFilter] = useState(false);
  const [stateFilter, setStateFilter] = useState();
  const { user } = useContext(UserContext);
  const { projectlist, setProjectlist } = useContext(ProjectsContext);
  const [columns, setColumns] = useState(openColumns);
  const [rows, setRows] = useState([]);
  const [tableName, setTableName] = useState("Open FAIs");
  let history = useHistory();

  let newByDate = 604800000;

  // Retrieve the project list
  useEffect(() => {
    var filter = {
      projectType: { eq: "fai" },
    };
    if (stateFilter === "all") {
      filter = filter;
    } else {
      filter = {
        ...filter,
        and: { finalStatus: { attributeExists: isClosedFilter } },
      };
    }

    graphQuery({
      query: listProjects,
      variables: {
        filter: filter,
      },
    })
      .then((response) => {
        var tmpProjects = response;
        if (stateFilter) {
          if (stateFilter === "new") {
            tmpProjects = tmpProjects.filter(
              (proj) => Date.now() - Date.parse(proj.createdAt) < newByDate
            );
          }
        }
        tmpProjects = tmpProjects.map((project) =>
          formatProject(project, Steps, false)
        );
        tmpProjects = handleSort(tmpProjects);
        setRows(tmpProjects);
        if (isClosedFilter) {
          handleShowUserHiddenColumn(tmpProjects);
        }
      })
      .catch((err) => console.log(err));
  }, [user, isClosedFilter, stateFilter]);

  const getCurrentStepOwner = (step) => {
    var steps = { ...Steps };
    var stepKeys = Object.keys(steps);
    for (var i = 0; i < stepKeys.length; i++) {
      if (stepKeys[i] == step) {
        return steps[stepKeys[i]].approvalRoles[1];
      }
    }
  };

  const handleOpenFais = () => {
    setStateFilter("open");
    setColumns(openColumns);
    setTableName("Open FAIs");
    setIsClosedFilter(false);
  };

  const handleClosedFais = () => {
    setStateFilter("closed");
    setIsClosedFilter(true);
    setTableName("Closed FAIs");
    setColumns(closedColumns);
  };

  const handleAllFais = () => {
    setStateFilter("all");
    setTableName("All FAIs");
    setColumns(allColumns);
  };

  //defaults to creation date descending
  const handleSort = (rows, sortType = "default") => {
    switch (sortType) {
      case "":
        break;
      case "":
        break;
      default:
        rows.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
        break;
    }
    return rows;
  };

  const viewAllowed = () => {
    var allowedRoles = [
      "admin",
      "engineering",
      "production",
      "quality",
      "purchasing",
      "allcompany",
    ];
    var allowedRolesInt = allowedRoles.map((role) => (user[role] ? 1 : 0));
    return Math.max(...allowedRolesInt) === 1;
  };

  const handleShowUserHiddenColumn = (projects) => {
    var userIsHiddenRole = !viewAllowed();
    console.log(userIsHiddenRole);
    if (!userIsHiddenRole) {
      closedColumns.push({
        field: "sampleLotDisposition",
        headerName: "Disposition",
        width: 150,
      });
    } else {
      closedColumns = closedColumns.filter(
        (column) => column.field !== "sampleLotDisposition"
      );
      console.log(closedColumns);
    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={1}>
          <IconActionButton
            onClick={handleOpenFais}
            icon={FaiIcon}
            text="Open FAI"
            iconX={3}
          />
          {viewAllowed() ? (
            <IconActionButton
              onClick={handleClosedFais}
              icon={FaiIcon}
              text="Closed FAI"
              iconX={3}
            />
          ) : null}
          {viewAllowed() ? (
            <IconActionButton
              onClick={handleAllFais}
              icon={FaiIcon}
              text="All FAI"
              iconX={3}
            />
          ) : null}
          <Feature name="faiCreateEnabled">
            <IconRouter
              link={`/fai/details/create`}
              icon={NewFaiIcon}
              text="Create New FAI"
              iconX={3}
            />
          </Feature>
        </Grid>
      </Container>

      <ProjectTable
        columns={columns}
        rows={rows}
        projectlist={projectlist}
        setProjectlist={setProjectlist}
        projectType={"fai"}
        tableName={tableName}
      />
    </div>
  );
};

export default FaiView;
