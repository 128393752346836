import { API } from "aws-amplify"
import * as mutations from '../graphql/mutations';

const DeleteCompany = async(companyID, version) => {
  const company = {
    id: companyID,
    _version: version
  }

  return await API.graphql({ query: mutations.deleteCompany, variables: { input: company } })
    .then(response => response.data.deleteCompany.id)
}

export default DeleteCompany;