export const Steps = {
  req_define: {
    label: 'Define Requirements',
    phase: 'requirements',
    complete: false,
    nextStep: 'req_p2s',
    lastStep: 'assign_team',
    approvalRoles: [
      'admin',
      'engineering'
    ],
    notifyRoles: [],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: false,
    workflow: {
      title: 'Send to Purchasing',
      body: 'Does Engineering sign-off on the above requirements and want to send to purchasing and then supplier?',
      actions: {
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: null,
          modals: [
            {
              key: 'req_define_specialInstructions',
              title: 'Special Instructions',
              description: 'Enter any special instructions in the field below and then press Submit',
              notifyRoles: ['purchasing'],
              responseType: 'textField',
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        }
      }
    }
  },
  req_p2s: {
    label: 'Send Requirements',
    phase: 'requirements',
    complete: false,
    nextStep: 'req_acknowledge',
    lastStep: 'req_define',
    approvalRoles: [
      'admin',
      'purchasing'
    ],
    addRoles: {
      adminUser: [],
      editUser: ['purchasing'],
      viewUser: ['quality']
    },
    notifyRoles: ['quality'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: false,
    workflow: {
      title: 'Send to Supplier',
      body: 'Does Purchasing want to send to Supplier for acknowledgement?',
      actions: {
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: null,
          modals: [
            {
              key: 'req_p2s_specialInstructions',
              title: 'Special Instructions',
              description: 'Enter any special instructions in the field below and then press Submit',
              notifyRoles: ['supplier'],
              responseType: 'textField',
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        }
      }
    }
  },
  req_acknowledge: {
    label: 'Acknowledge Requirements',
    phase: 'requirements',
    complete: false,
    nextStep: {
      approve: 'sample_ship',
      reject: 'req_define'
    },
    lastStep: 'req_p2s',
    approvalRoles: [
      'admin',
      'supplier'
    ],
    addRoles: {
      adminUser: [],
      editUser: [],
      viewUser: ['supplier','production']
    },
    notifyRoles: ['purchasing'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: false,
    workflow: {
      title: 'Supplier Acknowledgement',
      body: 'Does supplier acknowledge and agree to the above requirements?',
      actions: {
        reject: {
          label: 'No',
          icon: 'ThumbDown',
          color: 'secondary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: 'reject',
          modals: [
            {
              key: 'req_acknowledge_rejectReasons',
              title: 'Reject Reasons',
              description: 'Please enter your reasons for rejecting the requirements,',
              notifyRoles: ['engineering','purchasing'],
              responseType: 'textField',
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        },
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: 'approve',
          modals: [
            {
              key: 'req_acknowledge_specialInstructions',
              title: 'Special Instructions',
              description: 'Enter any special instructions in the field below and then press Submit',
              notifyRoles: ['supplier'],
              responseType: 'textField',
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        }
      }
    }
  },
  sample_upload: {
    label: 'Upload Sample Data',
    phase: 'samples',
    complete: false,
    nextStep: 'sample_dataaccept',
    lastStep: 'req_acknowledge',
    approvalRoles: [
      'admin',
      'supplier'
    ],
    notifyRoles: ['purchasing'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: true,
    workflow: {
      title: 'Submit Documents',
      body: 'Does Supplier want to submit documents for quality review?',
      actions: {
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: null
        }
      }
    }
  },
  sample_dataaccept: {
    label: 'Accept Sample Data',
    phase: 'samples',
    complete: false,
    nextStep: {
      approve: 'sample_ship',
      reject: 'sample_upload'
    },
    lastStep: 'sample_upload',
    approvalRoles: [
      'admin',
      'engineering',
      'quality'
    ],
    notifyRoles: ['purchasing'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: true,
    workflow: {
      title: 'Submit Documents',
      body: 'Does Quality or Engineering approve of submitted documents?',
      actions: {
        reject: {
          label: 'No',
          icon: 'ThumbDown',
          color: 'secondary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: 'reject',
          modals: [
            {
              key: 'sample_dataaccept_rejectReasons',
              title: 'Reject Reasons',
              description: 'Please enter your reasons for rejecting the sample data.',
              notifyRoles: ['supplier','purchasing'],
              responseType: 'textField',
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        },
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: 'approve',
          modals: [
            {
              key: 'sample_dataaccept_specialInstructions',
              title: 'Special Instructions',
              description: 'Enter any special instructions in the field below and then press Submit',
              notifyRoles: ['production','quality','purchasing'],
              responseType: 'textField',
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        }
      }
    }
  },
  sample_ship: {
    label: 'Ship Samples',
    phase: 'samples',
    complete: false,
    nextStep: 'sample_receive',
    lastStep: 'sample_dataaccept',
    approvalRoles: [
      'admin',
      'supplier'
    ],
    notifyRoles: ['purchasing'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: false,
    workflow: {
      title: 'Submit Shipping Information',
      body: 'Does Supplier want to submit shipping information?',
      actions: {
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: null,
        }
      }
    }
  },
  sample_receive: {
    label: 'Receive Samples',
    phase: 'samples',
    complete: false,
    nextStep: 'fai_perform',
    lastStep: 'sample_ship',
    approvalRoles: [
      'admin',
      'production',
      'quality'
    ],
    notifyRoles: ['production','quality'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: false,
    workflow: {
      title: 'Receive Samples',
      body: 'Does Production or Quality want to receive shipped samples?',
      actions: {
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: null
        }
      }
    }
  },
  fai_perform: {
    label: 'Perform FAI',
    phase: 'inspection',
    complete: false,
    nextStep: 'fai_review',
    lastStep: 'sample_receive',
    approvalRoles: [
      'admin',
      'quality'
    ],
    notifyRoles: ['quality'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: false,
    workflow: {
      title: 'Submit FAI',
      body: 'Does Quality want to submit FAI results to Engineering for review?',
      actions: {
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: null,
          modals: [
            {
              key: 'fai_perform_specialInstructions',
              title: 'Special Instructions',
              description: 'Enter any special instructions in the field below and then press Submit',
              notifyRoles: ['engineering'],
              responseType: 'textField',
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        }
      }
    }
  },
  fai_review: {
    label: 'Review FAI',
    phase: 'inspection',
    complete: false,
    nextStep: {
      approve: 'approved',
      reject: 'rejected'
    },
    lastStep: 'fai_perform',
    approvalRoles: [
      'admin',
      'engineering'
    ],
    notifyRoles: ['purchasing'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: false,
    workflow: {
      title: 'Review FAI',
      body: 'Does Engineering approve of FAI?',
      actions: {
        reject: {
          label: 'No',
          icon: 'ThumbDown',
          color: 'secondary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: 'reject',
          modals: [
            {
              key: 'additionalSamplesRequired',
              title: 'Additional Samples Required?',
              description: 'When the supplier responds to non-conformances, are additional samples required?',
              notifyRoles: ['purchasing', 'supplier', 'quality'],
              responseType: 'radioButtons',
              radioOptions: [
                'Yes',
                'No'
              ],
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            },
            {
              key: 'sampleLotDisposition',
              title: 'Sample Lot Disposition',
              description: 'What should be done with the samples?',
              notifyRoles: ['purchasing', 'quality', 'production'],
              responseType: 'radioButtons',
              hiddenRoles: ['supplier'],
              radioOptions: [
                'Sample Order: Save for future reference',
                'Production Order: Use-as-is',
                'Production Order: Rework Required',
                'Production Order: Reject'
              ],
              continueLabel: 'Submit',
              continueIcon: 'Forward',
              continueColor: 'primary',
              cancelLabel: 'Cancel',
              cancelIcon: 'Cancel',
              cancelColor: 'secondary'
            }
          ]
        },
        approve: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
          nextStepOption: 'approve'
        }
      }
    }
  },
  rejected: {
    label: 'Open CAR',
    phase: 'inspection',
    complete: false,
    nextStep: 'rejected_closed',
    lastStep: 'fai_review',
    approvalRoles: [
      'admin',
      'engineering'
    ],
    notifyRoles: ['purchasing','quality','supplier'],
    finalStep: false,
    defaultFinalStep: false,
    optionalStep: true,
    workflow: {
      title: 'Close FAI',
      body: 'Does Engineering wish to close FAI?',
      actions: {
        close: {
          label: 'Yes',
          icon: 'ThumbUp',
          color: 'primary',
          stepDirection: 'forward',
          nSteps: 1,
        }
      }
    }
  },
  approved: {
    label: 'Closed',
    phase: 'inspection',
    complete: false,
    nextStep: null,
    lastStep: 'fai_review',
    approvalRoles: [
      'admin',
      'engineering'
    ],
    notifyRoles: ['purchasing','quality','supplier'],
    finalStep: true,
    defaultFinalStep: true,
    optionalStep: true,
  },
  rejected_closed: {
    label: 'Closed (CAR)',
    phase: 'inspection',
    complete: false,
    nextStep: null,
    lastStep: 'rejected',
    approvalRoles: [
      'admin',
      'engineering'
    ],
    notifyRoles: ['purchasing','quality','supplier'],
    finalStep: true,
    defaultFinalStep: false,
    optionalStep: true,
  }
}

export default Steps