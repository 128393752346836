export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelprojectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
            items {
                id
                key
                value
                creatorID
                lastModifierID
                userID
                companyID
                partnumberID
                projectID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
            }
          nextToken
          startedAt
        }
        supplier {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        partnumber {
          id
          objectnumber
          numberType
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          items {
              id
              actionitem
              projectID
              actionitemType
              statusID
              status {
                id
                status
                statusState
                dueDate
                notes
              }
            }
          nextToken
          startedAt
        }
        history {
          nextToken
          startedAt
        }
        owner
        usergroups {
          items {
            id
            projectID
            name
            description
            creatorID
            lastModifierID
            adminUser
            editUser
            viewUser
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            users {
              items {
                id
                useraccessgroupID
                userID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
        requirements {
          nextToken
          startedAt
        }
        deliverable {
          nextToken
          startedAt
        }
        inspection {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;