import React, { useState, useEffect } from 'react';
import { Card, Tab, Tabs } from "@mui/material";
import OwnProjects from '../Modules/OwnProjects';
import { format, parseJSON } from 'date-fns';

const definedColumns = {
  'fai': [
    { field: 'customID', headerName: 'FAI Number', width: 180 },
    { field: 'objectNumber', headerName: 'Part Number', width: 180 },
    { field: 'description', headerName: 'Part Description', width: 180 },
    { field: 'supplierName', headerName: 'Supplier', width: 140 },
    { field: 'poNumber', headerName: 'PO', width: 100 },
    { field: 'quantity', headerName: 'Quantity', width: 140 },
    {
      field: 'createdAt', headerName: 'Created Date', width: 200,
      valueFormatter: (params) => format(parseJSON(params.value), 'yyyy-MM-dd HH:mm:ss')
    },
    { field: 'activeStatus', headerName: 'Current Status', width: 200 },
    { field: 'stepOwner', headerName: 'Current Step Owner', width: 230 },
    { field: 'nOpenActionItems', headerName: '# Open Actions', width: 140 },
    { field: 'nOpenCActionItems', headerName: '# Open Corrective Actions', width: 140 },
    { field: 'nClosedActionItems', headerName: '# Closed Actions', width: 140 },
    { field: 'nClosedCActionItems', headerName: '# Closed Corrective Actions', width: 140 },
    { 
      field: 'dueDate', headerName: 'Due Date', width: 150, 
      valueFormatter: (params) => format(parseJSON(params.value), 'yyyy-MM-dd HH:mm:ss')
    },
  ],
  'mtr': [
    { field: 'customID', headerName: 'MTR Number', width: 180 },
    { field: 'objectNumber', headerName: 'Part Number', width: 180 },
    { field: 'description', headerName: 'Part Description', width: 180 },
    { field: 'supplierName', headerName: 'Supplier', width: 140 },
    { field: 'poNumber', headerName: 'PO', width: 100 },
    { field: 'quantity', headerName: 'Quantity', width: 140 },
    {
      field: 'createdAt', headerName: 'Created Date', width: 200,
      valueFormatter: (params) => format(parseJSON(params.value), 'yyyy-MM-dd HH:mm:ss')
    },
    { field: 'activeStatus', headerName: 'Current Status', width: 200 },
    { field: 'stepOwner', headerName: 'Current Step Owner', width: 230 },
    { field: 'nOpenActionItems', headerName: '# Open Actions', width: 140 },
    { field: 'nOpenCActionItems', headerName: '# Open Corrective Actions', width: 140 },
    { field: 'nClosedActionItems', headerName: '# Closed Actions', width: 140 },
    { field: 'nClosedCActionItems', headerName: '# Closed Corrective Actions', width: 140 },
    { 
      field: 'dueDate', headerName: 'Due Date', width: 150, 
      valueFormatter: (params) => format(parseJSON(params.value), 'yyyy-MM-dd HH:mm:ss')
    },
  ]
}

const TabularProjectTable = (props) => {
  const { filterSelf = false, ...other } = props
  const [ projectType, setProjectType ] = useState("fai");
  const [tabValue, setTabValue] = useState(0);
  const [columns, setColumns] = useState(definedColumns['fai'])
  const [tabs, setTabs] = useState([])
  
  useEffect(() => { 
    const features = JSON.parse(localStorage.getItem('flags'));
    var feature = features.find(feature => feature.name === 'module_fai_enable')
    var tmpTabs = []
    if (feature && feature.active) {
      tmpTabs = [...tmpTabs, { name: 'FAI Projects', projectType: 'fai' }]
    }
    
    feature = features.find(feature => feature.name === 'module_mtr_enable')
    if (feature && feature.active) {
      tmpTabs = [...tmpTabs, { name: 'MTR Projects', projectType: 'mtr' }]
    }
    
    feature = features.find(feature => feature.name === 'module_rfq_enable')
    if (feature && feature.active) {
      tmpTabs = [...tmpTabs, { name: 'RFQ Projects', projectType: 'rfq' }]
    }

    setTabs(tmpTabs)
    setTabValue(0)
    setProjectType(tmpTabs[0]['projectType'])

  },[])

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    setProjectType(tabs[newValue]['projectType'])
    setColumns(definedColumns[tabs[newValue]['projectType']])
  }

  return (
    <Card {...other}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.name} />
        ))}
      </Tabs>
      <OwnProjects
        projectType={projectType}
        columns={columns}
        stateFilter={'open'}
        isClosedFilter={false}
        tableName={null}
      />
    </Card>
  )
}

export default TabularProjectTable;