import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { Tab, Tabs } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import AppBar from "@mui/material/AppBar";
import MTR from "./MTR";

import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  matchPath,
} from "react-router-dom";

import ProjectsContext from "../../contexts/ProjectsContext";
import CurrentProjectContext from "../../contexts/CurrentProjectContext";
import FaiTabContext from "../../contexts/FaiTabContext";

import * as getProjectquery from "../../graphql/getProject";
import { API } from "aws-amplify";

import { useLocation } from "react-router-dom";
import { formatProject } from "../Utilities/FormatProject";
import CreateProject from "../Base/CreateProject";
import { NavContextProvider } from "../../contexts/NavContext";
import Feature from "../Utilities/Feature";
import ProjectDrawerRight from "../Base/ProjectDrawerRight";
import ProjectDrawerLeft from "../Base/ProjectDrawerLeft";
import projectConfigs from "../../constants/projectConfigs";
import { useTheme } from "@emotion/react";

const drawerWidth = 380;

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.darkbackground.secondary,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  contentDrawerLeft: {
    paddingLeft: theme.spacing(8),
  },
  contentDrawerRight: {
    paddingRight: theme.spacing(8),
  },

  contentOpen: {
    flexGrow: 1,
    width: "calc(100% - " + drawerWidth + "px)",
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentClose: {
    flexGrow: 1,
    width: "calc(100% - " + theme.spacing(9) + "px)",
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

var Steps = { ...projectConfigs.find((item) => item.name === "mtr").steps };
const MtrMain = () => {
  let match = useRouteMatch();
  let history = useHistory();
  const theme = useTheme();

  const location = useLocation();

  const { projectlist, setProjectlist } = useContext(ProjectsContext);

  const [tabValue, setTabValue] = useState(0);
  const [project, setProject] = useState({
    partnumber: { objectnumber: "", numberType: "" },
    supplier: { id: "", name: "" },
    usergroups: {
      items: [],
    },
    requirements: {
      items: [],
    },
    status: {},
    actionitems: {
      items: [],
    },
    permissions: {
      edit: [],
      read: [],
    },
  });
  const [open] = useState(false);
  const classes = useStyles();

  const getProjectID = () => {
    const matchPathReturn = matchPath(location.pathname, {
      path: "/mtr/details/:projectid",
      exact: true,
      strict: true,
    });
    return matchPathReturn.params.projectid;
  };

  const getSetProject = async (projectid, setTab = false) => {
    var tmpProject = await API.graphql({
      query: getProjectquery.getProject,
      variables: { id: projectid },
    });
    if (projectid === "create") {
      tmpProject = {
        data: {
          getProject: {
            id: "create",
            customID: "New Project",
            keyvalues: {
              items: [],
            },
            partnumber: { objectnumber: "", numberType: "" },
            supplier: { id: "", name: "" },
            usergroups: {
              items: [],
            },
            requirements: {
              items: [],
            },
            status: {
              statusState: "create",
            },
          },
        },
      };
    }

    var tmpmtrProjects = projectlist.mtr;
    if (tmpmtrProjects.find((item) => item.id === projectid)) {
      tmpmtrProjects[
        tmpmtrProjects.findIndex((item) => item.id === projectid)
      ] = tmpProject.data.getProject;
    } else {
      tmpmtrProjects.push(tmpProject.data.getProject);
    }
    if (setTab) {
      setTabValue(tmpmtrProjects.findIndex((item) => item.id === projectid));
    }
    setProjectlist({ ...projectlist, mtr: tmpmtrProjects });
    var tmpproject = formatProject(tmpProject.data.getProject, Steps);
    setProject(tmpproject);
  };

  useEffect(() => {
    const projectid = getProjectID();
    getSetProject(projectid, true);
  }, []);

  const handleChange = (event, newValue) => {
    getSetProject(projectlist.mtr[newValue].id, true);
    history.push(`/mtr/details/${projectlist.mtr[newValue].id}`);
  };

  const features = JSON.parse(localStorage.getItem("flags"));
  const feature_leftProjectDrawer_enable = features.find(
    (feature) => feature.name === "feature_leftProjectDrawer_enable"
  );
  const feature_rightProjectDrawer_enable = features.find(
    (feature) => feature.name === "feature_rightProjectDrawer_enable"
  );

  return (
    <CurrentProjectContext.Provider value={{ project, setProject }}>
      <FaiTabContext.Provider value={{ tabValue, setTabValue }}>
        <NavContextProvider>
          <div>
            <Feature name="feature_leftProjectDrawer_enable">
              <ProjectDrawerLeft />
            </Feature>
            <div
              className={clsx(classes.content, {
                [classes.contentDrawerLeft]:
                  feature_leftProjectDrawer_enable.active,
                [classes.contentDrawerRight]:
                  feature_rightProjectDrawer_enable.active,
              })}
            >
              <AppBar position="static" className={classes.appbar}>
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  {projectlist.mtr.map((item, index) => (
                    <Tab
                      key={item.id}
                      label={item.customID}
                      {...a11yProps(index)}
                      style={{
                        color: theme.palette.darkbackground.contrastText,
                      }}
                    />
                  ))}
                </Tabs>
              </AppBar>
              <Switch>
                <Route
                  path={`${match.path}/create`}
                  component={CreateProject}
                />
                <Route
                  path={`${match.path}/:projectid`}
                  component={() => <MTR setTabValue={setTabValue} />}
                />
              </Switch>
            </div>
            <Feature name="feature_rightProjectDrawer_enable">
              <ProjectDrawerRight />
            </Feature>
          </div>
        </NavContextProvider>
      </FaiTabContext.Provider>
    </CurrentProjectContext.Provider>
  );
};

export default MtrMain;
