import React from 'react';
import gravatar from 'gravatar';
import { Avatar } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  xsmall: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const UserAvatar = (props) => {
  const { email, className, size='medium' } = props;
  const classes = useStyles();
  return (
    <Avatar
      src={
        gravatar.url(
          email ? email : '',
          { s: '200', r: 'pg', d: 'retro' }
        )
      }
      className={className ? className : classes[size]}
    />
  )
}

export default UserAvatar;