import { Container, Grid } from '@mui/material';
import React, { useContext } from 'react';
import UserContext from '../../contexts/UserContext';
import IconRouter from '../Utilities/IconRouter';
import { ReactComponent as UserSettingsIcon } from '../Utilities/Icons/user_settings.svg'
import { ReactComponent as CompanySettingsIcon } from '../Utilities/Icons/company_settings.svg'
import { ReactComponent as CompanyNewIcon } from '../Utilities/Icons/company_new.svg'
import { ReactComponent as FaiTemplateIcon } from '../Utilities/Icons/new_icon.svg'

const Settings = () => {
  const {user } = useContext(UserContext);
  return (
    <Container>
      <Grid container spacing={1}>
        <IconRouter link={`/settings/userprofile`} icon={UserSettingsIcon} text='User Settings' />
        {(user.companyAdmin || user.superUser) && user.companyID ?
          <IconRouter link={`/settings/companyprofile/${user.companyID}`} icon={CompanySettingsIcon} text='Company Settings' />
          : null
        }
        
        {(user.companyAdmin || user.superUser) && !user.company ?
          <IconRouter link={`/settings/companyprofile/create`} icon={CompanyNewIcon} text='Company Settings' />
          : null
        }
        
        {user.companyAdmin || user.superUser ?
          <>
            <IconRouter link={`/settings/suppliers`} icon={CompanySettingsIcon} text='Manage Suppliers' />
            <IconRouter link={`/settings/faitemplates`} icon={FaiTemplateIcon} disabled={true} text='Manage FAI Templates' />
          </>
          : null
        }
      </Grid>
    </Container>
  )
}

export default Settings;