
import React from 'react';
import Attachment from './Attachment';

const AttachmentList = (props) => {
  const { attachments=[], getSetProject, deleteAllowed = false} = props
  return (
    <>
      {attachments.map(file => (
        <Attachment
          key={file.attachment.id}
          file={file.attachment}
          getSetProject={() => getSetProject()}
          deleteAllowed={deleteAllowed}
        />
      ))}
    </>
  )
}

export default AttachmentList;