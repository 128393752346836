import { Card, CardContent, CardHeader, IconButton, List, Tooltip } from '@mui/material';
import { Add, Sort } from '@mui/icons-material';
import React, { useState } from 'react';
import ActionItem from './ActionItem';
import CreateActionItem from './CreateActionItem';

const ActionItems = (props) => {
  const { actionItems = [], style, ...other } = props
  const [createAIOpen, setCreateAIOpen] = useState(false)
  const [sortAscending, setSortAscending] = useState(false)

  return (
    <Card style={style}>
      <CardHeader
        action={
          <>
            <Tooltip title="Change Sort Order">
              <IconButton
                aria-label="sort action items"
                onClick={() => setSortAscending(!sortAscending)}
                size="large">
                <Sort />
              </IconButton>
            </Tooltip>
            <Tooltip title="Create New Action Item">
              <IconButton
                aria-label="create new action item"
                onClick={() => setCreateAIOpen(true)}
                size="large">
                <Add />
              </IconButton>
            </Tooltip>
          </>
        }
        title="Action Items"
      />
      <CardContent>
        <List >
          {actionItems[0] ? (actionItems[0]._lastChangedAt ?
            (sortAscending ? actionItems.sort((a, b) => b._lastChangedAt - a._lastChangedAt).map(ai => (<ActionItem actionItemID={ai.id} {...other}/>))
            : actionItems.sort((a, b) => a._lastChangedAt - b._lastChangedAt).map(ai => (<ActionItem actionItemID={ai.id} {...other}/>))) 
          :
          actionItems.map(ai => (<ActionItem actionItemID={ai.id} {...other}/>))) :
          null }
        </List>
      </CardContent>
      <CreateActionItem visible={createAIOpen} setVisible={setCreateAIOpen} corrective={false} {...props}/>
    </Card>
  );
}

export default ActionItems;