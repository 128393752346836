export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      customID
      name
      projectType
      partnumberID
      mpartnumber
      description
      dueDate
      supplierID
      statusID
      creatorID
      lastModifierID
      parentID
      childID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      supplier {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      partnumber {
        id
        objectnumber
        numberType
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        projects {
          nextToken
          startedAt
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          creator {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          lastModifier {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          projectID
          duedate
          assigneeID
          assignee {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
          }
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          status {
            id
            status
            statusState
            dueDate
            notes
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
          }
          comments {
            items {
              id
              projectID
              comment
              creatorID
              lastModifierID
              creator {
                id
                username
                email
                firstname
                lastname
                superUser
                companyAdmin
                companyID
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
                keyvalues {
                  nextToken
                  startedAt
                }
                company {
                  id
                  name
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                }
              }
              lastModifier {
                id
                username
                email
                firstname
                lastname
                superUser
                companyAdmin
                companyID
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
                keyvalues {
                  nextToken
                  startedAt
                }
                company {
                  id
                  name
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                }
              }
              parentID
              childID
              userID
              companyID
              partnumberID
              actionitemID
              historyID
              requirementID
              attachmentID
              deliverableID
              inspectionID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
            nextToken
            startedAt
          }
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
          attachment {
            items {
              id
              actionitemID
              attachmentID
              attachment {
                id
                projectID
                name
                description
                datatype
                location
                filename
                statusID
                creatorID
                lastModifierID
                lastversionID
                nextversionID
                adminUser
                editUser
                viewUser
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                creator {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                lastModifier {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                status {
                  id
                  status
                  statusState
                  dueDate
                  notes
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  creator {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  lastModifier {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  owner
                }
                comments {
                  items {
                    id
                    projectID
                    comment
                    creatorID
                    creator {
                      id
                      username
                      email
                      firstname
                      lastname
                      superUser
                      companyAdmin
                      companyID
                      creatorID
                      lastModifierID
                      _version
                      _deleted
                      _lastChangedAt
                      createdAt
                      updatedAt
                      owner
                      keyvalues {
                        nextToken
                        startedAt
                      }
                      company {
                        id
                        name
                        creatorID
                        lastModifierID
                        _version
                        _deleted
                        _lastChangedAt
                        createdAt
                        updatedAt
                        owner
                      }
                    }
                    lastModifierID
                    parentID
                    childID
                    userID
                    companyID
                    partnumberID
                    actionitemID
                    historyID
                    requirementID
                    attachmentID
                    deliverableID
                    inspectionID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                project {
                  items {
                    id
                    customID
                    name
                    partnumberID
                    description
                    dueDate
                    supplierID
                    statusID
                    creatorID
                    lastModifierID
                    parentID
                    childID
                    adminUser
                    editUser
                    viewUser
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                owner
                actionitems {
                  items {
                    id
                    attachmentID
                    actionitemID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      history {
        items {
          id
          projectID
          modifiedID
          modifiedType
          modifiedAction
          modification
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      usergroups {
        items {
          id
          projectID
          name
          description
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
          users {
            items {
              id
              useraccessgroupID
              userID
              user {
                id
                username
                email
                firstname
                lastname
                superUser
                companyAdmin
                companyID
                company {
                  id
                  name
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                }
                creatorID
                lastModifierID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                owner
              }
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      requirements {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
          attachments {
            items {
              id
              requirementID
              attachmentID
              attachment {
                id
                projectID
                name
                description
                datatype
                location
                filename
                statusID
                creatorID
                lastModifierID
                lastversionID
                nextversionID
                adminUser
                editUser
                viewUser
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                creator {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                lastModifier {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                status {
                  id
                  status
                  statusState
                  dueDate
                  notes
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  creator {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  lastModifier {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  owner
                }
                comments {
                  items {
                    id
                    projectID
                    comment
                    creatorID
                    creator {
                      id
                      username
                      email
                      firstname
                      lastname
                      superUser
                      companyAdmin
                      companyID
                      creatorID
                      lastModifierID
                      _version
                      _deleted
                      _lastChangedAt
                      createdAt
                      updatedAt
                      owner
                      keyvalues {
                        nextToken
                        startedAt
                      }
                      company {
                        id
                        name
                        creatorID
                        lastModifierID
                        _version
                        _deleted
                        _lastChangedAt
                        createdAt
                        updatedAt
                        owner
                      }
                    }
                    lastModifierID
                    parentID
                    childID
                    userID
                    companyID
                    partnumberID
                    actionitemID
                    historyID
                    requirementID
                    attachmentID
                    deliverableID
                    inspectionID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                project {
                  items {
                    id
                    customID
                    name
                    partnumberID
                    description
                    dueDate
                    supplierID
                    statusID
                    creatorID
                    lastModifierID
                    parentID
                    childID
                    adminUser
                    editUser
                    viewUser
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                owner
                actionitems {
                  items {
                    id
                    attachmentID
                    actionitemID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      deliverable {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
          attachments {
            items {
              id
              deliverableID
              attachmentID
              attachment {
                id
                projectID
                name
                description
                datatype
                location
                filename
                statusID
                creatorID
                lastModifierID
                lastversionID
                nextversionID
                adminUser
                editUser
                viewUser
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                creator {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                lastModifier {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                status {
                  id
                  status
                  statusState
                  dueDate
                  notes
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  creator {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  lastModifier {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  owner
                }
                comments {
                  items {
                    id
                    projectID
                    comment
                    creatorID
                    creator {
                      id
                      username
                      email
                      firstname
                      lastname
                      superUser
                      companyAdmin
                      companyID
                      creatorID
                      lastModifierID
                      _version
                      _deleted
                      _lastChangedAt
                      createdAt
                      updatedAt
                      owner
                      keyvalues {
                        nextToken
                        startedAt
                      }
                      company {
                        id
                        name
                        creatorID
                        lastModifierID
                        _version
                        _deleted
                        _lastChangedAt
                        createdAt
                        updatedAt
                        owner
                      }
                    }
                    lastModifierID
                    parentID
                    childID
                    userID
                    companyID
                    partnumberID
                    actionitemID
                    historyID
                    requirementID
                    attachmentID
                    deliverableID
                    inspectionID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                project {
                  items {
                    id
                    customID
                    name
                    partnumberID
                    description
                    dueDate
                    supplierID
                    statusID
                    creatorID
                    lastModifierID
                    parentID
                    childID
                    adminUser
                    editUser
                    viewUser
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                owner
                actionitems {
                  items {
                    id
                    attachmentID
                    actionitemID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
        nextToken
        startedAt
      }
      inspection {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          inspectorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          creator {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          inspector {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          lastModifier {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            keyvalues {
              nextToken
              startedAt
            }
            company {
              id
              name
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
          }
          status {
            id
            status
            statusState
            dueDate
            notes
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            creator {
              id
              username
              email
              firstname
              lastname
              superUser
              companyAdmin
              companyID
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
            lastModifier {
              id
              username
              email
              firstname
              lastname
              superUser
              companyAdmin
              companyID
              creatorID
              lastModifierID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
            owner
          }
          comments {
            items {
              id
              projectID
              comment
              creatorID
              lastModifierID
              parentID
              childID
              userID
              companyID
              partnumberID
              actionitemID
              historyID
              requirementID
              attachmentID
              deliverableID
              inspectionID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
            nextToken
            startedAt
          }
          project {
            items {
              id
              customID
              name
              partnumberID
              description
              dueDate
              supplierID
              statusID
              creatorID
              lastModifierID
              parentID
              childID
              adminUser
              editUser
              viewUser
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
              owner
            }
            nextToken
            startedAt
          }
          owner
          actionitems {
            items {
              id
              inspectionID
              actionitemID
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
          attachments {
            items {
              id
              inspectionID
              attachmentID
              attachment {
                id
                projectID
                name
                description
                datatype
                location
                filename
                statusID
                creatorID
                lastModifierID
                lastversionID
                nextversionID
                adminUser
                editUser
                viewUser
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
                creator {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                lastModifier {
                  id
                  username
                  email
                  firstname
                  lastname
                  superUser
                  companyAdmin
                  companyID
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  owner
                  keyvalues {
                    nextToken
                    startedAt
                  }
                  company {
                    id
                    name
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                }
                status {
                  id
                  status
                  statusState
                  dueDate
                  notes
                  creatorID
                  lastModifierID
                  _version
                  _deleted
                  _lastChangedAt
                  createdAt
                  updatedAt
                  creator {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  lastModifier {
                    id
                    username
                    email
                    firstname
                    lastname
                    superUser
                    companyAdmin
                    companyID
                    creatorID
                    lastModifierID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  owner
                }
                comments {
                  items {
                    id
                    projectID
                    comment
                    creatorID
                    creator {
                      id
                      username
                      email
                      firstname
                      lastname
                      superUser
                      companyAdmin
                      companyID
                      creatorID
                      lastModifierID
                      _version
                      _deleted
                      _lastChangedAt
                      createdAt
                      updatedAt
                      owner
                      keyvalues {
                        nextToken
                        startedAt
                      }
                      company {
                        id
                        name
                        creatorID
                        lastModifierID
                        _version
                        _deleted
                        _lastChangedAt
                        createdAt
                        updatedAt
                        owner
                      }
                    }
                    lastModifierID
                    parentID
                    childID
                    userID
                    companyID
                    partnumberID
                    actionitemID
                    historyID
                    requirementID
                    attachmentID
                    deliverableID
                    inspectionID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                project {
                  items {
                    id
                    customID
                    name
                    partnumberID
                    description
                    dueDate
                    supplierID
                    statusID
                    creatorID
                    lastModifierID
                    parentID
                    childID
                    adminUser
                    editUser
                    viewUser
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                    owner
                  }
                  nextToken
                  startedAt
                }
                owner
                actionitems {
                  items {
                    id
                    attachmentID
                    actionitemID
                    _version
                    _deleted
                    _lastChangedAt
                    createdAt
                    updatedAt
                  }
                  nextToken
                  startedAt
                }
              }
              _version
              _deleted
              _lastChangedAt
              createdAt
              updatedAt
            }
            nextToken
            startedAt
          }
        }
      }
    }
  }
`;
