import { Button, TextField } from '@mui/material';
import { API } from 'aws-amplify';
import React, {  useContext, useState } from 'react';
import CurrentProjectContext from '../../../contexts/CurrentProjectContext';
import UserContext from '../../../contexts/UserContext';
import * as mutations from '../../../graphql/mutations';

const NewComment = (props) => {
  const { getSetProject,
    projectID,
    partnumberID,
    actionitemID,
    historyID,
    requirementID,
    attachmentID,
    deliverableID,
    inspectionID  } = props;
  const { user } = useContext(UserContext);
  const { project } = useContext(CurrentProjectContext)
  const [newComment, setNewComment] = useState('')
  const [ createCommentDisabled, setCreateCommentDisabled ] = useState(true)

  const handleUpdate = (event) => {
    setNewComment(event.target.value)
    setCreateCommentDisabled((event.target.value) === '')
  }

  const submitComment = async () => {    
    const newcomment = {
      projectID: project.id ? project.id : projectID,
      userID: user.id,
      comment: newComment,
      creatorID: user.id,
      lastModifierID: user.id,
      partnumberID: partnumberID,
      actionitemID: actionitemID,
      historyID: historyID,
      requirementID: requirementID,
      attachmentID: attachmentID,
      deliverableID: deliverableID,
      inspectionID: inspectionID
    }
    await API.graphql({ query: mutations.createComment, variables: { input: newcomment } })
  }
  
  const handleClick = () => {
    setCreateCommentDisabled(true)
    submitComment()
      .then(getSetProject && getSetProject())
      .then(setNewComment(''))
      .catch(err => console.log(err))
  }

  return <>
    <TextField
      variant="standard"
      value={newComment}
      label="Add a new comment"
      style={{ width: '100%' }}
      onChange={(event) => handleUpdate(event)} />
    <Button
      disabled={createCommentDisabled}
      style={{ float: 'right', marginTop: '0.5em' }}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      Comment
    </Button>
  </>;
}

export default NewComment;