import { matchPath } from "react-router-dom";

const GetLocationParams = (location) => {
  const matchPathReturn = matchPath(
      location.pathname,
      {
        path: "/:main/:secondary*/:param",
        exact: false,
        strict: false
      }
    )
  return matchPathReturn
  
}

export default GetLocationParams;