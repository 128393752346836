import { Container, Divider, Grid, Link, Typography, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../contexts/UserContext';
import UserAvatar from '../Utilities/TeamMembers/UserAvatar';
import * as mutations from '../../graphql/mutations';
import API from '@aws-amplify/api';
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: '100%',
    //backgroundColor: theme.palette.background.paper,
  },
  row: {
    width: '100%',
    paddingTop: '3em'
  },
  profileItem: {
    width: '100%'
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const UserProfile = () => {
  const { user, setUser } = useContext(UserContext);
  const [tmpUser, setTmpUser] = useState({ firstname:'', lastname: '',email:'' })
  const [saveDisabled, setSaveDisabled] = useState(true)
  const classes = useStyles();

  useEffect(() => {
    setTmpUser({ ...user })
  },[user])

  const handleChange = (item) => (event) => {
    setTmpUser({...tmpUser, [item]:event.target.value})
    setSaveDisabled(false)
  }

  const handleSave = () => {
    const updatedUser = {
      id: tmpUser.id,
      firstname: tmpUser.firstname,
      lastname: tmpUser.lastname,
      email: tmpUser.email,
      _version: tmpUser._version,
      lastModifierID: tmpUser.id,
      superUser: user.superUser ? tmpUser.superUser : 'false',
      companyAdmin: user.companyAdmin ? tmpUser.companyAdmin: 'false'
    }
    return API.graphql({ query: mutations.updateUser, variables: { input: updatedUser } })
      .then(userData => {
        const updatedUser = {
          firstname: userData.data.updateUser.firstname,
          lastname: userData.data.updateUser.lastname,
          email: userData.data.updateUser.email,
          _version: userData.data.updateUser._version,
          superUser: userData.data.updateUser.superUser,
          companyAdmin: userData.data.updateUser.companyAdmin
        }
        setUser({...user, ...updatedUser})
      })

  }

  const labels = {
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    superUser: 'Super User',
    companyAdmin: 'Company Admin'
  }

  return (
    <Container className={classes.root}>
      <Grid container spacing={2} direction="row" className={classes.row}>
        <Grid item xs={12} md={3}>
          <Typography variant="h4">Avatar</Typography>
          <Typography variant="subtitle1">
            To change your avatar, visit <Link
            rel="noopener"
            target="_blank"
            href="https://gravatar.com/"
            underline="hover">Gravatar</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} md={8}>
          <UserAvatar email={user.email} className={classes.large}/>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '3em' }}/>
      <Grid container spacing={2} direction="row" className={classes.row}>
        <Grid item xs={12} md={3}>
          <Typography variant="h4">Main Information</Typography>
          <Typography variant="subtitle1">
            This information will appear in items such as action items and projects.
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} md={8} >
          <Grid container spacing={2} direction="row" className={classes.profileItem}>
            {['firstname', 'lastname', 'email'].map(item => (
              <Grid item xs={12} md={12} key={item}>
                <TextField
                  style={{ width: "100%" }}
                  id={item}
                  label={labels[item]}
                  variant="outlined"
                  defaultValue={''}
                  value={tmpUser[item]}
                  onChange={handleChange(item)} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row" className={classes.row} justifyContent="center">
        <Button color="primary" variant="contained" disabled={saveDisabled} onClick={handleSave}>Save Changes</Button>
      </Grid>
    </Container>
  );
}

export default UserProfile;