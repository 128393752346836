import React from 'react';
import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  xsmall: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const IconAvatar = (props) => {
  const { icon, size='large' } = props
  const classes = useStyles();


  return (
    <Avatar className={classes[size]} variant="square">
      {icon}
    </Avatar>
  )
  
}

export default IconAvatar;