export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelcompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          items {
            id
            username
            email
            firstname
            lastname
            superUser
            companyAdmin
            companyID
            creatorID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
          }
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;