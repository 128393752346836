import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { ArrowForward, ThumbDown, ThumbUp } from '@mui/icons-material';
import { API } from 'aws-amplify';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import Steps from '../../constants/steps_ca';
import * as mutations from '../../graphql/mutations'

const CompleteStep = (props) => {
  const { status, usergroups = { items: [] }, user, steps, getSetAI, correctiveActionID, assignee, getSetProject } = props
  console.log(assignee.id && user.id ? assignee.id === user.id : undefined) 

  const StepWorkflow = (direction = 'forward', nSteps = 1, nextStepOption=null) => {
    var stepDirection = 'nextStep'
    if (direction === 'backward') {
      stepDirection = 'lastStep'
    }

    var tmpState = status.statusState;

    for (let i = 0; i < nSteps; i++) {
      if (nextStepOption) {
        tmpState = steps[tmpState][stepDirection][nextStepOption]
      } else {
        tmpState = steps[tmpState][stepDirection]
      }
    }

    const updateStatus = {
      id: status.id,
      statusState: tmpState,
      status: Steps[tmpState].label,
      lastModifierID: user.id,
      _version: status._version
    }

    API.graphql({ query: mutations.updateStatus, variables: { input: updateStatus } })
      .then(response => {
        getSetAI(correctiveActionID)
        getSetProject()
      })
  }

  const getDisabled = (state) => {
    var disabled = true;

    var roles = {
      admin: false,
      engineering: false,
      purchasing: false,
      quality: false,
      production: false,
      supplier: false
    }

    for (let i = 0; i < Object.keys(roles).length; i++){
      if (usergroups.items.find(item => item.name === Object.keys(roles)[i]).users.items.find(item => item.userID === user.id)) {
        roles[Object.keys(roles)[i]] = true
      } 
    }
  
    var stepApprovalRoles = steps[status.statusState].approvalRoles

    for (let i = 0; i < stepApprovalRoles.length; i++){
      if (roles[stepApprovalRoles[i]]) {
        disabled = false
      }
    }
  
    if (state === 'open') {
      if (!disabled) {
        return disabled
      } else {
        return assignee.id && user.id ? !(assignee.id === user.id) : true
      }
    } else {
      return disabled
    }

  }

  useEffect(() => {
    try {
      getDisabled()
    } catch (err) {
      console.log(err)
    }
  }, [status])

  switch (status.statusState) {
    case "open":
      return (
        <Card style={{ marginTop: "1em" }}>
          <CardContent>
            <Typography variant="h5">
              Submit for Review
            </Typography>
            <Typography variant="body2" component="p">
              Does assignee or supplier wish to submit the above root cause and corrective actions for review?
            </Typography>
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: "space-evenly" }}>
            <Button startIcon={<ThumbUp />} color="primary" variant="contained" disabled={getDisabled(status.statusState)} onClick={() => StepWorkflow()}>Yes</Button>
          </CardActions>
        </Card>
      )
    case "review_pending":
      return (
        <Card style={{ marginTop: "1em" }}>
          <CardContent>
            <Typography  variant="h5">
              Approve
            </Typography>
              <Typography variant="body2" component="p">
                Does engineering approve of the submitted corrective action and root cause?
            </Typography>
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: "space-evenly" }}>
            
              <Button startIcon={<ThumbDown />} color="secondary" variant="contained" disabled={getDisabled()} onClick={() => StepWorkflow('forward', 1, 'disapprove')}>No</Button>
            <Button startIcon={<ThumbUp />} color="primary" variant="contained" disabled={getDisabled()} onClick={() => StepWorkflow('forward',1,'approve')}>Yes</Button>
          </CardActions>
        </Card>
      )
    default:
      return (
        <>
        </>
      )
  }
}

export default CompleteStep;