import { Card, CardContent, CardHeader, Grid, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from "react";
import { format, parse } from "date-fns";
import Attachment from "../Utilities/Attachment/Attachment";
import { getActionitem } from "../../graphql/getActionitem";
import AttachmentList from "../Utilities/Attachment/AttachmentList";
import * as mutations from "../../graphql/mutations";
import { API, Storage } from "aws-amplify";
import UserContext from "../../contexts/UserContext";
import FileUploadBox from "../Utilities/FileUpload/FileUploadBox";
import Steps from "../../constants/steps_ca";
import Timeline from "../Utilities/Timeline";
import { getProject } from "../../graphql/getUserAccessGroups";
import CompleteStep from "./CompleteStep";

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    marginTop: "0.5em",
  },
  caption: {
    marginRight: "20px",
  },
}));

const CorrectiveAction = (props) => {
  const {
    parentItem = {},
    correctiveActionID = "",
    child = false,
    getSetProject,
  } = props;
  const { user } = useContext(UserContext);
  const [correctiveAction, setCorrectiveAction] = useState({
    attachment: { items: [] },
    assignee: {
      firstname: "",
      lastname: "",
    },
    createdAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    duedate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    finding: "",
    rootcause: "",
    correctiveAction: "",
    status: {},
  });
  const [update, setUpdate] = useState(false);
  const [parentProject, setParentProject] = useState({});

  useEffect(() => {
    getSetAI(correctiveActionID);
  }, [correctiveActionID]);
  const classes = useStyles();

  const handleFindingChange = (event) => {
    setCorrectiveAction({ ...correctiveAction, finding: event.target.value });
    setUpdate(true);
  };

  const handleRootCauseChange = (event) => {
    setCorrectiveAction({ ...correctiveAction, rootcause: event.target.value });
    setUpdate(true);
  };
  const handleCorrectiveActionChange = (event) => {
    setCorrectiveAction({
      ...correctiveAction,
      correctiveAction: event.target.value,
    });
    setUpdate(true);
  };

  const handleBlur = () => {
    if (update) {
      const updateActionitem = {
        id: correctiveAction.id,
        _version: correctiveAction._version,
        finding: correctiveAction.finding,
        duedate: correctiveAction.duedate,
        rootcause: correctiveAction.rootcause,
        correctiveAction: correctiveAction.correctiveAction,
        lastModifierID: user.id,
      };

      API.graphql({
        query: mutations.updateActionitem,
        variables: { input: updateActionitem },
      }).then(setUpdate(false));
    }
  };

  const uploadAttachment = async (file) => {
    const newAttach = {
      name: file.name,
      filename: file.name,
      location: "",
      datatype: file.type,
      creatorID: user.id,
      lastModifierID: user.id,
      adminUser: correctiveAction.adminUser,
      editUser: correctiveAction.editUser,
      viewUser: correctiveAction.viewUser,
    };
    const attachmentID = await API.graphql({
      query: mutations.createAttachment,
      variables: { input: newAttach },
    })
      .then((response) => response.data.createAttachment.id)
      .catch((err) => console.log(err));
    Storage.put(attachmentID, file, {
      contentType: file.type,
    }).catch((error) => console.log(error));

    var newAttachLink = {
      attachmentID: attachmentID,
      actionitemID: correctiveAction.id,
    };

    API.graphql({
      query: mutations.createAttachmentactionitem,
      variables: { input: newAttachLink },
    }).catch((err) => console.log(err));
  };

  const handleUpload = async (files) => {
    await files.map((file) => uploadAttachment(file));
    getSetAI(correctiveAction.id);
  };

  const getSetAI = async (correctiveActionID) => {
    var corrAI = await API.graphql({
      query: getActionitem,
      variables: { id: correctiveActionID },
    }).then((response) => response.data.getActionitem);

    var steps = { ...Steps };
    corrAI.steps = steps;
    corrAI.activeStep = Object.keys(steps).findIndex(
      (item) => item === corrAI.status.statusState
    );
    if (corrAI.activeStep === Object.keys(steps).length - 1) {
      corrAI.activeStep = corrAI.activeStep + 1;
    }

    await API.graphql({
      query: getProject,
      variables: { id: corrAI.projectID },
    }).then((response) => setParentProject(response.data.getProject));
    setCorrectiveAction(corrAI);
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Timeline
              steps={correctiveAction.steps}
              threeColumn={false}
              activeStep={correctiveAction.activeStep}
            />
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h5">{correctiveAction.actionitem}</Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="caption" className={classes.caption}>
              Created:{" "}
              {format(
                parse(
                  correctiveAction.createdAt,
                  "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                  new Date()
                ),
                "Pp"
              )}
            </Typography>
            <Typography variant="caption" className={classes.caption}>
              Assignee:{" "}
              {correctiveAction.assignee.firstname +
                " " +
                correctiveAction.assignee.lastname}
            </Typography>
            <Typography variant="caption" className={classes.caption}>
              Due:{" "}
              {format(
                parse(
                  correctiveAction.duedate,
                  "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                  new Date()
                ),
                "Pp"
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              value={correctiveAction.finding}
              className={classes.textfield}
              multiline
              variant="outlined"
              label="Finding"
              onChange={handleFindingChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              value={correctiveAction.rootcause}
              className={classes.textfield}
              multiline
              variant="outlined"
              label="Root Cause"
              onChange={handleRootCauseChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              value={correctiveAction.correctiveAction}
              className={classes.textfield}
              multiline
              variant="outlined"
              label="Corrective Actions"
              onChange={handleCorrectiveActionChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item sm={12}>
            <Grid container spacing={1}>
              <AttachmentList
                attachments={
                  correctiveAction.attachment
                    ? correctiveAction.attachment.items
                    : []
                }
                getSetProject={getSetProject}
              />
            </Grid>

            <FileUploadBox handleUpload={handleUpload} />
          </Grid>
          <Grid item sm={12}>
            <CompleteStep
              usergroups={parentProject.usergroups}
              status={correctiveAction.status}
              steps={correctiveAction.steps}
              assignee={correctiveAction.assignee}
              correctiveActionID={correctiveActionID}
              user={user}
              getSetAI={(correctiveActionID) => getSetAI(correctiveActionID)}
              getSetProject={getSetProject}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CorrectiveAction;
