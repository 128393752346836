import React, { useState, useEffect } from "react";
import Amplify from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from "@aws-amplify/ui-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import awsconfig from "./aws-exports";
import AppBar from "./components/Base/AppBar";
import Main from "./components/Base/Main";
import "./App.css";
import { Hub } from "aws-amplify";
import UserContext from "./contexts/UserContext";
import ProjectsContext from "./contexts/ProjectsContext";
import LoadingContext from "./contexts/LoadingContext";
import Logger from "./services/Logger";

import { BrowserRouter as Router } from "react-router-dom";
import getUser from "./services/GetUser";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

Amplify.configure(awsconfig);
Amplify.Logger.LOG_LEVEL = "INFO";

const initFeatures = () => {
  localStorage.setItem(
    "flags",
    JSON.stringify([
      {
        name: "module_fai_enable",
        description: "FAI Module is enabled",
        active: process.env.REACT_APP_FAI_MODULE_ENABLE === "true",
      },
      {
        name: "module_mtr_enable",
        description: "MTR Module is enabled",
        active: process.env.REACT_APP_MTR_MODULE_ENABLE === "true",
      },
      {
        name: "module_rfq_enable",
        description: "RFQ Module is enabled",
        active: process.env.REACT_APP_RFQ_MODULE_ENABLE === "true",
      },
      {
        name: "feature_leftProjectDrawer_enable",
        description: "Left Project Drawer is enabled",
        active: process.env.REACT_APP_FEATURE_LEFTPROJECTDRAWER === "true",
      },
      {
        name: "feature_rightProjectDrawer_enable",
        description: "Right Project Drawer is enabled",
        active: process.env.REACT_APP_FEATURE_RIGHTPROJECTDRAWER === "true",
      },
      {
        name: "feature_displayhistory",
        description: "Display of project history is enabled",
        active: process.env.REACT_APP_FEATURE_DISPLAYHISTORY === "true",
      },
    ])
  );
};

initFeatures();

const logger = new Logger("Unknown");

function App() {
  const [user, setUser] = useState({
    id: "Unknown",
    loggedIn: false,
    company: {},
    faiCreateEnabled: false,
    mtrCreateEnabled: false,
  });
  const [projectlist, setProjectlist] = useState({ fai: [], mtr: [] });
  const [loading, setLoading] = useState({ long: true, short: false });

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      logger.info(`Auth Hub event: ${event}`);
      switch (event) {
        case "signIn":
          getUser(user, setUser)
            .then((userData) => userData)
            .catch((err) => console.log(err));
          break;
        case "cognitoHostedUI":
          getUser(user, setUser)
            .then((userData) => userData)
            .catch((err) => console.log(err));
          break;
        case "signOut":
          setUser({
            id: "Unknown",
            loggedIn: false,
            email: "",
            firstname: "",
            faiCreateEnabled: false,
            mtrCreateEnabled: false,
          }).catch((err) => console.log(err));
          setProjectlist({ fai: [], mtr: [] });
          break;
        case "signIn_failure":
          break;
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          console.log(event);
          setLoading({ long: false, short: false });
      }
    });
    setLoading({ long: true, short: false });
    getUser(user, setUser)
      .then(() => console.log(user))
      .catch((err) => console.log(err));
    setProjectlist({ fai: [], mtr: [] });
    setLoading({ long: false, short: false });
  }, []);

  useEffect(() => {
    logger.setUserId(user.id);
  }, [user]);

  console.log(user);
  return (
    //<AmplifyAuthenticator>
    <DndProvider backend={HTML5Backend}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <UserContext.Provider value={{ user, setUser }}>
          <ProjectsContext.Provider value={{ projectlist, setProjectlist }}>
            <LoadingContext.Provider value={{ loading, setLoading }}>
              <Router>
                <AppBar>
                  <AmplifyAuthenticator usernameAlias="email" hideToast="false">
                    <AmplifySignUp
                      usernameAlias="email"
                      slot="sign-up"
                      formFields={[{ type: "email" }, { type: "password" }]}
                    />

                    <AmplifySignIn
                      headerText="Welcome! Please Sign In."
                      slot="sign-in"
                      formFields={[{ type: "email" }, { type: "password" }]}
                    ></AmplifySignIn>
                    <Main />
                  </AmplifyAuthenticator>
                </AppBar>
              </Router>
            </LoadingContext.Provider>
          </ProjectsContext.Provider>
        </UserContext.Provider>
      </LocalizationProvider>
    </DndProvider>
    //</AmplifyAuthenticator>
  );
}

export default App;
