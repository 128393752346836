import React, { useCallback, useContext } from 'react';
import { Grid, Icon, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDropzone } from 'react-dropzone'
import { CloudQueue, CloudUpload } from '@mui/icons-material';
import { API, Storage } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import CurrentProjectContext from '../../../contexts/CurrentProjectContext';
import UserContext from '../../../contexts/UserContext';
import { formatProject } from '../../Utilities/FormatProject';
import * as getProjectquery from '../../../graphql/getProject';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    minHeight: '5rem',
    padding: '2rem',
    textAlign: 'left'
  },
}));

const baseStyle = {
  minHeight: '5rem',
  textAlign: 'left',
  display: 'flex',
  flexFlow: 'row',
  alignItems: 'center'
};

const FileUploadBox = (props) => {
  const { attachmentUse = 'requirement', parentID='', handleUpload, Steps} = props
  const { user } = useContext(UserContext);
  const { project, setProject } = useContext(CurrentProjectContext);
  
  const classes = useStyles();

  const onDrop = useCallback(acceptedFiles => {
    const uploadAttachment = async (file) => {
    var adminUser = [];
    var editUser = [];
    var viewUser = [];

    if (attachmentUse === 'requirement') {
      adminUser.push(...project.usergroups.items.find(item => item.name === 'admin').users.items.map(item => item.userID));
      adminUser.push(...project.usergroups.items.find(item => item.name === 'engineering').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'purchasing').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'quality').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'production').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'supplier').users.items.map(item => item.userID));
    } else if (attachmentUse === 'deliverable') {
      adminUser.push(...project.usergroups.items.find(item => item.name === 'admin').users.items.map(item => item.userID));
      adminUser.push(...project.usergroups.items.find(item => item.name === 'supplier').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'purchasing').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'engineering').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'quality').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'production').users.items.map(item => item.userID));
    } else if (attachmentUse === 'inspection') {
      adminUser.push(...project.usergroups.items.find(item => item.name === 'admin').users.items.map(item => item.userID));
      adminUser.push(...project.usergroups.items.find(item => item.name === 'quality').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'supplier').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'purchasing').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'engineering').users.items.map(item => item.userID));
      viewUser.push(...project.usergroups.items.find(item => item.name === 'production').users.items.map(item => item.userID));
    }

    const newAttach = {
      projectID: project.id,
      name: file.name,
      filename: file.name,
      location: '',
      datatype: file.type,
      creatorID: user.id,
      lastModifierID: user.id,
      adminUser: adminUser,
      editUser: editUser,
      viewUser: viewUser

    }
    const attachmentID = await API.graphql({ query: mutations.createAttachment, variables: { input: newAttach } })
      .then(response => response.data.createAttachment.id)
    Storage.put(attachmentID, file, {
      contentType: file.type
    })
      .catch(error => console.log(error))
    
    
    var newAttachLink = {
      attachmentID: attachmentID,
    }
    if (attachmentUse === 'requirement') {
      newAttachLink = { ...newAttachLink, requirementID: parentID }
      API.graphql({ query: mutations.createRequirementattachment, variables: { input: newAttachLink } })
        .catch(err => console.log(err))
    } else if (attachmentUse === 'deliverable') {
      newAttachLink = { ...newAttachLink, deliverableID: parentID }
      API.graphql({ query: mutations.createDeliverableattachment, variables: { input: newAttachLink } })
        .catch(err => console.log(err))
    } else if (attachmentUse === 'inspection') {
      newAttachLink = { ...newAttachLink, inspectionID: parentID }
      API.graphql({ query: mutations.createInspectionattachment, variables: { input: newAttachLink } })
        .catch(err => console.log(err))
    }

    var tmpProject = await API.graphql({query: getProjectquery.getProject , variables: {id: project.id }})
    tmpProject = formatProject(tmpProject.data.getProject, Steps)
    setProject(tmpProject)
    }
    
    if (handleUpload) {
      if (acceptedFiles) {
        handleUpload(acceptedFiles)
      }
    } else {
      acceptedFiles.map(file => uploadAttachment(file))
    }
    
  }, [handleUpload, Steps, attachmentUse, parentID, project, setProject, user])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  

  return (
    <Grid
      item
      xs={12}
      sm={ 12}
      md={12}
      className={classes.root}
    >
      <Paper elevation={2} style={{ width: '100%', height: '100%' }} >
        <div {...getRootProps()}  style={isDragActive ? { ...baseStyle, backgroundColor: 'rgba(80, 120, 255, 0.1)' } : baseStyle}>
          <input {...getInputProps()} />
          <Icon style={{ flexBasis: '10%', marginLeft: '2em' }}>{isDragActive ? <CloudQueue /> : <CloudUpload />}</Icon>
          <Typography variant='body2' style={{ flexBasis: '90%' }}>
            {isDragActive ? 'Release to upload' : 'Drag file here to upload'}
          </Typography>
        </div>
      </Paper>
    </Grid>
  )
}

export default FileUploadBox;