import { Container, Divider, Grid, Typography, TextField, Button, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { API } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import UserContext from '../../contexts/UserContext';
import * as queries from '../../graphql/queries';
import { getCompany } from '../../graphql/getCompany';
import * as mutations from '../../graphql/mutations';
import { useParams } from 'react-router-dom';
import TeamMemberSection from '../Utilities/TeamMembers/TeamMemberSection';
import { useHistory } from "react-router-dom";
import { Check, Edit } from '@mui/icons-material';
import graphQuery from '../../services/AWSGraphWrapper';
import GetCompany from '../../services/GetCompany';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: '100%',
    //backgroundColor: theme.palette.background.paper,
  },
  row: {
    width: '100%',
    paddingTop: '3em'
  },
  profileItem: {
    width: '100%'
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const companyGroups = [
  'purchasing',
  'engineering',
  'quality',
  'production',
  'admin',
  'contacts'
]

const selfCompanyGroups = [
  'purchasing',
  'engineering',
  'quality',
  'production',
  'admin',
]

const supplierGroups = [
  'contacts'
]

const teamGroups = {
  admin: {
    name: 'Admin',
    description: 'Create and Update FAI, Create and Update MTR'
  },
  purchasing: {
    name: 'Purchasing',
    description: 'Update FAI, Create MTR'
  },
  engineering: {
    name: 'Engineering',
    description: 'Create FAI, Update MTR'
  },
  quality: {
    name: 'Quality',
    description: 'Update FAI, Update MTR'
  },
  production: {
    name: 'Production',
    description: 'Read FAI, Create MTR'
  },
  supplier: {
    name: 'Supplier',
    description: 'Responsible for completing sample data collection and acknowledging requirements.'
  },
  contacts: {
    name: 'Contacts',
    description: 'Supplier contacts. If associated with a project, they can perform all supplier actions.'
  }
}

const ManageCompany = (props) => {
  const { user, setUser } = useContext(UserContext);
  const { companyid } = useParams();
  const [company, setCompany] = useState({ groups: { items: [{ users: { items: [] }, name: '' }] } });
  const [refreshCompany, setRefreshCompany] = useState(true)
  const [tmpCompany, setTmpCompany] = useState({});
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [editCompany, setEditCompany] = useState(false);
  const [, setIsSupplier] = useState(false);
  const [validGroups, setValidGroups] = useState([]);
  let history = useHistory();
  
  useEffect(() => {

    const removeDeletedUsers = (groups) => {
      return (
        groups.map(group => (
          { ...group, users: { items: group.users.items.filter(item => item._deleted !== true) } }
        ))
      )
    }

    const setCompanystate = (newcompany) => {
      var oldcompany = {...newcompany, groups: {items: removeDeletedUsers(newcompany.groups.items)}}
      if (oldcompany.id === user.companyID) {
        setIsSupplier(false)
        setValidGroups(selfCompanyGroups)
      } else {
        setIsSupplier(true)
        setValidGroups(supplierGroups)
      }
      setCompany(oldcompany)
      setTmpCompany(oldcompany)
    }

    graphQuery({ query: queries.listUsers })
      .then(response => {
        var tmpusers = response
        setUsers(tmpusers.filter(item => (!item.companyID) | (item.companyID === companyid)))
      })
    
    GetCompany(companyid)
      .then(response => {
        if (response.groups.items.length === 0) {
          for (let i = 0; i < companyGroups.length; i++) {
            const newGroup = {
              name: companyGroups[i],
              creatorID: user.id,
              lastModifierID: user.id,
              companyID: companyid
            }
            console.log(`Creating group ${companyGroups[i]}`)
            API.graphql({ query: mutations.createGroup, variables: { input: newGroup } })
              .then(response => console.log(response.data.createGroup.id))
          }
          API.graphql({ query: getCompany, variables: { id: companyid } })
            .then(response => setCompanystate(response.data.getCompany))
        } else {
          setCompanystate(response)
        }
      })
      .catch(err => console.log(err))
    
    setRefreshCompany(refreshCompany => false)
  }, [user, companyid, refreshCompany])

  const setCompanystate = (newcompany) => {   
    if (newcompany.id === user.companyID) {
      setIsSupplier(false)
      setValidGroups(selfCompanyGroups)
    } else {
      setIsSupplier(true)
      setValidGroups(supplierGroups)
    }
    setCompany(newcompany)
    setTmpCompany(newcompany)
  }
  
  const handleCreate = async () => {
    const newCompany = {
      name: tmpCompany.name,
      creatorID: user.id,
      lastModifierID: user.id
    }
    const newCompanyID = await API.graphql({ query: mutations.createCompany, variables: { input: newCompany } })
      .then(response => response.data.createCompany.id)
    
    for (let i = 0; i < companyGroups.length; i++) {
      const newGroup = {
        name: companyGroups[i],
        creatorID: user.id,
        lastModifierID: user.id,
        companyID: newCompanyID
      }
      console.log(`Creating group ${companyGroups[i]}`)
      API.graphql({ query: mutations.createGroup, variables: { input: newGroup } })
        .then(response => console.log(response.data.createGroup.id))
    }

    const updatedUser = {
      id: user.id,
      lastModifierID: user.id,
      companyID: newCompanyID,
      _version: user._version
    }
    API.graphql({ query: mutations.updateUser, variables: { input: updatedUser } })
      .then(response => {
        setUser({ ...user, ...response.data.updateUser })
        history.push(`/settings/companyprofile/${newCompanyID}`)
      }
      )
    
    

  }

  const handleChangeCompanyName = (event) => {
    setTmpCompany({ ...tmpCompany, name: event.target.value })
  }

  const handleBlur = () => {    
    if (tmpCompany.id) {
      const updatedCompany = {
        id: tmpCompany.id,
        name: tmpCompany.name,
        _version: tmpCompany._version,
        lastModifierID: user.id
      }
      API.graphql({ query: mutations.updateCompany, variables: { input: updatedCompany } })
        .then(
          API.graphql({ query: getCompany, variables: { id: companyid } })
            .then(response => setCompanystate(response.data.getCompany))
        )
    }
  }

  
  const findUserGroup = (userId, groupUsers) => {
    try {
      return groupUsers.sort((a, b) => a._lastChangedAt > b._lastChangedAt).find(item => item.userID === userId)
    }
    catch (err) {
      console.error(err)
      return {}
    }
  }

  const handleAddUser = (newUser, group) => {

    if (findUserGroup(newUser.id, group.users.items)) {
      console.log('User exists in group')
    } else {
      const newUsergroup = {
        userID: newUser.id,
        groupID: group.id
      }
      API.graphql({ query: mutations.createUsergroups, variables: { input: newUsergroup } })
        .then(response => {
          if (newUser.company === null) {
            const updatedUser = {
              id: newUser.id,
              companyID: group.companyID,
              lastModifierID: user.id
      
            }
            API.graphql({ query: mutations.updateUser, variables: { input: updatedUser } })
              .then(response => console.log(response))
          }          
        })
      setRefreshCompany(true)
    }
  }

  
  const handleRemoveUser = (oldUser, group) => {
    console.log(oldUser)
    console.log(group)
    API.graphql({ query: mutations.deleteUsergroups, variables: { input: { id: findUserGroup(oldUser.id, group.users.items).id, _version:group._version } } })
      .then(response => console.log(response))
    setRefreshCompany(true)
  }

  return (
    <Container className={classes.root}>
      <Grid container spacing={2} direction="row" className={classes.row}>
        <Grid item xs={12} md={3}>
        </Grid>
        <Grid item xs={12} md={1}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2} direction="row" className={classes.profileItem}>
            <Grid item xs={12}>
              {companyid === 'create' ?
                <TextField style={{ width: "100%" }} id='company-id' label='Company Name' variant="outlined" onChange={handleChangeCompanyName} />
                :
                
                <Grid container spacing={2} direction="row" className={classes.profileItem}>
                  <Grid item xs={10} style={{textAlign: 'center'}}>
                    <Typography variant="caption" style={{ width: "100%", marginLeft: '-1em' }} >Company Name</Typography>
                    {editCompany ?
                      <TextField
                        value={tmpCompany.name}
                        style={{ width: "100%" }}
                        id='company-id'
                        variant="outlined"
                        onChange={handleChangeCompanyName}
                        onBlur={handleBlur}
                      /> :
                      <Typography variant="h5" style={{ width: "100%" }} >{company.name} </Typography>
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      style={{ float: 'right' }}
                      color="primary"
                      onClick={() => setEditCompany(!editCompany)}
                      size="large">
                      {editCompany ? <Check /> : <Edit />}
                    </IconButton>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: '3em' }} />
      
      {companyid !== 'create' ? (
        <>
          <Grid container spacing={2} direction="row" className={classes.row}>
            <Grid item xs={12} md={3}>
              <Typography variant="h4">Roles and Responsibilities</Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="h4">Users</Typography>
            </Grid>
            <Grid item xs={12} md={8}>

            </Grid>
          </Grid>
          {validGroups.map(groupname => {
            const groupsi = company.groups.items.find(item => item.name===groupname)
            if (groupsi) {
              return (
                <TeamMemberSection
                  key={groupsi.id}
                  group={groupsi}
                  users={groupsi.users.items.map(item => item.user)}
                  groupDescription={teamGroups[groupsi.name] ? teamGroups[groupsi.name]['description'] : ''}
                  availUsers={users}
                  handleAddUser={(newUser) => handleAddUser(newUser, groupsi)}
                  handleRemoveUser={(oldUser) => handleRemoveUser(oldUser, groupsi)}
                />
  
              )
            } else {
              return (null)
            }
          }
          )}
        </>
      ) : (
        <Grid container spacing={2} direction="row" className={classes.row} justifyContent="center">
          <Button color="primary" variant="contained" onClick={handleCreate}>Create Company</Button>
        </Grid>
      )}
    </Container>
  );
}

export default ManageCompany;