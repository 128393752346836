import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import UserAvatar from './UserAvatar';

const SelectUser = (props) => {
  const { group, users, handleAddUser, handleClose, open} = props
  
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select User to add to {group.name.charAt(0).toUpperCase() + group.name.slice(1)} </DialogTitle>
      <List>
        {users.map((useri) => (
          <ListItem button onClick={() => handleAddUser(useri)} key={useri.id}>
            <ListItemAvatar>
              <ListItemAvatar>
                <UserAvatar email={useri.username}/>
              </ListItemAvatar>
            </ListItemAvatar>
            <ListItemText primary={useri.firstname + ' ' + useri.lastname + "    |    " + useri.username} secondary={useri.company ? useri.company.name : null} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default SelectUser;