import React, { useEffect, useState } from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TeamMember from './TeamMember';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flex: 1
    
  },
}));


const TeamMembers = (props) => {
  const {project, handleRemoveUser} = props
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  
  const [expanded, setExpanded] = React.useState(false);
  const handleAccordianChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    var allUsers = []
    project.usergroups.items.map(usergroup => {
      if (usergroup.users) {
        usergroup.users.items.map(usergroupuser => {
          if (!usergroupuser._deleted) {

            var tmpUser = { ...usergroupuser.user, role: usergroup.name, accessGroupId: usergroupuser.id }
            allUsers.push(tmpUser)
            return null
          }
          return null
        })
      }
      return null
    })
    allUsers.sort((a, b) => {
      let fa = a.firstname ? a.firstname.toLowerCase() : ''
      let fb = b.firstname ? b.firstname.toLowerCase() : ''
      
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    setUsers(allUsers)
  }, [project])

  const handleRemoveUserWrapper = (oldUser) => {
    handleRemoveUser(oldUser)
  }

  return (
    <Grid item xs={12}>
      <Accordion expanded={expanded} onClick={handleAccordianChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"bh-content"}
          id={"bh-header"}
        >
          <Typography className={classes.heading}>Team Members</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {users.map((item, index) => (
                <TeamMember
                  key={`${item.id}-${item.role}-${index}`}
                  teamMember={item}
                  role={item.role}
                  handleClear={(oldUser) => handleRemoveUserWrapper(oldUser)}
                />
              ))}
            </Grid>
          </Grid>      
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default TeamMembers;