import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import MuiAppBar from "@mui/material/AppBar";
import {
  Breadcrumbs,
  Button,
  CssBaseline,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AccountCircle,
  ExitToApp,
  ExpandMore,
  Home as HomeIcon,
  Widgets,
} from "@mui/icons-material";

import NavDrawer from "./NavDrawer";
import UserContext from "../../contexts/UserContext";
import UserAvatar from "../Utilities/TeamMembers/UserAvatar";

import L4DSpinner from "../Utilities/L4DSpinner";

import { Auth } from "aws-amplify";

import {
  Link as RouterLink,
  matchPath,
  useHistory,
  useLocation,
} from "react-router-dom";

import config from "../../config.json";
import LoadingContext from "../../contexts/LoadingContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProjectsContext from "../../contexts/ProjectsContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.darkbackground.main,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarBottom: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.darkbackground.main,
    zIndex: theme.zIndex.drawer + 1,
    top: "auto",
    bottom: 0,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
      zIndex: theme.zIndex.drawer + 1,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginBottom: theme.spacing(8),
    width: "calc(100vw - 240px)",
    offset: theme.mixins.toolbar,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  link: {
    display: "flex",
    color: "#FFFFFF",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: "#FFFFFF",
  },
}));

const AppBarBase = withStyles((theme) => ({
  root: (props) =>
    props.color === "darkbackground"
      ? {
          color: theme.palette.darkbackground.contrastText,
          backgroundColor: theme.palette.darkbackground.main,
        }
      : {},
}))(MuiAppBar);

const year = new Date().getFullYear();

const AppBar = (props) => {
  const { user } = useContext(UserContext);
  const { loading } = useContext(LoadingContext);
  const { children } = props;
  const [open, setOpen] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(false);
  const [module, setModule] = React.useState("");
  const [, setParam] = React.useState("");
  const [secondary, setSecondary] = React.useState("");
  const { projectlist, setProjectlist } = useContext(ProjectsContext);
  const classes = useStyles();
  let history = useHistory();

  const location = useLocation();

  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down("xl")),
    sm: useMediaQuery(theme.breakpoints.up("sm")),
    md: useMediaQuery(theme.breakpoints.up("md")),
    lg: useMediaQuery(theme.breakpoints.up("lg")),
  };

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const handleProfileClick = () => {
    handleClose();
  };

  async function signOut() {
    var features = JSON.parse(localStorage.getItem("flags"));

    var elementsIndex = features.findIndex(
      (element) => element.name === "faiCreateEnabled"
    );
    if (elementsIndex >= 0) {
      features[elementsIndex] = {
        name: "faiCreateEnabled",
        description: "User can create FAI",
        active: false,
      };
    } else {
      features = [
        ...features,
        {
          name: "faiCreateEnabled",
          description: "User can create FAI",
          active: false,
        },
      ];
    }

    elementsIndex = features.findIndex(
      (element) => element.name === "mtrCreateEnabled"
    );

    if (elementsIndex >= 0) {
      features[elementsIndex] = {
        name: "mtrCreateEnabled",
        description: "User can create MTR",
        active: false,
      };
    } else {
      features = [
        ...features,
        {
          name: "mtrCreateEnabled",
          description: "User can create MTR",
          active: false,
        },
      ];
    }
    localStorage.setItem("flags", JSON.stringify(features));
    setAnchorMenu(false);
    setProjectlist({ fai: [], mtr: [] });
    try {
      history.push("/home");
      Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  useEffect(() => {
    const matchPathReturn = matchPath(location.pathname, {
      path: "/:main/:secondary*/:param",
      exact: false,
      strict: false,
    });
    if (matchPathReturn) {
      setModule(matchPathReturn.params.main.toUpperCase());
      if (matchPathReturn.params.param) {
        setParam(matchPathReturn.params.param.toUpperCase());
      } else {
        setParam("");
      }
      if (matchPathReturn.params.secondary) {
        setSecondary(matchPathReturn.params.secondary.toUpperCase());
      } else {
        setSecondary("");
      }
    } else {
      setModule("");
      setSecondary("");
      setParam("");
    }
  }, [location]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarBase
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            disabled={false}
            aria-label="open drawer"
            onClick={() => history.push("/home")}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            style={{ color: "#FFFFFF" }}
            size="large"
          >
            <HomeIcon />
          </IconButton>
          <img
            src={
              process.env.REACT_APP_HEADER_LOGO
                ? process.env.REACT_APP_HEADER_LOGO
                : config.headerLogo
            }
            alt="Leverage4Data Logo"
            style={{ height: "2.5em" }}
          ></img>
          {user.loggedIn ? (
            <>
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{ marginLeft: "2.5em", flexGrow: 2, color: "#FFFFFF" }}
              >
                <Link
                  component={RouterLink}
                  to={`/`}
                  className={classes.link}
                  underline="hover"
                >
                  <HomeIcon className={classes.icon} />
                  Home
                </Link>
                {module !== "" ? (
                  <div>
                    <Link
                      component={RouterLink}
                      to={`/${module.toLowerCase()}/home`}
                      className={classes.link}
                      underline="hover"
                    >
                      <Widgets className={classes.icon} />
                      {module}
                    </Link>
                  </div>
                ) : null}
                {secondary !== "" ? (
                  <div>
                    <Link
                      component={RouterLink}
                      to={`/${module.toLowerCase()}/home`}
                      className={classes.link}
                      underline="hover"
                    >
                      <Widgets className={classes.icon} />
                      {secondary == "COMPANYPROFILE" && user.company
                        ? user.company.name + " Profile"
                        : secondary}
                    </Link>
                  </div>
                ) : null}
              </Breadcrumbs>
              {matches.sm ? (
                <Typography
                  style={{ marginRight: "1em", color: "#FFFFFF" }}
                  variant="body2"
                >
                  {`${user.firstname} ${user.lastname}`}
                </Typography>
              ) : (
                <div />
              )}
              <Button
                color="inherit"
                startIcon={<UserAvatar email={user ? user.email : ""} />}
                endIcon={<ExpandMore />}
                onClick={handleClick}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorMenu}
                getContentAnchorEl={null}
                keepMounted={false}
                open={Boolean(anchorMenu)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {matches.sm ? null : (
                  <MenuItem disabled={true}>
                    <Typography style={{ marginRight: "1em" }} variant="body2">
                      {`${user.firstname} ${user.lastname}`}
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={handleProfileClick}
                  component={RouterLink}
                  to={`/settings/userprofile`}
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem onClick={signOut}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </Toolbar>
      </AppBarBase>
      <NavDrawer open={open} setOpen={setOpen} />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {loading.long ? <L4DSpinner /> : null}
        {children}
      </main>

      <AppBarBase
        position="fixed"
        className={clsx(classes.appBarBottom, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <img
            src={
              process.env.REACT_APP_HEADER_LOGO_W_NAME
                ? process.env.REACT_APP_HEADER_LOGO_W_NAME
                : config.headerLogowName
            }
            alt="Guild Logo"
            style={{ height: "2.5em" }}
          ></img>
          <Typography
            variant="caption"
            style={{ marginLeft: "0.5em", flexGrow: 2 }}
          >
            {process.env.REACT_APP_VERSION}
          </Typography>
          {matches.md ? (
            user.loggedIn ? (
              <>
                <Button
                  color="inherit"
                  style={{ marginRight: "2em" }}
                  component={RouterLink}
                  to={`/`}
                  size={"small"}
                >
                  Home
                </Button>
                {user.companyAdmin || user.superUser ? (
                  <Button
                    color="inherit"
                    style={{ marginRight: "2em" }}
                    component={RouterLink}
                    to={`/settings/suppliers`}
                    size={"small"}
                  >
                    Companies
                  </Button>
                ) : null}
              </>
            ) : null
          ) : null}
          {matches.sm ? (
            <img
              src={
                process.env.REACT_APP_FOOTER_LOGO
                  ? process.env.REACT_APP_FOOTER_LOGO
                  : config.footerLogo
              }
              alt="Leverage4Data Logo"
              style={{ height: "2.5em" }}
            />
          ) : null}
          <Typography variant="caption" style={{ marginLeft: "0.5em" }}>
            &copy; {year} | Leverage4Data, LLC
          </Typography>
        </Toolbar>
      </AppBarBase>
    </div>
  );
};

export default AppBar;
