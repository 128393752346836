import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Requirements from "./Requirements/Requirements";
import ProjectDescription from "../Base/ProjectDescription";
import Samples from "./Samples/Samples";
import Inspection from "./Inspection/Inspection";
import TeamMembers from "../Utilities/TeamMembers/TeamMembers";
import { useMediaQuery } from "react-responsive";

import Timeline from "../Utilities/Timeline";
import ActionItems from "../Utilities/ActionItem/ActionItems";
import FaiAppBar from "./FaiAppBar";
import CurrentProjectContext from "../../contexts/CurrentProjectContext";
//import Steps from '../../constants/steps';
import { formatProject } from "../Utilities/FormatProject";
import * as getProjectquery from "../../graphql/getProject";
import {
  deleteUseraccessgroupusers,
  updateProject,
} from "../../graphql/mutations";
import { API } from "aws-amplify";
import ProjectsContext from "../../contexts/ProjectsContext";
import LoadingContext from "../../contexts/LoadingContext";
import UserContext from "../../contexts/UserContext";
import { useHistory } from "react-router";
import config from "../../config.json";
import CommentList from "../Utilities/CommentList/CommentList";
import projectConfigs from "../../constants/projectConfigs";
import ModalActionsList from "../Utilities/ModalActionsList";
import { NavContext } from "../../contexts/NavContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
  },
  aside0: {
    margin: "1em",
    flex: 0,
  },
  aside1: {
    margin: "1em",
    flex: 2,
  },
  aside2: {
    width: "100%",
    display: "flex",
    flex: 1,
    padding: "1em",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  toolbar: {
    borderTop: "1px solid #e8e8e8",
    backgroundColor: theme.palette.darkbackground.secondary,
    color: "white",
    display: "flex",
    justifyContent: "space-between",
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

var Steps = { ...projectConfigs.find((item) => item.name === "fai").steps };
var steps = Steps;

const FAI = (props) => {
  const { ...other } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { user } = useContext(UserContext);
  const { project, setProject } = useContext(CurrentProjectContext);
  const { loading, setLoading } = useContext(LoadingContext);
  const { projectlist, setProjectlist } = useContext(ProjectsContext);
  const [accessIDs, setAccessIDs] = useState({});
  const [nav] = useContext(NavContext);

  let history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (project.activePhase === "requirements") {
      setValue(0);
    } else if (project.activePhase === "samples") {
      setValue(1);
    } else if (project.activePhase === "inspection") {
      setValue(2);
    }
    steps = project.steps;

    var adminUser = [];
    var editUser = [];
    var viewUser = [];

    try {
      adminUser.push(
        ...project.usergroups.items
          .find((item) => item.name === "admin")
          .users.items.map((item) => item.userID)
      );
      editUser.push(
        ...project.usergroups.items
          .find((item) => item.name === "engineering")
          .users.items.map((item) => item.userID)
      );
      editUser.push(
        ...project.usergroups.items
          .find((item) => item.name === "purchasing")
          .users.items.map((item) => item.userID)
      );
      editUser.push(
        ...project.usergroups.items
          .find((item) => item.name === "quality")
          .users.items.map((item) => item.userID)
      );
      editUser.push(
        ...project.usergroups.items
          .find((item) => item.name === "production")
          .users.items.map((item) => item.userID)
      );
      editUser.push(
        ...project.usergroups.items
          .find((item) => item.name === "supplier")
          .users.items.map((item) => item.userID)
      );
    } catch (err) {
      console.log(err);
    }

    setAccessIDs({
      adminUser: adminUser,
      editUser: editUser,
      viewUser: viewUser,
    });
  }, [project]);

  const getAbleCreate = () => {
    return (
      user.faiCreateEnabled &&
      (process.env.REACT_APP_FAI_MODULE_ENABLE
        ? process.env.REACT_APP_FAI_MODULE_ENABLE
        : config.faiModuleEnabled)
    );
  };

  const getSetProject = async (projectid) => {
    setLoading({ ...loading, long: true });
    var tmpProject = await API.graphql({
      query: getProjectquery.getProject,
      variables: { id: projectid },
    });
    var tmpFaiProjects = projectlist.fai;

    if (tmpFaiProjects.find((item) => item.id === projectid)) {
      tmpFaiProjects[
        tmpFaiProjects.findIndex((item) => item.id === projectid)
      ] = tmpProject.data.getProject;
    } else {
      tmpFaiProjects.push(tmpProject.data.getProject);
    }
    setProjectlist({ ...projectlist, fai: tmpFaiProjects });
    tmpProject = formatProject(tmpProject.data.getProject, Steps);
    setProject(tmpProject);
    setLoading({ ...loading, long: false });
  };

  const removeSetProject = async (projectid) => {
    var tmpProject;
    var tmpFaiProjects = projectlist.fai;

    tmpFaiProjects = tmpFaiProjects.filter((item) => item.id != projectid);
    if (projectlist.fai.length > 1) {
      tmpProject = await API.graphql({
        query: getProjectquery.getProject,
        variables: { id: projectlist.fai[0].id },
      });
    } else {
      tmpProject = null;
    }

    setProjectlist({ ...projectlist, fai: tmpFaiProjects });
    if (tmpProject != null) {
      tmpProject = formatProject(tmpProject.data.getProject, Steps);
      setProject(tmpProject);
      history.push(`${tmpProject.id}`);
    } else {
      history.push("/");
    }
  };

  const handleRemoveUser = async (oldUser) => {
    setLoading({ ...loading, long: true });

    let aUsers = project.adminUser.filter((user) => user !== oldUser.id);
    let eUsers = project.editUser.filter((user) => user !== oldUser.id);
    let vUsers = project.viewUser.filter((user) => user !== oldUser.id);
    const projVariables = {
      id: project.id,
      adminUser: aUsers,
      editUser: eUsers,
      viewUser: vUsers,
      _version: 1,
    };
    let tmpProject = await API.graphql({
      query: updateProject,
      variables: { input: projVariables },
    });
    let tmpProject2 = await API.graphql({
      query: deleteUseraccessgroupusers,
      variables: { input: { id: oldUser.accessGroupId, _version: 1 } },
    });

    getSetProject(project.id);

    setLoading({ ...loading, long: false });
  };

  const handleClose = () => {
    removeSetProject(project.id);
  };

  const threeColumn = useMediaQuery({
    query: "(min-device-width: 1900px)",
  });

  return (
    <>
      <FaiAppBar
        value={value}
        project={project}
        ableCreate={getAbleCreate()}
        handleClose={handleClose}
        setProject={setProject}
        projectType="fai"
        projectlist={projectlist}
        setProjectlist={setProjectlist}
        {...other}
      />
      <div className={classes.root}>
        <div className={threeColumn ? classes.aside0 : classes.aside2}>
          <Timeline
            steps={project.steps}
            threeColumn={threeColumn}
            activeStep={project.activeStep}
          />
        </div>
        <Container style={{ marginTop: "1em", flex: 4 }}>
          <Paper elevation={3} style={{ padding: "1em" }}>
            <Grid container spacing={1}>
              {false ? (
                <Grid item xs={4} md={1}>
                  <Avatar
                    alt={project.customID}
                    src="/static/images/avatar/1.jpg"
                    className={classes.large}
                  />
                </Grid>
              ) : null}
              <ProjectDescription
                project={project}
                getSetProject={getSetProject}
                user={user}
                module="fai"
              />
              <TeamMembers
                project={project}
                handleRemoveUser={(oldUser) => handleRemoveUser(oldUser)}
              />
            </Grid>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="true"
              padding={"1em"}
              style={{ width: "100%" }}
            >
              <Tab label="Requirements" {...a11yProps(0)} />
              <Tab label="Part Samples" {...a11yProps(1)} />
              <Tab label="Inspection" {...a11yProps(2)} />
            </Tabs>
            <Requirements
              value={value}
              index={0}
              getSetProject={getSetProject}
              loading={loading}
              setLoading={setLoading}
              Steps={Steps}
            />
            <Samples
              value={value}
              index={1}
              getSetProject={getSetProject}
              loading={loading}
              setLoading={setLoading}
              Steps={Steps}
            />
            <Inspection
              value={value}
              index={2}
              getSetProject={getSetProject}
              loading={loading}
              setLoading={setLoading}
              Steps={Steps}
            />
          </Paper>
        </Container>
        <div className={classes.aside1}>
          {["all", "actionItems"].findIndex(
            (item) => item === nav.project_right_object
          ) > -1 && (
            <ActionItems
              actionItems={
                project.actionitems
                  ? project.actionitems.items.filter(
                      (item) => item.actionitemType === "basic"
                    )
                  : []
              }
              projectID={project.id}
              accessIDs={accessIDs}
              getSetProject={() => getSetProject(project.id)}
              user={user}
            />
          )}

          {["all", "commentList"].findIndex(
            (item) => item === nav.project_right_object
          ) > -1 && (
            <CommentList
              style={{ marginTop: "1em" }}
              commentObjects={project.allCommentObjects}
              projectID={project.id}
              accessIDs={accessIDs}
              getSetProject={() => getSetProject(project.id)}
              user={user}
            />
          )}

          {["all", "modalActions"].findIndex(
            (item) => item === nav.project_right_object
          ) > -1 && (
            <ModalActionsList
              style={{ marginTop: "1em" }}
              modalObjects={project.modalActions}
              projectID={project.id}
              accessIDs={accessIDs}
              user={user}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FAI;
