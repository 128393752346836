import React, { useContext } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import TabularProjectTable from "./TabularProjectTable";
import IconRouter from "../Utilities/IconRouter";
import { ReactComponent as FaiIcon } from "../Utilities/Icons/fai_icon.svg";
import { ReactComponent as MtrIcon } from "../Utilities/Icons/mtr_icon.svg";
import { ReactComponent as RfqIcon } from "../Utilities/Icons/rfq_icon.svg";
import { ReactComponent as SetupIcon } from "../Utilities/Icons/setup.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import Feature from "../Utilities/Feature";
import OwnActionItems from "../Utilities/ActionItem/OwnActionItems";
import UserContext from "../../contexts/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const Home = () => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down("xl")),
    sm: useMediaQuery(theme.breakpoints.up("sm")),
    md: useMediaQuery(theme.breakpoints.up("md")),
    lg: useMediaQuery(theme.breakpoints.up("lg")),
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={9}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Card>
                <CardContent>
                  {matches.lg ? null : (
                    <Typography align="right" variant="h6">
                      {format(new Date(), "MMMM do, yyyy")}
                    </Typography>
                  )}

                  <Grid container>
                    <Feature name="module_fai_enable">
                      <IconRouter link="/fai/home" icon={FaiIcon} iconX={12} />
                    </Feature>
                    <Feature name="module_mtr_enable">
                      <IconRouter link="/mtr/home" icon={MtrIcon} iconX={12} />
                    </Feature>
                    <Feature name="module_rfq_enable">
                      <IconRouter
                        link="/"
                        icon={RfqIcon}
                        disabled={true}
                        iconX={12}
                      />
                    </Feature>
                    <IconRouter
                      link="/settings/home"
                      icon={SetupIcon}
                      iconX={12}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Card>
                <CardHeader title="My Work" />
                <CardContent>
                  <TabularProjectTable
                    filterSelf={true}
                    syle={{ paddingTop: "1em" }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {matches.lg ? (
          <Grid item sm={12} lg={3}>
            <Card>
              <CardContent>
                <Typography align="right" variant="h6">
                  {format(new Date(), "MMMM do, yyyy")}
                </Typography>
                <Typography align="left" variant="h5">
                  My Corrective Actions
                </Typography>
                <OwnActionItems user={user} actionitemType="corrective" />
              </CardContent>
            </Card>
            <Card style={{ marginTop: "1em" }}>
              <CardContent>
                <Typography align="left" variant="h5">
                  My Action Items
                </Typography>
                <OwnActionItems user={user} />
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <Grid item sm={12}>
            <Card>
              <CardContent>
                <Typography align="left" variant="h5">
                  My Corrective Actions
                </Typography>
                <OwnActionItems user={user} actionitemType="corrective" />
              </CardContent>
            </Card>
            <Card style={{ marginTop: "1em" }}>
              <CardContent>
                <Typography align="left" variant="h5">
                  My Action Items
                </Typography>
                <OwnActionItems user={user} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Home;
