import { Card, CardHeader, Container, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add } from '@mui/icons-material';
import React, { useState, useEffect, useContext } from 'react';
import * as queries from '../../graphql/listCompanys';
import { Redirect } from "react-router-dom";
import UserContext from '../../contexts/UserContext';
import graphQuery from '../../services/AWSGraphWrapper';
import CreateCompanyView from './CreateCompanyView';
import CompanyCard from './CompanyCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: '100%',
    //backgroundColor: theme.palette.background.paper,
  },
  row: {
    width: '100%',
    paddingTop: '3em'
  },
  profileItem: {
    width: '100%'
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  footer: {
    position: 'absolute',
    bottom: 65,
    left: 0,
    textAlign: 'center',
    width: '100%',
  },
}));

const ManageSuppliers = () => {
  const [companies, setCompanies] = useState([])
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [createCompanyDialog, setCreateCompanyDialog] = useState(false)

  const sortByName = (a, b) => {
    let fa = a.name.toLowerCase(),
    fb = b.name.toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
  }

  useEffect(() => {
    graphQuery({ query: queries.listCompanys })
      .then(response => setCompanies(response.sort((a, b) => sortByName(a,b))))
  }, [user])


  const CloseCreateCompany = () => {
    setCreateCompanyDialog(false)
    graphQuery({ query: queries.listCompanys })
      .then(response => setCompanies(response.sort((a, b) => sortByName(a,b))))
  }


  const handleUpdateCompanies = () => {
    graphQuery({ query: queries.listCompanys })
      .then(response => setCompanies(response.sort((a, b) => sortByName(a,b))))
  }
  
  console.log(user)
  
  if (user.loggedIn) {
    return (
      <Container className={classes.root}>
      <Grid container spacing={2} direction="row" className={classes.row}>
        {companies.map(company => (
          
          <Grid key={company.id} item xs={12} md={4} >
            <CompanyCard company={company} handleUpdateCompanies={() => handleUpdateCompanies()} />
          </Grid>
        ))}
        <Grid key={1} item xs={12} md={4} >
            <Card>
              <CardHeader
                title='Add Supplier'
                action={
                  <IconButton onClick={() => setCreateCompanyDialog(true)} size="large">
                  <Add />
                </IconButton>
              }
              />
            </Card>
            <CreateCompanyView open={createCompanyDialog} closeHandle={() => CloseCreateCompany()} user={user} />
          </Grid>
      </Grid>
    </Container>
    );
  } else {
    return  <Redirect to="/settings/home" />
  }
}

export default ManageSuppliers;