import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format, parseJSON, subDays } from 'date-fns';

// Contexts
import ProjectsContext from '../../contexts/ProjectsContext';
import UserContext from '../../contexts/UserContext';

// Custom Components
import ProjectTable from '../Base/ProjectTable';

// Custom Helper Functions
import { listProjects } from '../../graphql/listProjects';
import { formatProject } from '../Utilities/FormatProject';

// Constants
import graphQuery from '../../services/AWSGraphWrapper';

import projectConfigs from '../../constants/projectConfigs';

const OwnProjects = (props) => {
  const { projectType, columns, isClosedFilter, stateFilter, tableName } = props;
  const { user } = useContext(UserContext);
  const { projectlist, setProjectlist } = useContext(ProjectsContext);
  const [rows, setRows] = useState([]);


  useEffect(() => {
    
    const newByDate = subDays(new Date(), 7)
    
    var filter = {
      projectType: {eq: projectType}
    }

    if (stateFilter !== 'all') {
      filter = {...filter, and: { finalStatus: { attributeExists: isClosedFilter } }}
    }

    // Set type of steps
    graphQuery({
      query: listProjects,
      variables: {
        filter: filter
      }
    })
      .then(response => {
        var tmpProjects = response;
        if (stateFilter) {
          if (stateFilter === "new") {
            tmpProjects = tmpProjects.filter(proj => (Date.now() - Date.parse(proj.createdAt)) < newByDate)
          } 
        }
        tmpProjects = tmpProjects.map(project => formatProject(project, { ...projectConfigs.find(item => item.name === projectType).steps }, false))
        tmpProjects = tmpProjects.filter(item => findUser(item))
        setRows(tmpProjects)
    })
      .catch(err => console.log(err))
    
    const findUser = (project) => {
      if (project.usergroups) {
        var usergroup = project.usergroups.items.filter(item => project.stepOwner.indexOf(item.name) > -1)
        var userIndex = -1
        if (usergroup.length > 0) {
          userIndex = usergroup[0].users.items.findIndex(item => item.userID === user.id)
        }
        if (userIndex >= 0) {
          return true
        } else {
          return false
        }
      }
    }
  }, [user, stateFilter, isClosedFilter, projectType])

  return (
    <ProjectTable
      columns={columns}
      rows={rows}
      projectlist={projectlist}
      setProjectlist={setProjectlist}
      projectType={projectType}
      tableName={tableName}
    />
  )
}

OwnProjects.propTypes = {
  projectType: PropTypes.string,
  columns: PropTypes.array,
  isClosedFilter: PropTypes.bool,
  stateFilter: PropTypes.string,
  tableName: PropTypes.any
}

OwnProjects.defaultProps = {
  projectType: '',
  columns: [
    { field: 'customID', headerName: 'FAI Number', width: 200 },
    { field: 'objectNumber', headerName: 'Part or Drawing Number', width: 150 },
    { field: 'createdBy', headerName: 'Created By', width: 150 },
    {
      field: 'createdAt', headerName: 'Created At', width: 200,
      valueFormatter: (params) => format(parseJSON(params.value), 'yyyy-MM-dd HH:mm:ss')
    },
    { field: 'activeStatus', headerName: 'Current Status', width: 200 },
  ],
  isClosedFilter: false,
  stateFilter: 'all',
  tableName: ''
}

export default OwnProjects;