import { IconButton, Tooltip} from '@mui/material';
import React, { useState} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmAction from '../Base/ConfirmAction';
import DeleteCompany from '../../services/DeleteCompany';

const DeleteCompanyView = (props) => {
  const { company, handleUpdateCompanies } = props;
  const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false)

  const handleDeleteCompany = () => {
    setDeleteCompanyDialog(true)
  }

  const handleDeleteCompanyConfirm = async () => {
    console.log(`Deleting ${company.id}`);
    setDeleteCompanyDialog(false);
    await DeleteCompany(company.id, company._version);
    handleUpdateCompanies()
  }
  
  return <>
    <Tooltip title='Delete Company'>
      <IconButton onClick={() => handleDeleteCompany()} size="large">
        <ClearIcon />
      </IconButton>
    </Tooltip>
    <ConfirmAction
      open={deleteCompanyDialog}
      handleOnClose={() => setDeleteCompanyDialog(false)}
      handleConfirm={() => handleDeleteCompanyConfirm()}
      dialogTitle='Confirm'
      dialogContent={`You have requested to delete ${company.name}. Are you sure you want to do this?`}
    />
  </>;
          
   
}

export default DeleteCompanyView;