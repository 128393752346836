import { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Checkbox,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CommentIcon from '@mui/icons-material/Comment';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Comments from '../Comment/Comments';
import API from '@aws-amplify/api';
import { getActionitem } from "../../../graphql/getActionitem";
import * as mutations from "../../../graphql/mutations";
import NewComment from '../Comment/NewComment';
import Badge from '@mui/material/Badge';

import { Link as RouterLink } from "react-router-dom";
import { format } from 'date-fns';
const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  inline: {
    display: 'inline',
  },
}));

const ActionItem = (props) => {
  const { actionItemID = {}, user, secondary = 'assignee' } = props
  const classes = useStyles();
  const [actionItem, setActionItem] = useState({complete: false})
  const [expanded, setExpanded] = useState(false);
  const [newComment, setNewComment] = useState(false);

  const setComplete = (ai) => {
    ai.complete = ai.status.statusState === 'closed'
    return ai
  }

  const getSetActionItem = () => {
    API.graphql({ query: getActionitem, variables: { id: actionItemID } })
      .then(response => {
        setActionItem(setComplete(response.data.getActionitem))
      })
    setNewComment(false)
  }
  
  
  useEffect(() => {
    if (user.id) {
      try {
        const getSetActionItem = () => {
          API.graphql({ query: getActionitem, variables: { id: actionItemID } })
            .then(response => {
              setActionItem(setComplete(response.data.getActionitem))
            })
          setNewComment(false)
        }
      
        getSetActionItem(actionItemID)
      } catch (err) {
        console.log(err)
      }
    }
  }, [user, actionItemID])
  
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAICheck = () => {
    try {
      const updateStatus = {
        id: actionItem.status.id,
        _version: actionItem.status._version,
        statusState: actionItem.complete ? 'open' : 'closed',
        status: actionItem.complete ? 'Open' : 'Closed',
        lastModifierID: user.id
      }
      API.graphql({ query: mutations.updateStatus, variables: { input: updateStatus } })
        .then(response => {
          setActionItem(setComplete({ ...actionItem, status: response.data.updateStatus }))
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleNewCommentClick = () => {
    if (!expanded) {
      setExpanded(true)
    }
    setNewComment(!newComment)
  }

  const formatDueDate = (dueDate) => {
    try {
      return format(new Date(dueDate), 'Pp')
    } catch {
      return dueDate
    }
  }
  
  return <>
    <ListItem
      ContainerProps={{
        style: { display: 'flex', alignItems: 'center' },
      }}
    >
      {actionItem.actionitemType !== 'corrective' ?
        <ListItemIcon >
          <Checkbox
            edge="start"
            onChange={handleAICheck}
            checked={actionItem.complete}
            tabIndex={-1}
          />
        </ListItemIcon>
        : null}
      <ListItemText
        primary={actionItem.actionitem}
        secondary=
        {
          <>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {`Due: ${formatDueDate(actionItem.duedate)} `}
            </Typography>
            <br />
            {secondary === 'assignee' ?
              <Typography
                variant="body1"
                color="textPrimary"
              >
                {actionItem.assignee ? actionItem.assignee.firstname + " " + actionItem.assignee.lastname : ''}
              </Typography>
              :
              (actionItem.project ?
                <Link
                  component={RouterLink}
                  to={`/${actionItem.project.projectType ? actionItem.project.projectType : 'fai'}/details/${actionItem.project.id}`}
                  className={classes.link}
                  underline="hover">
                  {actionItem.project.customID}
                </Link> :
                null
              )
            }
          </>
        }
      />
      {actionItem.actionitemType !== 'corrective' ?
        <ListItemSecondaryAction
          style={{ position: 'static', transform: 'none' }}>
          <IconButton
            edge="end"
            aria-label="comments"
            disabled={false}
            onClick={handleNewCommentClick}
            size="large">
            <CommentIcon />
          </IconButton>
          <Badge badgeContent={actionItem.comments ? actionItem.comments.items.length : null} color="primary">
            <IconButton
              edge="end"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,   
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show comments"
              disabled={false}
              size="large"> 
              <ExpandMore />
            </IconButton>
          </Badge>
          
        </ListItemSecondaryAction>
        :
        null}
    </ListItem>
    {expanded && (
      <ListItem>
        <ListItemText>
          <Comments
            comments={actionItem.comments.items}
            replyHandle={handleNewCommentClick}
          />
          {newComment && <NewComment
            actionitemID={actionItemID}
            projectID={actionItem.projectID}
            getSetProject={getSetActionItem}
            parentID={actionItemID}
          />}
        </ListItemText>
      </ListItem>
    )}
  </>;
}

export default ActionItem;