class Logger {
  constructor(userId, infoLevel='INFO') {
    this.setUserId(userId)
    this.setInfoLevel(infoLevel)
    this.setMsgLevel(infoLevel)
  }

  setInfoLevel(infoLevel) {
    this.infoLevel = infoLevel.toUpperCase()
  }

  setMsgLevel(msgLevel) {
    this.msgLevel = msgLevel.toUpperCase()
  }

  setUserId(userId) {
    this.userId = userId
  }

  setMessage(message) {
    this.message = message
  }

  setData(data) {
    this.data = data
  }
  
  debug(message, data = null) {
    this.setMsgLevel('DEBUG')
    switch (this.infoLevel) {
      case 'DEBUG':
        this.log(message, data)
        break;
      default:
        break;
    }
  }

  info(message, data = null) {
    this.setMsgLevel('INFO')
    switch (this.infoLevel) {
      case 'INFO':
        this.log(message, data)
        break;
      case 'DEBUG':
        this.log(message, data)
        break;
      default:
        break;
    }
  }

  warning(message, data = null) {
    this.setMsgLevel('WARNING')
    switch (this.infoLevel) {
      case 'WARNING':
        this.log(message, data)
        break;
      case 'INFO':
        this.log(message, data)
        break;
      case 'DEBUG':
        this.log(message, data)
        break;
      default:
        break;
    }
  }

  
  error(message, data = null) {
    this.setMsgLevel('ERROR')
    switch (this.infoLevel) {
      case 'ERROR':
        this.log(message, data)
        break;
      case 'WARNING':
        this.log(message, data)
        break;
      case 'INFO':
        this.log(message, data)
        break;
      case 'DEBUG':
        this.log(message, data)
        break;
      default:
        break;
    }
  }

  log(message, data = null) {
    this.setMessage(message)
    this.setData(data)
    console.log(message)
    this.sendLog()
  }
  
  sendLog() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
      'messageTime': new Date(),
      'infoLevel': this.infoLevel,
      'msgLevel': this.msgLevel,
      'data': this.data,
      'message': this.msg,
      'app': 'guild-beta',
      'host': window.location.host,
      'url': window.location.href,
      'userId': this.userID
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    fetch(process.env.REACT_APP_LOGGERAPI_ENDPOINT, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  
  }
}

export default Logger;