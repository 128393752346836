import { API } from "aws-amplify";

const graphQuery = async (input) => {
  let inputVariables = {}
  if (Object.keys(input).findIndex(item => item === 'variables') > 0) {
    inputVariables = input['variables']
  }
  let tmpData = {}
  let returnArray = []
  let continueRequest = true
  let nextTokenExist = false
  let queryName = ''
  let fakeArray = []
  let i = 0;

  while (continueRequest) {
    tmpData = await API.graphql({ query: input.query, variables: inputVariables })
    queryName = Object.keys(tmpData.data)[0]
    
    if (Object.keys(tmpData.data[queryName]).findIndex(item => item === 'items') > -1)
      returnArray.push(...tmpData.data[queryName]['items'])
    
    nextTokenExist = Object.keys(tmpData.data[queryName]).findIndex(item => item === 'nextToken') > -1
    continueRequest = false

    if (nextTokenExist) {
      if (tmpData.data[queryName]['nextToken']) {
        continueRequest = true
        inputVariables = { ...inputVariables, nextToken: tmpData.data[queryName]['nextToken'] }
      }
    }

    fakeArray.push(i)
    
    i++;
    if (i === 100) break;
  }

  return returnArray.filter(item => !item._deleted)
  
}

export default graphQuery;