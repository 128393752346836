/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModeluserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      firstname
      lastname
      superUser
      companyAdmin
      companyID
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      company {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModeluserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      firstname
      lastname
      superUser
      companyAdmin
      companyID
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      company {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModeluserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      firstname
      lastname
      superUser
      companyAdmin
      companyID
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      company {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createKeyvalues = /* GraphQL */ `
  mutation CreateKeyvalues(
    $input: CreateKeyvaluesInput!
    $condition: ModelkeyvaluesConditionInput
  ) {
    createKeyvalues(input: $input, condition: $condition) {
      id
      key
      value
      creatorID
      lastModifierID
      userID
      companyID
      partnumberID
      projectID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const updateKeyvalues = /* GraphQL */ `
  mutation UpdateKeyvalues(
    $input: UpdateKeyvaluesInput!
    $condition: ModelkeyvaluesConditionInput
  ) {
    updateKeyvalues(input: $input, condition: $condition) {
      id
      key
      value
      creatorID
      lastModifierID
      userID
      companyID
      partnumberID
      projectID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const deleteKeyvalues = /* GraphQL */ `
  mutation DeleteKeyvalues(
    $input: DeleteKeyvaluesInput!
    $condition: ModelkeyvaluesConditionInput
  ) {
    deleteKeyvalues(input: $input, condition: $condition) {
      id
      key
      value
      creatorID
      lastModifierID
      userID
      companyID
      partnumberID
      projectID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelcompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      users {
        items {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      groups {
        items {
          id
          name
          creatorID
          companyID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelcompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      users {
        items {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      groups {
        items {
          id
          name
          creatorID
          companyID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelcompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      users {
        items {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      groups {
        items {
          id
          name
          creatorID
          companyID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelgroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      creatorID
      companyID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          userID
          groupID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelgroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      creatorID
      companyID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          userID
          groupID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelgroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      creatorID
      companyID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          userID
          groupID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createUsergroups = /* GraphQL */ `
  mutation CreateUsergroups(
    $input: CreateUsergroupsInput!
    $condition: ModelusergroupsConditionInput
  ) {
    createUsergroups(input: $input, condition: $condition) {
      id
      userID
      groupID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      group {
        id
        name
        creatorID
        companyID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateUsergroups = /* GraphQL */ `
  mutation UpdateUsergroups(
    $input: UpdateUsergroupsInput!
    $condition: ModelusergroupsConditionInput
  ) {
    updateUsergroups(input: $input, condition: $condition) {
      id
      userID
      groupID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      group {
        id
        name
        creatorID
        companyID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteUsergroups = /* GraphQL */ `
  mutation DeleteUsergroups(
    $input: DeleteUsergroupsInput!
    $condition: ModelusergroupsConditionInput
  ) {
    deleteUsergroups(input: $input, condition: $condition) {
      id
      userID
      groupID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      group {
        id
        name
        creatorID
        companyID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createPartnumber = /* GraphQL */ `
  mutation CreatePartnumber(
    $input: CreatePartnumberInput!
    $condition: ModelpartnumberConditionInput
  ) {
    createPartnumber(input: $input, condition: $condition) {
      id
      objectnumber
      numberType
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      projects {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updatePartnumber = /* GraphQL */ `
  mutation UpdatePartnumber(
    $input: UpdatePartnumberInput!
    $condition: ModelpartnumberConditionInput
  ) {
    updatePartnumber(input: $input, condition: $condition) {
      id
      objectnumber
      numberType
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      projects {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deletePartnumber = /* GraphQL */ `
  mutation DeletePartnumber(
    $input: DeletePartnumberInput!
    $condition: ModelpartnumberConditionInput
  ) {
    deletePartnumber(input: $input, condition: $condition) {
      id
      objectnumber
      numberType
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      owner
      projects {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createStatus = /* GraphQL */ `
  mutation CreateStatus(
    $input: CreateStatusInput!
    $condition: ModelstatusConditionInput
  ) {
    createStatus(input: $input, condition: $condition) {
      id
      status
      statusState
      dueDate
      notes
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus(
    $input: UpdateStatusInput!
    $condition: ModelstatusConditionInput
  ) {
    updateStatus(input: $input, condition: $condition) {
      id
      status
      statusState
      dueDate
      notes
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const deleteStatus = /* GraphQL */ `
  mutation DeleteStatus(
    $input: DeleteStatusInput!
    $condition: ModelstatusConditionInput
  ) {
    deleteStatus(input: $input, condition: $condition) {
      id
      status
      statusState
      dueDate
      notes
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelcommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      projectID
      comment
      creatorID
      lastModifierID
      parentID
      childID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      attachmentID
      deliverableID
      inspectionID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelcommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      projectID
      comment
      creatorID
      lastModifierID
      parentID
      childID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      attachmentID
      deliverableID
      inspectionID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelcommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      projectID
      comment
      creatorID
      lastModifierID
      parentID
      childID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      attachmentID
      deliverableID
      inspectionID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
    }
  }
`;
export const createActionitem = /* GraphQL */ `
  mutation CreateActionitem(
    $input: CreateActionitemInput!
    $condition: ModelactionitemConditionInput
  ) {
    createActionitem(input: $input, condition: $condition) {
      id
      actionitem
      duedate
      assigneeID
      actionitemType
      finding
      rootcause
      correctiveAction
      statusID
      creatorID
      lastModifierID
      projectID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      deliverableID
      inspectionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      assignee {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      project {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        supplier {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        partnumber {
          id
          objectnumber
          numberType
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        history {
          nextToken
          startedAt
        }
        owner
        usergroups {
          nextToken
          startedAt
        }
        requirements {
          nextToken
          startedAt
        }
        deliverable {
          nextToken
          startedAt
        }
        inspection {
          nextToken
          startedAt
        }
      }
      attachment {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateActionitem = /* GraphQL */ `
  mutation UpdateActionitem(
    $input: UpdateActionitemInput!
    $condition: ModelactionitemConditionInput
  ) {
    updateActionitem(input: $input, condition: $condition) {
      id
      actionitem
      duedate
      assigneeID
      actionitemType
      finding
      rootcause
      correctiveAction
      statusID
      creatorID
      lastModifierID
      projectID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      deliverableID
      inspectionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      assignee {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      project {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        supplier {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        partnumber {
          id
          objectnumber
          numberType
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        history {
          nextToken
          startedAt
        }
        owner
        usergroups {
          nextToken
          startedAt
        }
        requirements {
          nextToken
          startedAt
        }
        deliverable {
          nextToken
          startedAt
        }
        inspection {
          nextToken
          startedAt
        }
      }
      attachment {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteActionitem = /* GraphQL */ `
  mutation DeleteActionitem(
    $input: DeleteActionitemInput!
    $condition: ModelactionitemConditionInput
  ) {
    deleteActionitem(input: $input, condition: $condition) {
      id
      actionitem
      duedate
      assigneeID
      actionitemType
      finding
      rootcause
      correctiveAction
      statusID
      creatorID
      lastModifierID
      projectID
      userID
      companyID
      partnumberID
      actionitemID
      historyID
      requirementID
      deliverableID
      inspectionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      assignee {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      project {
        id
        customID
        name
        partnumberID
        mpartnumber
        description
        projectType
        finalStatus
        dueDate
        supplierID
        statusID
        creatorID
        lastModifierID
        parentID
        childID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        supplier {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        partnumber {
          id
          objectnumber
          numberType
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        history {
          nextToken
          startedAt
        }
        owner
        usergroups {
          nextToken
          startedAt
        }
        requirements {
          nextToken
          startedAt
        }
        deliverable {
          nextToken
          startedAt
        }
        inspection {
          nextToken
          startedAt
        }
      }
      attachment {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelhistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      projectID
      modifiedID
      modifiedType
      modifiedAction
      modification
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelhistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      projectID
      modifiedID
      modifiedType
      modifiedAction
      modification
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelhistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      projectID
      modifiedID
      modifiedType
      modifiedAction
      modification
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelprojectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      customID
      name
      partnumberID
      mpartnumber
      description
      projectType
      finalStatus
      dueDate
      supplierID
      statusID
      creatorID
      lastModifierID
      parentID
      childID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      supplier {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      partnumber {
        id
        objectnumber
        numberType
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        projects {
          nextToken
          startedAt
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      history {
        items {
          id
          projectID
          modifiedID
          modifiedType
          modifiedAction
          modification
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      usergroups {
        items {
          id
          projectID
          name
          description
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      requirements {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      deliverable {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      inspection {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          inspectorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelprojectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      customID
      name
      partnumberID
      mpartnumber
      description
      projectType
      finalStatus
      dueDate
      supplierID
      statusID
      creatorID
      lastModifierID
      parentID
      childID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      supplier {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      partnumber {
        id
        objectnumber
        numberType
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        projects {
          nextToken
          startedAt
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      history {
        items {
          id
          projectID
          modifiedID
          modifiedType
          modifiedAction
          modification
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      usergroups {
        items {
          id
          projectID
          name
          description
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      requirements {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      deliverable {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      inspection {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          inspectorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelprojectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      customID
      name
      partnumberID
      mpartnumber
      description
      projectType
      finalStatus
      dueDate
      supplierID
      statusID
      creatorID
      lastModifierID
      parentID
      childID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      supplier {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          nextToken
          startedAt
        }
      }
      partnumber {
        id
        objectnumber
        numberType
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        projects {
          nextToken
          startedAt
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      history {
        items {
          id
          projectID
          modifiedID
          modifiedType
          modifiedAction
          modification
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      usergroups {
        items {
          id
          projectID
          name
          description
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      requirements {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      deliverable {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      inspection {
        items {
          id
          projectID
          name
          description
          dueDate
          statusID
          creatorID
          inspectorID
          lastModifierID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createUseraccessgroup = /* GraphQL */ `
  mutation CreateUseraccessgroup(
    $input: CreateUseraccessgroupInput!
    $condition: ModeluseraccessgroupConditionInput
  ) {
    createUseraccessgroup(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          useraccessgroupID
          userID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateUseraccessgroup = /* GraphQL */ `
  mutation UpdateUseraccessgroup(
    $input: UpdateUseraccessgroupInput!
    $condition: ModeluseraccessgroupConditionInput
  ) {
    updateUseraccessgroup(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          useraccessgroupID
          userID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteUseraccessgroup = /* GraphQL */ `
  mutation DeleteUseraccessgroup(
    $input: DeleteUseraccessgroupInput!
    $condition: ModeluseraccessgroupConditionInput
  ) {
    deleteUseraccessgroup(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      owner
      users {
        items {
          id
          useraccessgroupID
          userID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createUseraccessgroupusers = /* GraphQL */ `
  mutation CreateUseraccessgroupusers(
    $input: CreateUseraccessgroupusersInput!
    $condition: ModeluseraccessgroupusersConditionInput
  ) {
    createUseraccessgroupusers(input: $input, condition: $condition) {
      id
      useraccessgroupID
      userID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      useraccessgroup {
        id
        projectID
        name
        description
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateUseraccessgroupusers = /* GraphQL */ `
  mutation UpdateUseraccessgroupusers(
    $input: UpdateUseraccessgroupusersInput!
    $condition: ModeluseraccessgroupusersConditionInput
  ) {
    updateUseraccessgroupusers(input: $input, condition: $condition) {
      id
      useraccessgroupID
      userID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      useraccessgroup {
        id
        projectID
        name
        description
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteUseraccessgroupusers = /* GraphQL */ `
  mutation DeleteUseraccessgroupusers(
    $input: DeleteUseraccessgroupusersInput!
    $condition: ModeluseraccessgroupusersConditionInput
  ) {
    deleteUseraccessgroupusers(input: $input, condition: $condition) {
      id
      useraccessgroupID
      userID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      useraccessgroup {
        id
        projectID
        name
        description
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
        users {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createRequirement = /* GraphQL */ `
  mutation CreateRequirement(
    $input: CreateRequirementInput!
    $condition: ModelrequirementConditionInput
  ) {
    createRequirement(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      attachments {
        items {
          id
          requirementID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateRequirement = /* GraphQL */ `
  mutation UpdateRequirement(
    $input: UpdateRequirementInput!
    $condition: ModelrequirementConditionInput
  ) {
    updateRequirement(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      attachments {
        items {
          id
          requirementID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteRequirement = /* GraphQL */ `
  mutation DeleteRequirement(
    $input: DeleteRequirementInput!
    $condition: ModelrequirementConditionInput
  ) {
    deleteRequirement(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      actionitems {
        items {
          id
          actionitem
          duedate
          assigneeID
          actionitemType
          finding
          rootcause
          correctiveAction
          statusID
          creatorID
          lastModifierID
          projectID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          deliverableID
          inspectionID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      attachments {
        items {
          id
          requirementID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createRequirementattachment = /* GraphQL */ `
  mutation CreateRequirementattachment(
    $input: CreateRequirementattachmentInput!
    $condition: ModelrequirementattachmentConditionInput
  ) {
    createRequirementattachment(input: $input, condition: $condition) {
      id
      requirementID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      requirement {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        attachments {
          nextToken
          startedAt
        }
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateRequirementattachment = /* GraphQL */ `
  mutation UpdateRequirementattachment(
    $input: UpdateRequirementattachmentInput!
    $condition: ModelrequirementattachmentConditionInput
  ) {
    updateRequirementattachment(input: $input, condition: $condition) {
      id
      requirementID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      requirement {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        attachments {
          nextToken
          startedAt
        }
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteRequirementattachment = /* GraphQL */ `
  mutation DeleteRequirementattachment(
    $input: DeleteRequirementattachmentInput!
    $condition: ModelrequirementattachmentConditionInput
  ) {
    deleteRequirementattachment(input: $input, condition: $condition) {
      id
      requirementID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      requirement {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        actionitems {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        attachments {
          nextToken
          startedAt
        }
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelattachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      datatype
      location
      filename
      statusID
      creatorID
      lastModifierID
      lastversionID
      nextversionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelattachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      datatype
      location
      filename
      statusID
      creatorID
      lastModifierID
      lastversionID
      nextversionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelattachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      datatype
      location
      filename
      statusID
      creatorID
      lastModifierID
      lastversionID
      nextversionID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          attachmentID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createAttachmentcomment = /* GraphQL */ `
  mutation CreateAttachmentcomment(
    $input: CreateAttachmentcommentInput!
    $condition: ModelattachmentcommentConditionInput
  ) {
    createAttachmentcomment(input: $input, condition: $condition) {
      id
      attachmentID
      commentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      comment {
        id
        projectID
        comment
        creatorID
        lastModifierID
        parentID
        childID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        attachmentID
        deliverableID
        inspectionID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateAttachmentcomment = /* GraphQL */ `
  mutation UpdateAttachmentcomment(
    $input: UpdateAttachmentcommentInput!
    $condition: ModelattachmentcommentConditionInput
  ) {
    updateAttachmentcomment(input: $input, condition: $condition) {
      id
      attachmentID
      commentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      comment {
        id
        projectID
        comment
        creatorID
        lastModifierID
        parentID
        childID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        attachmentID
        deliverableID
        inspectionID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteAttachmentcomment = /* GraphQL */ `
  mutation DeleteAttachmentcomment(
    $input: DeleteAttachmentcommentInput!
    $condition: ModelattachmentcommentConditionInput
  ) {
    deleteAttachmentcomment(input: $input, condition: $condition) {
      id
      attachmentID
      commentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      comment {
        id
        projectID
        comment
        creatorID
        lastModifierID
        parentID
        childID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        attachmentID
        deliverableID
        inspectionID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createAttachmentactionitem = /* GraphQL */ `
  mutation CreateAttachmentactionitem(
    $input: CreateAttachmentactionitemInput!
    $condition: ModelattachmentactionitemConditionInput
  ) {
    createAttachmentactionitem(input: $input, condition: $condition) {
      id
      attachmentID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateAttachmentactionitem = /* GraphQL */ `
  mutation UpdateAttachmentactionitem(
    $input: UpdateAttachmentactionitemInput!
    $condition: ModelattachmentactionitemConditionInput
  ) {
    updateAttachmentactionitem(input: $input, condition: $condition) {
      id
      attachmentID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteAttachmentactionitem = /* GraphQL */ `
  mutation DeleteAttachmentactionitem(
    $input: DeleteAttachmentactionitemInput!
    $condition: ModelattachmentactionitemConditionInput
  ) {
    deleteAttachmentactionitem(input: $input, condition: $condition) {
      id
      attachmentID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createDeliverable = /* GraphQL */ `
  mutation CreateDeliverable(
    $input: CreateDeliverableInput!
    $condition: ModeldeliverableConditionInput
  ) {
    createDeliverable(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          deliverableID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          deliverableID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateDeliverable = /* GraphQL */ `
  mutation UpdateDeliverable(
    $input: UpdateDeliverableInput!
    $condition: ModeldeliverableConditionInput
  ) {
    updateDeliverable(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          deliverableID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          deliverableID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteDeliverable = /* GraphQL */ `
  mutation DeleteDeliverable(
    $input: DeleteDeliverableInput!
    $condition: ModeldeliverableConditionInput
  ) {
    deleteDeliverable(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          deliverableID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          deliverableID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createDeliverableactionitem = /* GraphQL */ `
  mutation CreateDeliverableactionitem(
    $input: CreateDeliverableactionitemInput!
    $condition: ModeldeliverableactionitemConditionInput
  ) {
    createDeliverableactionitem(input: $input, condition: $condition) {
      id
      deliverableID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      deliverable {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateDeliverableactionitem = /* GraphQL */ `
  mutation UpdateDeliverableactionitem(
    $input: UpdateDeliverableactionitemInput!
    $condition: ModeldeliverableactionitemConditionInput
  ) {
    updateDeliverableactionitem(input: $input, condition: $condition) {
      id
      deliverableID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      deliverable {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteDeliverableactionitem = /* GraphQL */ `
  mutation DeleteDeliverableactionitem(
    $input: DeleteDeliverableactionitemInput!
    $condition: ModeldeliverableactionitemConditionInput
  ) {
    deleteDeliverableactionitem(input: $input, condition: $condition) {
      id
      deliverableID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      deliverable {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createDeliverableattachment = /* GraphQL */ `
  mutation CreateDeliverableattachment(
    $input: CreateDeliverableattachmentInput!
    $condition: ModeldeliverableattachmentConditionInput
  ) {
    createDeliverableattachment(input: $input, condition: $condition) {
      id
      deliverableID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      deliverable {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateDeliverableattachment = /* GraphQL */ `
  mutation UpdateDeliverableattachment(
    $input: UpdateDeliverableattachmentInput!
    $condition: ModeldeliverableattachmentConditionInput
  ) {
    updateDeliverableattachment(input: $input, condition: $condition) {
      id
      deliverableID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      deliverable {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteDeliverableattachment = /* GraphQL */ `
  mutation DeleteDeliverableattachment(
    $input: DeleteDeliverableattachmentInput!
    $condition: ModeldeliverableattachmentConditionInput
  ) {
    deleteDeliverableattachment(input: $input, condition: $condition) {
      id
      deliverableID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      deliverable {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createInspection = /* GraphQL */ `
  mutation CreateInspection(
    $input: CreateInspectionInput!
    $condition: ModelinspectionConditionInput
  ) {
    createInspection(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      inspectorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      inspector {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          inspectionID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          inspectionID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateInspection = /* GraphQL */ `
  mutation UpdateInspection(
    $input: UpdateInspectionInput!
    $condition: ModelinspectionConditionInput
  ) {
    updateInspection(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      inspectorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      inspector {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          inspectionID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          inspectionID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteInspection = /* GraphQL */ `
  mutation DeleteInspection(
    $input: DeleteInspectionInput!
    $condition: ModelinspectionConditionInput
  ) {
    deleteInspection(input: $input, condition: $condition) {
      id
      projectID
      name
      description
      dueDate
      statusID
      creatorID
      inspectorID
      lastModifierID
      adminUser
      editUser
      viewUser
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      creator {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      inspector {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      lastModifier {
        id
        username
        email
        firstname
        lastname
        superUser
        companyAdmin
        companyID
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
        keyvalues {
          nextToken
          startedAt
        }
        company {
          id
          name
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
      }
      status {
        id
        status
        statusState
        dueDate
        notes
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        owner
      }
      comments {
        items {
          id
          projectID
          comment
          creatorID
          lastModifierID
          parentID
          childID
          userID
          companyID
          partnumberID
          actionitemID
          historyID
          requirementID
          attachmentID
          deliverableID
          inspectionID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      project {
        items {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      owner
      actionitems {
        items {
          id
          inspectionID
          actionitemID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      attachments {
        items {
          id
          inspectionID
          attachmentID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createInspectionactionitem = /* GraphQL */ `
  mutation CreateInspectionactionitem(
    $input: CreateInspectionactionitemInput!
    $condition: ModelinspectionactionitemConditionInput
  ) {
    createInspectionactionitem(input: $input, condition: $condition) {
      id
      inspectionID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      inspection {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateInspectionactionitem = /* GraphQL */ `
  mutation UpdateInspectionactionitem(
    $input: UpdateInspectionactionitemInput!
    $condition: ModelinspectionactionitemConditionInput
  ) {
    updateInspectionactionitem(input: $input, condition: $condition) {
      id
      inspectionID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      inspection {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteInspectionactionitem = /* GraphQL */ `
  mutation DeleteInspectionactionitem(
    $input: DeleteInspectionactionitemInput!
    $condition: ModelinspectionactionitemConditionInput
  ) {
    deleteInspectionactionitem(input: $input, condition: $condition) {
      id
      inspectionID
      actionitemID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      actionitem {
        id
        actionitem
        duedate
        assigneeID
        actionitemType
        finding
        rootcause
        correctiveAction
        statusID
        creatorID
        lastModifierID
        projectID
        userID
        companyID
        partnumberID
        actionitemID
        historyID
        requirementID
        deliverableID
        inspectionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        assignee {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        owner
        project {
          id
          customID
          name
          partnumberID
          mpartnumber
          description
          projectType
          finalStatus
          dueDate
          supplierID
          statusID
          creatorID
          lastModifierID
          parentID
          childID
          adminUser
          editUser
          viewUser
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        attachment {
          nextToken
          startedAt
        }
      }
      inspection {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createInspectionattachment = /* GraphQL */ `
  mutation CreateInspectionattachment(
    $input: CreateInspectionattachmentInput!
    $condition: ModelinspectionattachmentConditionInput
  ) {
    createInspectionattachment(input: $input, condition: $condition) {
      id
      inspectionID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      inspection {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateInspectionattachment = /* GraphQL */ `
  mutation UpdateInspectionattachment(
    $input: UpdateInspectionattachmentInput!
    $condition: ModelinspectionattachmentConditionInput
  ) {
    updateInspectionattachment(input: $input, condition: $condition) {
      id
      inspectionID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      inspection {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteInspectionattachment = /* GraphQL */ `
  mutation DeleteInspectionattachment(
    $input: DeleteInspectionattachmentInput!
    $condition: ModelinspectionattachmentConditionInput
  ) {
    deleteInspectionattachment(input: $input, condition: $condition) {
      id
      inspectionID
      attachmentID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      attachment {
        id
        projectID
        name
        description
        datatype
        location
        filename
        statusID
        creatorID
        lastModifierID
        lastversionID
        nextversionID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
      }
      inspection {
        id
        projectID
        name
        description
        dueDate
        statusID
        creatorID
        inspectorID
        lastModifierID
        adminUser
        editUser
        viewUser
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        inspector {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        status {
          id
          status
          statusState
          dueDate
          notes
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        comments {
          nextToken
          startedAt
        }
        project {
          nextToken
          startedAt
        }
        owner
        actionitems {
          nextToken
          startedAt
        }
        attachments {
          nextToken
          startedAt
        }
      }
    }
  }
`;
