import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";

export const createUpdateKV = async (key, value, project, userId) => {
  var kv = project.keyvalues.items.find((item) => item.key === key);
  if (kv) {
    const updateKV = {
      id: kv.id,
      value: value,
      lastModifierID: userId,
      _version: kv._version,
    };
    await API.graphql({
      query: mutations.updateKeyvalues,
      variables: { input: updateKV },
    }).then((response) => response.data.updateKeyvalues.id);
  } else {
    const newKV = {
      projectID: project.id,
      key: key,
      value: value,
      creatorID: userId,
      lastModifierID: userId,
    };
    await API.graphql({
      query: mutations.createKeyvalues,
      variables: { input: newKV },
    }).then((response) => response.data.createKeyvalues.id);
  }
};
