import React from 'react';
import { useHistory } from 'react-router';
import * as getProjectquery from '../../graphql/getProject';
import { API } from 'aws-amplify';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


const ProjectTable = (props) => {
  const { projectType, projectlist, setProjectlist, rows, columns, tableName = '', ...other } = props
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName: `${projectType}-export` }} />
      </GridToolbarContainer>
    );
  }
  let history = useHistory();
  
  const handleRowClick = async (event) => {
    if (!projectlist[projectType].find(item => item.id === event.row.id)) {
      console.log(`${event.row.id} not in open project list. Adding`)
      var tmpProject = await API.graphql({query: getProjectquery.getProject , variables: {id: event.row.id }})
      var tmpProjectlist = projectlist[projectType]
      tmpProjectlist.push(tmpProject.data.getProject)
      setProjectlist({ ...projectlist, [projectType]: tmpProjectlist })
    } else {
      console.log(`${event.row.id} in open project list. Nothing to add`)
    }

    history.push(`/${projectType}/details/${event.row.id}`)
  }

  return (
    <div style={{margin:'1em'}}>
      <Typography variant="h4">{tableName === '' ? projectType.toUpperCase() + 's': tableName}</Typography>
      <Grid container>
        <Grid item xs={12} style={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20, 50, 100]}
            density="comfortable"
            onRowClick={handleRowClick}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ProjectTable;