import React, {createContext, useState} from 'react';
import PropTypes from 'prop-types';

const NavContext = createContext([{}, () => {}]);
const features = JSON.parse(localStorage.getItem('flags'));
var feature_rightProjectDrawer_enable
if (features) {
 feature_rightProjectDrawer_enable = features.find(feature => feature !== null ? feature.name === 'feature_rightProjectDrawer_enable' : false);
} else {
  feature_rightProjectDrawer_enable = false
}
const NavContextProvider = (props) => {


  const [nav, setNav] = useState({
    project_right_object: feature_rightProjectDrawer_enable.active ? 'actionItems' : 'all'
  });

  return (
    <NavContext.Provider value = {[nav, setNav]}>
      {props.children}
    </NavContext.Provider>
  )
}

NavContextProvider.propTypes = {
  children: PropTypes.element
}

NavContextProvider.defaultProps = {
  children: null
}

export { NavContext, NavContextProvider }