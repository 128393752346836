export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      firstname
      lastname
      superUser
      companyAdmin
      companyID
      creatorID
      lastModifierID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      keyvalues {
        items {
          id
          key
          value
          creatorID
          lastModifierID
          userID
          companyID
          partnumberID
          projectID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      company {
        id
        name
        creatorID
        lastModifierID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        creator {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        lastModifier {
          id
          username
          email
          firstname
          lastname
          superUser
          companyAdmin
          companyID
          creatorID
          lastModifierID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        users {
          nextToken
          startedAt
        }
        keyvalues {
          nextToken
          startedAt
        }
        owner
        groups {
          items {
            id
            name
            creatorID
            companyID
            lastModifierID
            _version
            _deleted
            _lastChangedAt
            createdAt
            updatedAt
            owner
            users {
              items {
                id
                userID
                user {
                  id
                  username
                  firstname
                  lastname
                  companyID
                  company {
                    name
                  }
                }
                groupID
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
              }
              nextToken
              startedAt
            }
          }
          nextToken
          startedAt
        }
      }
    }
  }
`;