import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, SvgIcon } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const IconActionButton = (props) => {
  const { icon, onClick, text, sm = 1, md = 1.25, lg = 1.5, iconX = 6, ...other } = props
  
  const theme = useTheme();
  const matches = {
    'sm': useMediaQuery(theme.breakpoints.down('xl')),
    'md': useMediaQuery(theme.breakpoints.up('md')),
    'lg': useMediaQuery(theme.breakpoints.up('lg'))
  };

  const fontsize = matches.lg ? `${lg}em` : (matches.md ? `${md}em` : `${sm}em`)
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Button
        onClick={onClick}
        style={{width: '100%',  fontSize: fontsize, textTransform: 'none', color: 'black'   }}
        startIcon={
          <SvgIcon component={icon} style={{ fontSize: `${iconX}em` }}/>
        }
        {...other}>
      {text}
      </Button>
    </Grid>
  );
}

export default IconActionButton;